import React, { Component } from 'react';
import './add_chain.less';
import Select from 'react-select';
import InputText from '../../../common/components/input-text/input-text';
import Delete from '../../../../assets/images/delete.svg';
import Button from '../../../home/button-classic/button-classic';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

import { getCookie } from "../../../../utils/cookies";
import Refresh from "../../../../assets/images/refresh-button.svg";
import { netWorkTypes, weeklyOff, entityType, addressType, movementTypes, metroType, CLUSTERS } from "../../../../utils/common";
import { DEEPAK_COMPANY_ID, AWL_COMPANY_IDS, PANTALOONS_COMPANY_IDS, ABFRL_COMPANY_IDS } from '../../../../utils/common';
import Moment from 'moment';
import { legthValidation, validationCheck, toPascalCase } from "../../../../utils/validate";
// import Geocode from "react-geocode";
import home from '../../home.constants.js';
import { attachGoogleKeyScript, geocodeAddress } from '../../../../utils/index.js';

// Geocode.setApiKey("AIzaSyBZS3IcyJ42uPzK8z5ZvbK5Fd99j0NqMRE");

class AddChain extends Component {
	constructor(props) {
		super(props);
		this.state = {
			basicData: {
				company_id: JSON.parse(getCookie("user")).company_id,
				network_type: '',
				movement_type: null,
				company_name: '',
				consignee_branch: '',
				address: '',
				latitude: '',
				longitude: '',
				zone_id: '',
				zone: '',
				cluster: '',
				cluster_id: '',
				consignee_email: '',
				start_time: '',
				end_time: '',
				pan_no: '',
				address_type: '',
				weekly_off: [],
				primary_consignee_name: '',
				primary_consignee_email: '',
				primary_consignee_contact: '',
				primary_consignee_code: '',
				secondary_consignee_code: '',
				channel_two:'',
				channel_one:'',
				sub_brand:'',
				snop_city: '',
				primary_gstin: '',
				secondary_gstin: '',
				zip_code: '',
				is_metro: null,
				region_state: '',
				region_state_code: '',
				country: '',
				country_code: '',
				contract_city:'',
				inserted_by: JSON.parse(getCookie("user")).user_id,
				contacts: [{
					name: "",
					role: "",
					mobile: "",
					email: ""
				}],
				contactsSeekerCompany: [{
					nameCompany: "",
					roleCompany: "",
					mobileCompany: "",
					emailCompany: ""
				}],
				productDetails: [{
					vehicle_type_id: "",
					vehicle_type_name: "",
					product_id: "",
					product_master_id: "",
					product_name: ""
				}],
				geofence_radius_sim: home.geofence_radius_sim,
				geofence_radius_gps: home.geofence_radius_gps

			},
			// loading : {
			//     'gatepass_generation': false,
			//     'first_loading_weightment' : false,
			//     'second_loading_weightment' : false,
			//     'load_start': false,
			//     'load_end': false,
			//     'gate_out': false
			// },
			// unloading : {
			//     'gatepass_generation': false,
			//     'first_loading_weightment' : false,
			//     'second_loading_weightment' : false,
			//     'load_start': false,
			//     'load_end': false,
			//     'gate_out': false
			// },
			iserror: '',
			errormessage: '',
			isValidate: false,
            validationMsg: ''
		}
    }

    componentDidMount() {
		this.props.getstateF()
		this.props.getCountry()
		attachGoogleKeyScript('AIzaSyBZS3IcyJ42uPzK8z5ZvbK5Fd99j0NqMRE')
	  }

    onAddPointofContact = () => {
        let { basicData } = this.state;
        const data= {
                    name : "",
                    role : "",
                    mobile : "",
                    email : ""
               }
        basicData.contacts.push(data);
        this.setState({ basicData });
    }


	onChangeProductDetails = (e, id, index) => {
		let { basicData } = this.state;
		if(id === 'vehicle_type_name'){
			basicData.productDetails[index][id] = e.label;
			basicData.productDetails[index].vehicle_type_id = e.value;
		} else {
			basicData.productDetails[index].product_id = e.value;
			basicData.productDetails[index][id] = e.label;
			basicData.productDetails[index].product_master_id = e.productId;
		}
		this.setState({ basicData });
	}
    onDeletePointofContact = (index) => {
        let { basicData } = this.state;
        delete basicData.contacts[index];
        this.setState({ basicData });
    }

    onloading = (id) => {
        const { loading } = this.state;
        loading[id] = !loading[id];
        this.setState({ loading });
    }

    onUnloading = (id) => {
        const { Unloading } = this.state;
        Unloading[id] = !Unloading[id];
        this.setState({ Unloading });
    }

    onChangeHandler = (value, id, isType) => {
        let { basicData } = this.state;
		if(id === 'secondary_gstin' || id === 'pan_no' || id === 'primary_gstin' || id === 'zip_code'){
			basicData[id] = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g,'');
		} 
		else if (id === 'primary_consignee_contact'){
			const result = validationCheck(value, basicData[id], isType);
            basicData[id] = result;
		}
		else if (id === 'primary_consignee_name'){
			basicData[id] = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}0-9]/g,'');
		}
		else {
			basicData[id] = value
		}
        this.setState({ basicData });
    }

    onBlurChange = (e) => {
        if(e.target.id == 'consignee_email'|| e.target.id === 'primary_consignee_email'){
            const errMsg = legthValidation('email', e.target.value);
            if( errMsg && e.target.value){
                this.setState ({
                    iserror: e.target.id,
                    errormessage: errMsg
                })
            } else if(this.state.iserror){
                this.setState({iserror: ''});
            }
        } else if(e.target.id == "primary_consignee_code" && AWL_COMPANY_IDS.includes(this.props.companyId)){
			let { basicData } = this.state;
			let result =  (e.target.value + "").padStart(10, "0");
			basicData.primary_consignee_code = result;
			this.setState({ basicData })
		}
		else {
            this.getLatLong(e.target.value.replace('#',''));
        }
    }

    handleChange = (id, selcetedOption) => {
        let { basicData } = this.state;
        if(id === 'weekly_off'){
            basicData.weekly_off = [];
            for(let i= 0 ; i < selcetedOption.length; i++){
                basicData.weekly_off.push(selcetedOption[i].value);
            }
        } else {
            basicData[id] = selcetedOption.value
        }
        this.setState( { basicData });
    }

    onChangePointofContact = (e, id , index) => {
        if(id == 'mobile' && e.target.value.length > 10){
            return;
        }
        let { basicData } = this.state;
        basicData.contacts[index][id] = e.target.value;
        this.setState( { basicData });
    }

    getLatLong = (address) => {
        if(address) {
        // Geocode.fromAddress(address).then(
        //     response => {
        //       const { lat, lng } = response.results[0].geometry.location;
        //       let { basicData } = this.state;
        //       basicData.latitude = lat;
        //       basicData.longitude = lng;
        //       this.setState ({ basicData });
        //     },
        //     error => {
        //       console.error(error);
        //     }
        //   );
        geocodeAddress(address).then(
            (response) => {
              const { lat, lng } = response[0].geometry.location;
              let { basicData } = this.state;
              basicData.latitude = lat();
              basicData.longitude = lng();
              this.setState ({ basicData });
            },
            error => {
              console.error(error);
            }
          );
	
        } else {
            const { basicData } = this.state;
            basicData.longitude = '';
            basicData.latitude = '';
            this.setState ({ basicData });
        }
    }

    isValidateFields = () => {
        const { basicData } = this.state;
        let isState = true;
        const isNumeric = /^\d+$/.test(basicData.primary_consignee_code);
        if(!basicData.network_type){
            this.setState({validationMsg: 'Chain Type'})
            isState = false;
        } else if(!basicData.company_name){
            this.setState({validationMsg: 'Company Name'})
            isState = false;
        } else if(!basicData.consignee_branch){
            this.setState({validationMsg: 'Consignee Branch'})
            isState = false;
        }else if(!basicData.primary_consignee_code){
            this.setState({validationMsg: 'Consignee Code'})
            isState = false;
        }
		//  else if(!isNumeric){
        //     this.setState({validationMsg: 'Consignee Code should be numeric'})
        //     isState = false;
        // }
		else if(!basicData.primary_consignee_email){
            this.setState({validationMsg: 'Email'})
            isState = false;
        } else if(!basicData.address){
            this.setState({validationMsg: 'Address'})
            isState = false;
        } else if(!basicData.address_type){
            this.setState({validationMsg: 'Address Type'})
            isState = false;
        } else if(!basicData.address_type == 9){
            if(!basicData.region_state){
                this.setState({validationMsg: 'Region/State'})
                isState = false;
            } else if(!basicData.zone_id){
                this.setState({validationMsg: 'Zone'})
                isState = false;
            } else if(!basicData.zip_code){
                this.setState({validationMsg: 'Zip Code'})
                isState = false;
            } else if(AWL_COMPANY_IDS.includes(basicData.company_id) && !basicData.cluster){
                this.setState({validationMsg: 'Cluster'})
                isState = false;
            } else if(!basicData.address){
                this.setState({validationMsg: 'Address'})
                isState = false;
            } else if(!basicData.country){
                this.setState({validationMsg: 'Country'})
                isState = false;
            } 
        }
        
        return isState;
    }


	onAddLogistics = () => {
		if (this.isValidateFields() ) {
			const data = this.state.basicData;
			data.is_metro = data.is_metro === 1 ? true : false;
			this.props.addNetworkChain(data);
		} else {
			this.setState({ isValidate: true });
		}
	}
    autoPop = (id, selectedOption) => {
	
		let { basicData } = this.state;
		const { stateListData, companyId } = this.props;
		
		if(id === 'region_state'){
			const getCompanySpecificList =  (stateListData || []).find(el => el.name === selectedOption.label && el.company_id === companyId);
			if(getCompanySpecificList) {
				basicData['region_state_code'] = getCompanySpecificList.value;
				basicData['cluster'] = getCompanySpecificList.cluster;
				basicData['cluster_id'] = getCompanySpecificList.cluster_id;
				basicData['zone_id'] = getCompanySpecificList.zone_id;
				basicData['zone'] = getCompanySpecificList.zone;
				basicData['country'] = getCompanySpecificList.country;
				basicData['country_code'] = getCompanySpecificList.ccode;
				basicData[id] = selectedOption.label;
			} else {
				basicData['region_state_code'] = selectedOption.value;
				basicData['cluster'] = selectedOption.cluster;
				basicData['cluster_id'] = selectedOption.cluster_id;
				basicData['zone_id'] = selectedOption.zone_id;
				basicData['zone'] = selectedOption.zone;
				basicData['country'] = selectedOption.country;
				basicData['country_code'] = selectedOption.ccode;
				basicData[id] = selectedOption.label;
			}

			if(AWL_COMPANY_IDS.includes(this.props.companyId)) {
				basicData['cluster'] = selectedOption.cluster;
				basicData['cluster_id'] = selectedOption.cluster_id;
				basicData['zone_id'] = selectedOption.zone_id;
				basicData['zone'] = selectedOption.zone;
                basicData['country_code'] = selectedOption.ccode;
                basicData['country'] = selectedOption.country;
			}
		}else{
			basicData['country_code'] = selectedOption.value;
			basicData[id] = selectedOption.label;
		}
		
		this.setState({ basicData });
	  };

	  onCancel = () => {
		this.props.toggleAdd()
		window.location.reload()
	  }

		onChangePointofContactCompany = (e, id, index) => {
			if (id == 'mobile' && e.target.value.length > 10) {
				return;
			}
			let { basicData } = this.state;
			basicData.contactsSeekerCompany[index][id] = e.target.value;
			this.setState({ basicData });
		}

    render() {
        const details = this.state.basicData;
        const optionZone = [];
        let entityOptions = [],
            chainOptions = [];
			let optionProduct = [];
			let optionVehicle = [];

        if (this.props.zones) {
            for (let i = 0; i < this.props.zones.length; i++) {
                optionZone.push({
                value: this.props.zones[i].id,
                label: this.props.zones[i].name
                });
            }
        }
        const {stateListData, companyId }=this.props;
        const StateDataList =
        (stateListData || []).map((data) => ({
        label: data.name,
        value: data.code,
        ...data
        })) || [];


        const {countryListData }=this.props;
        const countryList =
        (countryListData || []).map((data) => ({
        label: data.name,
        value: data.iso_code,
        })) || [];

		if(this.props.productList){
			for(let i = 0; i < this.props.productList.length-1; i++) {
				optionProduct.push({
					value: this.props.productList[i].product,
					label: this.props.productList[i].materialName,
					productId: this.props.productList[i].productMasterId
				});
			} 
		}
		if (this.props.vehicles) {
			for (let i = 0; i < this.props.vehicles.length; i++) {
				optionVehicle.push({
					value: this.props.vehicles[i].id,
					label: this.props.vehicles[i].name
				});
			}
		}

        return(
            <div className = "add-chain">
                <div onClick = {this.props.toggleAdd} className = "overlay"></div>
                <div id = "modal" className = "modal-popup">
                    <div className = "pop-heading">
                        Add Chain
                    </div>
					<div className="content">
						<div className="details-section">
							<div className="heading-section network-consignee">Network And Consignee Details</div>
							<div className="details">
								<div className="select-wrap">
									<div className="label">Chain Type<sup className={'error error-font'}>*</sup></div>
									<Select
										className="select"
										options={netWorkTypes}
										value={this.state.network_type}
										onChange={this.handleChange.bind(
											this,
											"network_type"
										)}
									/>
								</div>
								<div className="select-wrap">
									<div className="label">Movement Type</div>
									<Select
										className="select"
										options={movementTypes}
										value={this.state.movement_type}
										onChange={this.handleChange.bind(
											this,
											"movement_type"
										)}
									/>
								</div>
								<InputText
									placeholder="Company Name"
									id={"company_name"}
									label="Company Name"
									changeHandler={this.onChangeHandler}
									value={details.company_name}
									required={true}
								/>
								<InputText
									placeholder="Consignee Branch"
									id={"consignee_branch"}
									label="Consignee Branch"
									changeHandler={this.onChangeHandler}
									value={details.consignee_branch}
									required={true}
								/>
								<InputText
									placeholder="PAN"
									id={"pan_no"}
									label="PAN"
									changeHandler={this.onChangeHandler}
									value={details.pan_no}
									length={'10'}
								/>
								<InputText
									placeholder="GSTIN 1"
									id={"primary_gstin"}
									label="GSTIN 1"
									changeHandler={this.onChangeHandler}
									value={details.primary_gstin}
									length={'15'}
								/>
								<InputText
									placeholder="GSTIN 2"
									id={"secondary_gstin"}
									label="GSTIN 2"
									changeHandler={this.onChangeHandler}
									value={details.secondary_gstin}
									length={'15'}
								/>
								{/* <InputText
                                    placeholder="Party"
                                    id={"Party"}
                                    label="Party"
                                /> */}
								<div className="select-wrap">
									<div className="label">Address Type<sup className={'error error-font'}>*</sup></div>
									<Select
										className="select"
										options={addressType}
										value={this.state.chain_type}
										onChange={this.handleChange.bind(
											this,
											"address_type"
										)}
									/>
								</div>

								{/*<div className="select-wrap">
                                     <div className="label">Entity Type</div>
                                    <Select
                                        className="select"
                                        options={entityType}
                                        value={this.state.entity_type} 
                                        onChange={this.handleChange.bind(
                                            this,
                                            "entity_type"
                                        )}
                                    />
                                </div>*/}

								<InputText
									placeholder="Consignee Code 1"
									id={"primary_consignee_code"}
									label="Consignee Code"
									changeHandler={this.onChangeHandler}
									value={details.primary_consignee_code}
                                    required={true}
									additionalProps={{ onBlur: this.onBlurChange }}
									length={'10'}
								/>
								{!ABFRL_COMPANY_IDS.includes(companyId) &&
								<InputText
									placeholder="Consignee Code 2"
									id={"secondary_consignee_code"}
									label="Consignee Code"
									changeHandler={this.onChangeHandler}
									value={details.secondary_consignee_code}
									length={'10'}
								/>}
								{PANTALOONS_COMPANY_IDS.includes(this.props.companyId) &&
								<InputText
									placeholder="Channel"
									id={"channel_two"}
									label="Channel"
									changeHandler={this.onChangeHandler}
									value={details.channel_two}
								/>}
								<InputText
                                    placeholder="Channel"
                                    id={"channel_one"}
                                    label="Channel"
                                    changeHandler={this.onChangeHandler}
                                    value={details.channel_one}
                                />
								<InputText
									placeholder="Sub Brand"
									id={"sub_brand"}
									label="Sub Brand"
									changeHandler={this.onChangeHandler}
									value={details.sub_brand}
								/>
								{PANTALOONS_COMPANY_IDS.includes(companyId) &&
								<InputText
									placeholder="SNOP City"
									id={"snop_city"}
									label="SNOP City"
									changeHandler={this.onChangeHandler}
									value={details.snop_city}
								/>}
								
							</div>
						</div>


						<div className={'details-section'}>
							<div className="heading-section">Primary Contact</div>
							<div className={'details'}>
								<InputText
									placeholder="Name"
									id={"primary_consignee_name"}
									label="Name"
									changeHandler={this.onChangeHandler}
									value={details.primary_consignee_name}
									
								/>
								<InputText
									placeholder="Email"
									id={"primary_consignee_email"}
									label="Email"
									changeHandler={this.onChangeHandler}
									value={details.primary_consignee_email}
									additionalProps={{ onBlur: this.onBlurChange }}
									iserror={this.state.iserror}
									errormessage={this.state.errormessage}
									required={true}
								/>
								<InputText
									placeholder="Contact"
									id={"primary_consignee_contact"}
									label="Contact"
									changeHandler={this.onChangeHandler}
									value={details.primary_consignee_contact}
									length={'10'}
						            isValidate={'numeric'}
									//type='number'
								/>
							</div>
						</div>


						<div className={'address-section details-section'}>
							<div className="heading-section">Address</div>
							<div className={'details'}>
                                
                                <div className={'refresh'} >
                                    <div className={'refresh-icon'} style={{ backgroundImage: `url(${Refresh})`, marginTop: '5px' }} onClick={() => this.getLatLong(this.state.basicData.address)}></div>
                                    <InputText
                                        placeholder="Address"
                                        id={"address"}
                                        label={"Address"}
                                        changeHandler={this.onChangeHandler}
                                        additionalProps={{ onBlur: this.onBlurChange }}
                                        value={details.address}
                                        required={details.address_type !== 9 && true}
                                        />
                                </div>
								{/* <InputText
                                    placeholder="Region"
                                    id={"region"}
                                    label={"Region"}
                                /> */}
								
									<InputText
										placeholder="Latitude"
										id={"latitude"}
										label={"Latitude"}
										value={details.latitude}
                                        changeHandler={this.onChangeHandler}
                                        additionalProps={{ onBlur: this.onBlurChange }}
									/>
								
									<InputText
										placeholder="Longitude"
										id={"longitude"}
										label={"Longitude"}
										value={details.longitude}
                                        changeHandler={this.onChangeHandler}
                                        additionalProps={{ onBlur: this.onBlurChange }}
									/>
								<div className="select-wrap">
								<div className="label">Region/State{details.address_type !== 9 && <sup className={'error error-font'}>*</sup>}</div>
								<Select
									className="select"
									options={StateDataList}
									value={StateDataList.find((val) => {
										return val.label == details.region_state})}
									onChange={this.autoPop.bind(this, "region_state")}
									required={true}
								 
								/>
							   	</div>
								<div className="select-wrap">
									<div className="label">Zone{details.address_type !== 9 && <sup className={'error error-font'}>*</sup>}</div>
									<Select
										className={`select ${AWL_COMPANY_IDS.includes(companyId) ? 'pointer-event' : ''}`}
										options={optionZone}
										value={optionZone.filter(zone => zone.value === details.zone_id)}
										onChange={this.handleChange.bind(this, 'zone_id')}
									/>
								</div>

								<div className="select-wrap">
									<div className="label">Cluster{AWL_COMPANY_IDS.includes(companyId) && details.address_type !== 9 && <sup className={'error error-font'}>*</sup>}</div>
									<Select
										className={`select ${AWL_COMPANY_IDS.includes(companyId) ? 'pointer-event' : ''}`}
										options={CLUSTERS}
										value={CLUSTERS.filter(cluster => cluster.label === details.cluster)}
										onChange={this.handleChange.bind(this, 'cluster')}
									/>
								</div>

								<InputText
									placeholder="ZIP Code"
									id={"zip_code"}
									label={"ZIP Code"}
									changeHandler={this.onChangeHandler}
									value={details.zip_code}
                                    required={details.address_type !== 9 && true}
									length={'6'}
								/>
								{DEEPAK_COMPANY_ID.includes(details.company_id) &&
								<InputText
									placeholder="Contract City"
									id={"contract_city"}
									label={"Contract City"}
									changeHandler={this.onChangeHandler}
									value={details.contract_city}
								
								/>}

								<InputText 
									readonly
									placeholder="Region/State Code"
									id={"region_state"}
									label={"Region/State Code"}
									value={details.region_state_code}
									changeHandler={()=>this.onChangeHandler()}
                                    required={details.address_type !== 9 && true}
								/>
                                <div className="select-wrap">
                                    <div className="label">Country{details.address_type !== 9 && <sup className={'error error-font'}>*</sup>}</div> 
                                    <Select
									className="select"
									options={countryList}
									value={countryList.find((val) => {
										return val.label == details.country})}
									onChange={this.autoPop.bind(this, "country")}
								/>
                                </div>
								<InputText
									placeholder="Country Code"
									id={"country"}
									label={"Country Code"}
									changeHandler={()=>this.onChangeHandler()}
									value={details.country_code}
								/>	

								<InputText
									label="Indent Request Approval Email"
									placeholder="Email"
									id={"consignee_email"}
									value={details.consignee_email}
									changeHandler={this.onChangeHandler}
									additionalProps={{ onBlur: this.onBlurChange }}
									iserror={this.state.iserror}
									errormessage={this.state.errormessage}
								/>
								<div className="select-wrap">
									<div className="label">Metro Type</div>
								<Select
										className="select"
										options={metroType}
										value={metroType.filter(val => val.value == details.is_metro)}
										onChange={this.handleChange.bind(
											this,
											"is_metro"
										)}
									/>
								</div>

								<InputText
									label="geofence_radius_sim"
									placeholder="geofence_radius_sim"
									id={"geofence_radius_sim"}
									value={details.geofence_radius_sim}
									changeHandler={this.onChangeHandler}
									additionalProps={{ onBlur: this.onBlurChange }}
									iserror={this.state.iserror}
									errormessage={this.state.errormessage}
									type='number'
								/>

                                <InputText
									label="geofence_radius_gps"
									placeholder="geofence_radius_gps"
									id={"geofence_radius_gps"}
									value={details.geofence_radius_gps}
									changeHandler={this.onChangeHandler}
									additionalProps={{ onBlur: this.onBlurChange }}
									iserror={this.state.iserror}
									errormessage={this.state.errormessage}
									type='number'
								/>

							</div>
						</div>

						<InputText
									label="Geofence radius sim"
									placeholder="geofence_radius_sim"
									id={"geofence_radius_sim"}
									value={details.geofence_radius_sim}
									changeHandler={this.onChangeHandler}
									additionalProps={{ onBlur: this.onBlurChange }}
									iserror={this.state.iserror}
									errormessage={this.state.errormessage}
									type='number'
								/>

                                <InputText
									label="Geofence radius gps"
									placeholder="geofence_radius_gps"
									id={"geofence_radius_gps"}
									value={details.geofence_radius_gps}
									changeHandler={this.onChangeHandler}
									additionalProps={{ onBlur: this.onBlurChange }}
									iserror={this.state.iserror}
									errormessage={this.state.errormessage}
									type='number'
								/>
						{/* <div className={'details-section'}>
                            <div className="heading-section">Location type, region and Zone</div>
                            <div className={'details'}>
                                <div className="select-wrap">
                                    <div className="label">Relation</div>
                                    <Select
                                    className="select"
                                    />
                                </div>
                                <div className="select-wrap">
                                    <div className="label">Movement type</div>
                                    <Select
                                    className="select"
                                    />
                                </div>
                                <div className="select-wrap">
                                    <div className="label">Zone</div>
                                    <Select
                                    className="select"
                                    />
                                </div>
                            </div>
                        </div> */}
						{/* <div className={'details-section'}>
                            <div className="heading-section">Email</div>
                            <div className={'details'}>
                                <div className="select-wrap">
                                    <div className="label">Relation</div>
                                    <Select
                                    className="select"
                                    />
                                </div>
                                <div className="select-wrap">
                                    <div className="label">Movement type</div>
                                    <Select
                                    className="select"
                                    />
                                </div>
                                <InputText
                                    label="Indent request approval email"
                                    placeholder={'indent'}
                                />
                            </div>
                        </div> */}
						<div className={'details-section'}>
							<div className="heading-section">Start And End Timings</div>
							<div className={'details'}>
								<div className="data-picker">
									<div className="label">
										Daily Report Start Time
                                        {/*<sup className={'error error-font'}>*</sup>*/}
									</div>
									<TimePicker
										format={'h:mm a'}
										showSecond={false}
										placeholder={'Time'}
										onChange={(value) => {
											let { basicData } = this.state;
											basicData.start_time = Moment(value).format('HH:mm');
											this.setState({ basicData });
										}
										}
										use12Hours
									/>
								</div>
								<div className="data-picker">
									<div className="label">
										Daily Report End Time
                                        {/*<sup className={'error error-font'}>*</sup>*/}
									</div>
									<TimePicker
										format={'h:mm a'}
										showSecond={false}
										placeholder={'Time'}
										onChange={(value) => {
											let { basicData } = this.state;
											basicData.end_time = Moment(value).format('HH:mm');
											this.setState({ basicData });
										}
										}
										use12Hours
									/>
								</div>
								<div className="select-wrap">
									<div className="label">
										Week-off
                                        {/*<sup className={'error error-font'}>*</sup>*/}
									</div>
									<Select
										className="select"
										options={weeklyOff}
										value={this.state.weekly_off}
										onChange={this.handleChange.bind(this, 'weekly_off')}
										isMulti={true}
									/>
								</div>
							</div>
						</div>
					</div>
					
					{DEEPAK_COMPANY_ID.includes(details.company_id) &&  <div className="point-of-contact content">
					
					<div className={'heading-section'}>Product Mapping</div>
					<div className={'list-section header'}>
						<div className={'tabel-header name'}>Product Name</div>
						{/* <div className={'tabel-header name'}>Product Code</div> */}
						<div className={'tabel-header name'}>Vehicle Type</div>
					</div>
					{details.productDetails.map((val, index) => {
						return (<div className={'list-section'} key={index}>
							<Select
								className="tabel-header-input name select"
								options={optionProduct}
								value={optionProduct.filter(prod => prod.value === val.product_id)}
								onChange={(e) => this.onChangeProductDetails(e, 'product_name', index)}
							/>
							{/* <div className="tabel-header">{val.product_id || '-'}</div> */}
							<Select
								className="tabel-header-input name select"
								options={optionVehicle}
								value={optionVehicle.filter(vehicle => vehicle.value === val.vehicle_type_id)}
								onChange={(e) => this.onChangeProductDetails(e, 'vehicle_type_name', index)}
							/>
							<div className={'tabel-header'}>
								<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} 
									//  onClick={() => this.toggleDelete(index)} 
									 onClick={() => this.onDeleteProductDetails(index)}>
								</div>
							</div>
						</div>)
					})
					}
					<div className='contact-btn'>
						<Button value="+ Add" click={this.onAddProductDetails} />
					</div>
				</div>
				}

					<div className="point-of-contact content">
						<div className={'heading-section'}>Point Of Contact</div>
						<div className={'list-section header'}>
							<div className={'tabel-header name'}>Name</div>
							<div className={'tabel-header'}>Role</div>
							<div className={'tabel-header'}>Mobile</div>
							<div className={'tabel-header email'}>Email</div>
							<div className={'tabel-header'}>Action</div>
						</div>
						{details.contacts.map((val, index) => {
							return (<div className={'list-section'} key={index}>
								<input className={'tabel-header-input name code-value'} value={val.name} onChange={(e) => this.onChangePointofContact(e, 'name', index)}></input>
								<input className={'tabel-header-input code-value'} value={val.role} onChange={(e) => this.onChangePointofContact(e, 'role', index)}></input>
								<input className={'tabel-header-input code-value'} type='number' value={val.mobile} onChange={(e) => this.onChangePointofContact(e, 'mobile', index)} length={'10'}></input>
								<input className={'tabel-header-input email code-value'} value={val.email} onChange={(e) => this.onChangePointofContact(e, 'email', index)}></input>
								<div className={'tabel-header'}>
									<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} 
										//  onClick={() => this.toggleDelete(index)} 
										 onClick={() => this.onDeletePointofContact(index)}>
									</div>
								</div>
							</div>)
						})
						}
						<div className='contact-btn'>
							<Button value="+ Add" click={this.onAddPointofContact} />
						</div>
					</div>
					
					{/* ( Seeker Company)
					*/}
					

					{DEEPAK_COMPANY_ID.includes(details.company_id) &&  <div className="point-of-contact content">
					
						<div className={'heading-section'}>Point Of Contact{details.company_name || " (Seeker Company)"} </div>
						<div className={'list-section header'}>
							<div className={'tabel-header name'}>Name</div>
							<div className={'tabel-header'}>Role</div>
							<div className={'tabel-header'}>Mobile</div>
							<div className={'tabel-header email'}>Email</div>
							<div className={'tabel-header'}>Action</div>
						</div>
						{details.contactsSeekerCompany.map((val, index) => {
							return (<div className={'list-section'} key={index}>
								<input className={'tabel-header-input name code-value'} value={val.nameCompany} onChange={(e) => this.onChangePointofContactCompany(e, 'nameCompany', index)}></input>
								<input className={'tabel-header-input code-value'} value={val.roleCompany} onChange={(e) => this.onChangePointofContactCompany(e, 'roleCompany', index)}></input>
								<input className={'tabel-header-input code-value'} type='number' value={val.mobileCompany} onChange={(e) => this.onChangePointofContactCompany(e, 'mobileCompany', index)}></input>
								<input className={'tabel-header-input email code-value'} value={val.emailCompany} onChange={(e) => this.onChangePointofContactCompany(e, 'emailCompany', index)}></input>
								<div className={'tabel-header'}>
									<div className={'delete-icon'} style={{ backgroundImage: `url(${Delete})` }} 
										//  onClick={() => this.toggleDelete(index)} 
										 onClick={() => this.onDeletePointofContactCompany(index)}>
									</div>
								</div>
							</div>)
						})
						}
						<div className='contact-btn'>
							<Button value="+ Add" click={this.onAddPointofContactCompany} />
						</div>
					</div>
					}
					{/* <div className="point-of-contact content">
                        <div className={'heading-section'}>Entities</div>
                        <div className={'list-section header'}>
                            <div className={'tabel-header name'}>Entity Type</div>
                            <div className={'tabel-header code'}>Code</div>
                            <div className={'tabel-header code'}>Action</div>
                        </div>
                            <div className={'list-section'}>
                                <Select className={'tabel-header name'} placeholder={'Select entity type'}/>
                                <input className={'tabel-header-input code-value'} value=''></input>
                                <div className={'tabel-header code'}>
                                    <div className={'delete-icon'} style={{backgroundImage: `url(${Delete})`}} onClick={() => this.onDeletePointofContact(index)}></div>
                                </div>
                            </div>
                        <div className='contact-btn'>
                            <Button value="+ Add" click={this.onAddEntity} />
                        </div>
                    </div>
                    <div className="point-of-contact content">
                        <div className={'heading-section'}>Loading section</div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'gatepass_generation'} selected={this.state.loading.gatepass_generation} toggle={this.onloading}/>
                                    <div className = {`branch`}>Gatepass generation</div>
                                    {this.state.loading.gatepass_generation && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'first_loading_weightment'} selected={this.state.loading.first_loading_weightment} toggle={this.onloading}/>
                                    <div className = {`branch`}>First loading weighment</div> 
                                    {this.state.loading.first_loading_weightment && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'second_loading_weightment'} selected={this.state.loading.second_loading_weightment} toggle={this.onloading}/>
                                    <div className = {`branch`}>Second loading weighment</div> 
                                    {this.state.loading.second_loading_weightment && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'load_start'} selected={this.state.loading.load_start} toggle={this.onloading}/>
                                    <div className = {`branch`}>Load Start</div> 
                                    {this.state.loading.load_start && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'load_end'} selected={this.state.loading.load_end} toggle={this.onloading}/>
                                    <div className = {`branch`}>Load End</div> 
                                    {this.state.loading.load_end && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'gate_out'} selected={this.state.loading.gate_out} toggle={this.onloading}/>
                                    <div className = {`branch`}>Gate out</div> 
                                   {this.state.loading.gate_out && <div><InputText placeholder="email"/></div>}
                        </div>
                    </div>
                    <div className="point-of-contact content">
                        <div className={'heading-section'}>Unloading section</div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'gatepass_generation'} selected={this.state.unloading.gatepass_generation} toggle={this.onUnloading}/>
                                    <div className = {`branch`}>Gatepass generation</div>
                                    {this.state.unloading.gatepass_generation && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'first_loading_weightment'} selected={this.state.unloading.first_loading_weightment} toggle={this.onUnloading}/>
                                    <div className = {`branch`}>First loading weighment</div> 
                                    {this.state.unloading.first_loading_weightment && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'second_loading_weighment'} selected={this.state.unloading.second_loading_weighment} toggle={this.onUnloading}/>
                                    <div className = {`branch`}>Second loading weighment</div> 
                                    {this.state.unloading.second_loading_weighment && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'load_start'} selected={this.state.unloading.load_start} toggle={this.onUnloading}/>
                                    <div className = {`branch`}>Load Start</div> 
                                    {this.state.unloading.load_start && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'load_end'} selected={this.state.unloading.load_end} toggle={this.onUnloading}/>
                                    <div className = {`branch`}>Load End</div> 
                                    {this.state.unloading.load_end && <div><InputText placeholder="email"/></div>}
                        </div>
                        <div className = "loading-section">
                                    <CustomCheckBox id={'gate_out'} selected={this.state.unloading.gate_out} toggle={this.onUnloading}/>
                                    <div className = {`branch`}>Gate out</div> 
                                    {this.state.unloading.gate_out && <div><InputText placeholder="email"/></div>}
                        </div>
                    </div> */}
					<div className={'Add-logistics-btn'}>
						{this.state.isValidate && <div className={'error'} style={{ fontSize: '14px', marginBottom: '5px', marginLeft: '40px' }}>{`Please Enter The Details For ${this.state.validationMsg} Mandatory Field`}</div>}
						<Button value={'Cancel'} click={this.onCancel} />
						<Button value={'Add Logistics Network'} click={this.onAddLogistics} />
					</div>
				</div>

				{this.state.showDelete && <div className="add-company-wrap">
					<div className="overlay"></div>
					<div className="modal-popup small-modal">
						<div>
							<div>Are You Sure ?</div>
							<div className="submit-section submit-area">
								<div className="button-section">
									<div className="add-button" onClick={this.onDeletePointofContact || this.toggleDelete}>Delete</div>
								</div>
								<div className="button-section">
									<div className="add-button" onClick={this.toggleDelete}>Close</div>
								</div>
							</div>
						</div>
					</div>
				</div>}

			</div>
		)
	}
}

const CustomCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}

export default AddChain;
