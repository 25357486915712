import React, { Component } from "react";
import { connect } from "react-redux";
import ACTIONS from "../../common/action_constant";
import InputText from "../../common/components/input-text/input-text";

class Emudhra extends Component {
    constructor(props) {
      super(props);
      this.state = {
        emudhra_details: '',
        id: props.companyId
      };
    }

    componentDidMount() {
        this.getEmudhraDetails();
    }

    getEmudhraDetails = () => {
        const { dispatch } = this.props;
        let data = "?companyId=" + this.state.id + "&companyType=2";
        dispatch({ type: ACTIONS.HOME.GET_BASIC_DETAILS, data, 
            onSuccess: (data) => {
                this.setState({
                    ...data
                })
              }
        });
    }
    
      onSave = () => {
        const { dispatch } = this.props;
        const { client_id, access_key, key_id } = this.state.emudhra_details;

        const data = {
            client_id,
            access_key,
            key_id
        }
        let param = "?id=" + this.state.id;
        dispatch({
          type: ACTIONS.HOME.SAVE_EMUDHRA_DETAILS,
          param,
          data,
          onSuccess: this.getEmudhraDetails
        })
      }

      onChangeHandler = (value, id) => {
        this.setState(prevState => ({
            emudhra_details: {
                ...prevState.emudhra_details,
                [id]: value
            }
        }));
    }

    render(){
        const emudhra_details = this.state.emudhra_details;
        const disable = !(emudhra_details['client_id'] && emudhra_details['access_key'] && emudhra_details['key_id']);
        return(
            <div style={{"padding": '50px'}}>
                <form className="form-section" autoComplete="nope">
                        <div className="flex route-import-root">
                            <div className="mb-10 mr-20">
                                <div className="header">Client id</div>
                                <div className="sub-container">
                                    <InputText className="wt-100p tCenter" 
                                    placeholder="Client id" 
                                    id={"client_id"} 
                                    value={emudhra_details.client_id} 
                                    changeHandler={this.onChangeHandler} />
                                </div>
                            </div>

                            <div className="mb-10">
                                <div className="header">Access key</div>
                                <div className="sub-container">
                                    <InputText className="wt-100p tCenter" 
                                    placeholder="Access key" 
                                    id={"access_key"} 
                                    value={emudhra_details.access_key} 
                                    changeHandler={this.onChangeHandler}/>
                                </div>
                            </div>

                            <div className="mb-10 mr-20">
                                <div className="header">Key id</div>
                                <div className="sub-container">
                                    <InputText className="wt-100p tCenter" 
                                    placeholder="Key id" 
                                    id={"key_id"} 
                                    value={emudhra_details.key_id} 
                                    changeHandler={this.onChangeHandler} />
                                </div>
                            </div>
                        </div>
                </form>
                <div className="submit-section" style={{display: 'block'}}>
                    <button className="add-button" 
                    onClick={this.onSave}
                    disabled={disable}>
                        Save
                    </button>
                </div>
        </div>
        )
    }

}

export default connect()(Emudhra);