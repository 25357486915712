import { combineReducers } from "redux";
import homeReducer from "./home/home.reducer";
import masterReducer from "./master/master.reducer";
import loginReducer from "./SignIn/login.reducer";
import broadcastReducer from "./broadcast/broadcast.reducer";
import loaderReducer from "./common/components/loader/loader_reducer";
import msgpopup from "./common/components/msg-popup/msg-popup-reducer";
import plantsDepots from "./home/plants-depots/plants-depots-reducer";
import contracts from "./home/contracts_management/contract_management_reducer";
import companyAccounts from "./home/reconciliation/reconcillation-reducer";
import dataGovernance from "./dataGovernance/dataGovernance.reducer";
import vendors from "./vendors/vendors.reducer";
import vendorForm from "./vendorForm/vendorForm.reducer";
import auditScreenReducer from "./pages/AuditScreen/auditScreenReducer";
import companyUserInfoScreenReducer from "./home/companyUserInfo/companyUserInfoScreenReducer";


const rootReducers = combineReducers({
  home: homeReducer,
  master: masterReducer,
  login: loginReducer,
  loader: loaderReducer,
  broadcast: broadcastReducer,
  vendorForm,
  dataGovernance,
  vendors,
  msgpopup,
  plantsDepots,
  contracts,
  companyAccounts,
  auditScreenReducer,
  companyUserInfoScreenReducer
});

export default rootReducers;
