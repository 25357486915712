import React, { Component, Fragment } from "react";
import "./LoginForm.scss";
import LoginFormCard from "../components/SignIn/LoginFormCard";
import LoginWithSso from "../components/SignIn/LoginWithSso";
import { connect } from 'react-redux';
import { ReactComponent as AlertIcon } from './icons/esclamation.svg';
import cx from 'classnames';
import { ToastContainer, toast } from "react-toastify";
import ACTION from "../common/action_constant";
import validate from "../../utils/validate";
import api from '../common/api_config';
import { getCookie } from '../../utils/cookies';
import { getSecureRandomKey } from '../../utils';
import MessagePopUp from "../common/components/msg-popup/msg-popup";


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordVisible: false,
      isLoginWithSso: false,
      email: "",
      password: "",
      isVerified: false,
      isSSOLogin: false,
      reCaptchaKey: 1,
      captcha_value: '',
      show_checkbox: false,
      isUserAlreadyLoggedin: false,
      errorMessage: "",
      force_logout: false,
    };
  }

  componentDidMount() {
      const {dispatch} = this.props;
      const token = getCookie("token");
      dispatch({
         type: ACTION.LOGIN.GET_SSO_CONFIG,
         params: {}
      })
      setTimeout(function () { 
         this.setState({ showTag: true })
      }.bind(this), 1000)
      if(!token) {
         return;
      } else {
         location.replace('/home')
      }
   }

  handlePasswordChange = (e) => {
    const password = e.target.value;
    this.setState({
      password,
    });
  };
  handleEmailChange = (e) => {
    const email = e.target.value;
    this.setState({
      email,
    });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));
  };

  backtoSendOtp = () => {
    this.setState((prevState) => ({
      isVerifyOtp: !prevState.isVerifyOtp,
    }));
  }

  toggleLoginWithSso = () => {
    this.setState((prevState) => ({
      isLoginWithSso: !prevState.isLoginWithSso,
      isSSOLogin: true,
      email: "",
      password: ""
    }));
  };

  shouldRenderLoginFormCard = () => {
    const {
      isOtpLogin,
      isVerifyOtp,
      isForgotPassword,
      isLoginWithSso,
    } = this.state;

    const C1 = !isOtpLogin;
    const C2 = !isVerifyOtp;
    const C3 = !isForgotPassword;
    const C4 = !isLoginWithSso;

    if (C1 && C2 && C3 && C4) {
      return true;
    }
  };

  shouldRenderLoginWithSsoFormCard = () => {
    const { isLoginWithSso } = this.state;

    const C1 = isLoginWithSso;

    if (C1) {
      return true;
    }
  };

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  validateSSOUser = () => {
    const { email } = this.state;
    const { dispatch } = this.props;
    if (!email) {
      toast.error("Email must not be empty!");
      return;
    }
    if (!this.validateEmail(email)) {
      toast.error("Please enter a valid email address!");
      return;
    }
    const data = {
      email
    }
    dispatch({
      type: ACTION.LOGIN.VALIDATE_SSO_USER,
      data,
      onSuccess: () => {
        localStorage.setItem('isSSOUser', true);
        window.location.replace(`${api.getSSO}/?sso&email=${email}&module=6&next=${window.location.origin}/home`);
      }
    })

  }

    verifyCallback = (response) => {
      this.setState((prevState)=>{
        const updatedState  =  {...prevState};
        updatedState.captcha_value = response
        updatedState.errorMessage = ""
        updatedState.isVerified = true;
        return updatedState
      })  
    }

  callback = () => {
    console.log('Done!!!!');
  };

  onExpire = () => {
    this.setState({
      isVerified: false
    });
  };


  onGenerateAnotherSession = () => {
    const { show_checkbox, invalidContact, invalidOTP } = this.state;
    this.setState({
      show_checkbox: !show_checkbox,
      errorMessage: '',
      force_logout: true
    }, () => {
      this.signin();
    })
  }


  signin = () => {
    const { dispatch } = this.props;
    const { email, password, companyType, force_logout,
      captcha_value, forceOtpLogin
    } = this.state;
    console.log("---in signin---");

    if (!email || !password) {
      toast.error("Email and password must not be empty!");
      return;
    }
    if (!this.validateEmail(email)) {
      toast.error("Please enter a valid email address!");
      return;
    }

    dispatch({
      type: ACTION.LOGIN.USERLOGIN,
      data: {
        email,
        password,
        device_type: 3,
        company_type: "",
        module_id: 6,
        force_logout: forceOtpLogin ? true : undefined,
        captcha_value
      },
      onError: (data) => {
        const isUserAlreadyLoggedin = data == 1
        const hideLoginBtn = data == 1
        const isForceLogout = data == 1
        this.setState({
          show_checkbox: isForceLogout ? true : undefined,
          isUserAlreadyLoggedin,
          hideLoginBtn,
          forceOtpLogin: isForceLogout,
        });
      }
    })
    this.setState((prevState) => {
      const updatedState = { ...prevState };
      updatedState.isVerified = false
      updatedState.reCaptchaKey = getSecureRandomKey()
      return updatedState
    })
  }

  closeMsgPopup = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTION.MSG_DISPLAY.HIDE_MSG })
  }

  render() {
    const {
      companyType,
      isPasswordVisible,
      password,
      email,
      show_checkbox,
    } = this.state;
    // console.log("render state", this.state)
    const {
      ssoConfigData,
      isSubmitting
    } = this.props;
    // console.log("render props", this.props)

    const disableTegLogin = (ssoConfigData || { disable_teg_login: true }).disable_teg_login;
    const isCustomDomain = (ssoConfigData || { is_custom_domain: true }).is_custom_domain;

    return (
      <div className="loginFormMain">
        {isSubmitting && <ActionLoader />}
        {
          this.props.msgpopup.active && <MessagePopUp
            close={this.closeMsgPopup}
            type={this.props.msgpopup.type}
            msg={this.props.msgpopup.msg}
          />
        }
        {this.shouldRenderLoginFormCard() && (
          <LoginFormCard
            isPasswordVisible={isPasswordVisible}
            togglePasswordVisibility={this.togglePasswordVisibility}
            handlePasswordChange={this.handlePasswordChange}
            password={password}
            toggleLoginWithSso={this.toggleLoginWithSso}
            handleEmailChange={this.handleEmailChange}
            email={email}
            signin={this.signin}

          />
        )}

        {this.shouldRenderLoginWithSsoFormCard() && (
          <LoginWithSso
            toggleLoginWithSso={this.toggleLoginWithSso}
            handleEmailChange={this.handleEmailChange}
            email={email}
            validateSSOUser={this.validateSSOUser}
          />
        )}
        {
          show_checkbox === true &&
          <div
            className="fs-14 flex flex-center col-1"
            style={{ background: "linear-gradient(87deg, #597EF7 15.86%, #C4BEFE 124.08%)"}}
            // style={{}}
          >
            <div
              className="theme-color fBold flex flex-center pt-5 mb-40 tCenter "
              style={{
                background: "wheat",
                padding: "1rem",
                "border-radius": "5px",
                "margin-top": "1rem",
                marginLeft: "400px" 
              }}
            >
              {/* <AlertIcon className="wt-10 flex ht-10 alert-icon" /> */}
              <div style={{ "padding-left": "5rem" }}>
                {
                  <Fragment>
                    <span className="error-color flex flex-center" style={{ fontSize: "16px" }}>
                      Your Previous Login / Session is still active and not closed properly.
                      To Logout previous sessions,
                      {<Fragment>
                        <a
                          onClick={this.onGenerateAnotherSession}
                          style={{
                            color: "white",
                            "box-shadow": "0 0 5px 0 black",
                            padding: "0.25rem",
                            "margin-left": " 0.5rem",
                            "border-radius": "5px",
                            background: "green",
                            cursor:"pointer"
                          }}
                        >
                          Click Here...
                        </a>
                      </Fragment>}
                    </span>
                  </Fragment>}
                {<div className="error-color flex flex-center fBold mt-5" style={{ fontSize: "16px" }}>Note: Any unsaved data in previous sessions will be lost</div>}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = state => {
   return {
      login: state.login,
      loader: state.loader,
      msgpopup: state.msgpopup,
      ssoConfigData: state.login.ssoConfigData
   };
};

export default connect(mapStateToProps)(Login);
