import materialDetails from "../home/material-details/material-details";

const baseURLV1 = "/api/0.1/";
const baseURLV2 = "/api/0.2/";
const envObj = process.env;

const baseUrl = envObj.BASE_URL + baseURLV1;
const baseUrlIndenting = envObj.INDENTING_ADMIN_URL + baseURLV1;

const plantBaseUrl = envObj.BASE_URL + baseURLV2;

const contractingUrl = envObj.CONTRACTING_URL + baseURLV1;
const GoogleAPIUrl = envObj.GOOGLE_API_URL;

const indentingUrl = envObj.INDENTING_URL + baseURLV1;
const trackingUrl = envObj.TRACKING_URL + baseURLV1;

const ssoBaseUrl = envObj.SSO_BASE_URL;
const accountBaseUrl = envObj.ACCOUNTS_BASE_URL;

const apis = {
   addCompany: baseUrl + "write/add/company",
   getHome: baseUrl + "read/fetch/home",
   updateStatus: baseUrl + "write/company/status",
   getBasicDetails: baseUrl + "read/basic/details",
   getBasicDetailsForVendorScreen: baseUrl + "read/basic/details",
   getEstbCategory: baseUrl + "read/estb/category",
   getBusinessSeg: baseUrl + "read/business/segment",
   uploadImg: baseUrl + "write/file/upload",
   updateBasicDetails: baseUrl + "write/basic/details",
   uploadMultipleFile: baseUrl + "write/provider/files/upload",
   deleteFileUrl: baseUrl + "write/provider/file?companyId=",
   saveCompanyCategoryDetails: baseUrl + "write/company/category/details",
   fetchCompanyCategoryDetails: baseUrl + "read/company/category/details?companyId=",
   fetchVendorDetails: baseUrl + "read/download/vendorlist?companyId=",
   fetchCargillVendorDetails: baseUrl + "download/vendor/company/category?companyId=",
   fetchAllModes: baseUrl + "read/company/types/details",
   fetchAllTypes: baseUrl + "read/company/modes/details",

   getPlantDepots: baseUrl + "read/plants",
   getIndentingFlows: baseUrlIndenting + "indenting/flows",
   getSubPlants: baseUrl + "read/subplants",
   getIndentingItems: baseUrlIndenting + "indenting/items",
   getIndentingUsers: baseUrlIndenting + "indenting/users",
   getIndentingFlowDetails: baseUrlIndenting + "indenting/flows/detail",
   getIndentingMoves: baseUrlIndenting + "indenting/moves",
   getIndentingActions: baseUrlIndenting + "indenting/actions",
   getIndentingChecklists: baseUrlIndenting + "indenting/checklists",
   getIndentingWarehouses: baseUrlIndenting + "indenting/warehouses",
   getIndentingVendors: baseUrlIndenting + "indenting/vendors",
   addMove: baseUrlIndenting + "indenting/moves",
   addAction: baseUrlIndenting + "indenting/actions",
   addChecklist: baseUrlIndenting + "indenting/checklists",
   addWarehouse: baseUrlIndenting + "indenting/warehouses",
   addVendor: baseUrlIndenting + "indenting/vendors",
   submitMoves: baseUrlIndenting + "indenting/flows",
   deleteMove: baseUrlIndenting + "indenting/flows/",
   addSubPlants: baseUrl + "write/addsubplant",
   zones: baseUrl + "read/zones",
   branchTypes: baseUrl + "read/branch/types",
   updatePlantDepotbasicDetails: plantBaseUrl + "write/plants/basic",
   getActionTypes: baseUrlIndenting + "indenting/actions/types",
   getMoveName: baseUrl + "read/indent/moves",
   getIndentingSubMoves: baseUrl + "read/submoves",
   // getMoveName: baseUrl + "read/indent/moves",


   getDepotUsers: baseUrl + "read/plants/user?companyId=",
   getBranches: baseUrl + "read/branch/types",
   verifyUser: baseUrl + "write/plants/verify/erp/user",
   getActions: baseUrl + "read/actions",
   addEditUser: baseUrl + "write/plants/user",
   toggleUserStatus: baseUrl + "write/plant/user/status",
   deleteUser: baseUrl + "write/plants/user",
   getAllBranch: baseUrl + "read/all/branch?companyId=",


   getProviderUsers: baseUrl + "read/fetch/provider/users?companyId=",
   addUpdateProviderUsers: baseUrl + "write/add/provider/users",
   fetchAllSeekers: baseUrl + "read/fetch/all/seekers",
   companyCategory: baseUrl + "read/all/company/category",

   //Material Details Screen(Adani)
   fetchmrpData:baseUrl + "read/fetch/mrp/data",
   downloadMRPTemplateUrl:baseUrl + "dowload/mrp/template",
   downloadMRPDataUrl:baseUrl + "write/download/mrp/data",
   uploadMRPUrl:baseUrl + "write/upload/mrp/data",


   //contracts management
   getBasicContractSetting: baseUrl + "read/rfq/contracts/basic?companyId=",
   getOtherContractSetting: baseUrl + "read/fetch/rfq/settings/master",
   createUpdateBasicContract: baseUrl + "write/rfq/contracts/basic",
   getCollapsibleSection: baseUrl + "read/rfq/contracts/alerts?branchId=",
   addCollapsibleSection: baseUrl + "write/rfq/contracts/alerts",
   deleteCollasibleSection: baseUrl + "write/rfq/contracts/alerts?branchId=",
   getBranchList: baseUrl + "read/branches?companyId=",
   applySettingsToBranches: baseUrl + "write/rfq/contracts/apply/branch",
   getRFQ: baseUrl + "read/rfq/names?",

   getRoles: baseUrl + "read/roles?companyId=",
   addRole: baseUrl + "write/add/role",
   deleteRole: baseUrl + "write/role",
   getDepartments: baseUrl + "read/departments?companyId=",
   addDepartment: baseUrl + "write/add/department",
   updateDepartment: baseUrl + "write/department",
   deleteDepartment: baseUrl + "write/department",

   login: ssoBaseUrl + "/auth/login/",
   routeImport: baseUrl + "read/sap/route?type=",
   routeExport: baseUrl + "write/upload/sap/route",

   getLOIApproverEmail: baseUrl + "read/fetch/approver/email?companyId=",
   //location network
   addNetworkChain: baseUrl + "write/location/network",
   updateNetworkChain: baseUrl + "write/location/network",
   getNetworkChain: baseUrl + "read/location/network?companyId=",
   getNetworkChainV1: baseUrl + "read/location/network",
   deleteNetworkChain: baseUrl + "write/location/network",
   uploadNetworkChain: baseUrl + "write/transportationchain/upload?companyId=",
   downloadNetworkChain: baseUrl + "read/transportationchain/download?companyId=",
   getState: baseUrl + "read/fetch/states",
   // getDPLbranchListDetails: baseUrl + "read/branch/name/id?",
   getCountry: baseUrl + "read/fetch/country",
   downloadNetworkTemp: baseUrl + "read/transportationchain/download/temp?companyId=",
   getDepotConsignee: baseUrl + "get/depot/consignees",
   updateDepotConsignee: baseUrl + "update/depot/consignees",
   addDepotConsignee: baseUrl + "create/depot/consignees",
   deleteDepotConsignee: baseUrl + "delete/depot/consignees",
   uploadDepotConsignee: baseUrl + "upload/depot/consignees?companyId=",
   downloadDepotConsignee: baseUrl + "get/depot/data/consignees?companyId=",
   downloadDepotConsigneeTemp: baseUrl + "download/depot/consignees",

   //Inventory Item
   getConstantValues: baseUrl + "read/productMaster/getConstants",
   getInventoryItem: baseUrl + "read/productMaster/get?company_id=",
   addInventoryItem: baseUrl + "write/productMaster/create",
   updateInventoryItem: baseUrl + "write/productMaster/edit",
   deleteInventoryItem: baseUrl + "write/productMaster/delete?productMasterId=",
   uploadInventoryItem: baseUrl + "write/productMaster/upload?company_id=",
   downloadInventoryItem: baseUrl + "write/productMaster/download?company_id=",
   getPackagingDetails: baseUrl + "read/packing/types?",


   // Account Master
   getAccountEvents: baseUrl + "read/event?type=",
   postAccountEvents: baseUrl + "write/event",
   deleteAccountEvents: baseUrl + "write/event",
   getCreditMaster: baseUrl + "read/accounts/credit/master?companyId=",
   postCreditMaster: baseUrl + "write/accounts/credit/master?companyId=",
   getDebitMaster: baseUrl + "read/accounts/company/master/event?companyId=",
   postDebitMaster: baseUrl + "write/event/accounts/company/master?companyId=",
   deleteDebitMaster:baseUrl + "write/event/accounts/company/master?companyId=",
   getVehicleType: baseUrl + "read/vehicle/types?serviceType=1&rfqType=1",
   getCompanyAccounts: baseUrl + "read/accounts/master?companyId=",
   postCompanyAccounts: baseUrl + "write/accounts/master?companyId=",
   deleteCompanyAccounts: baseUrl + "write/accounts/master?companyId=",
   deleteCreditMaster: baseUrl + "write/accounts/credit/master?companyId=",
   getBillNumber: baseUrl + "read/accounts/bill/master?companyId=",
   postBillNumber: baseUrl + "write/accounts/bill/master?companyId=",
   deleteBillNumber: baseUrl + "write/accounts/bill/master?companyId=",
   updateBillNumber: baseUrl + "write/accounts/bill/master?companyId=",
   getMaterialDetails: baseUrl + "read/productMaster/materialList?chainType=",
   getDetentionDetails: baseUrl + "read/debit/master/detention?companyId=",
   addDetentionDetails: baseUrl + "write/debit/master/detention?companyId=",
   getMultiPickupDropDetail: baseUrl + "read/multipoint/pickup?companyId=",
   addMultiPickupDropDetail: baseUrl + "write/multipoint/pickup?companyId=",
   getHolidayList: baseUrl + "read/holiday/list?companyId=",
   uploadHolidayList: baseUrl + "write/holidaylist/upload?companyId=",
   downloadHolidayList: baseUrl + "read/holidaylist/download?companyId=",
   getPayables: baseUrl + "read/accounts/credit/master/payables?companyId=",
   addPayables: baseUrl + "write/accounts/credit/master/payables?companyId=",
   updatePayables: baseUrl + "write/accounts/credit/master/payables?companyId=",
   deletePayables: baseUrl + "write/accounts/credit/master/payables?companyId=",

   // State  List 
   getTransporterList: baseUrl + "read/fetch/transporters?",
   getStateListUrl: baseUrl + "read/fetchAllStates",

   //Indnet Reasons
   getIndentReasons: baseUrl + "read/indentreasons?companyId=",
   addIndentReasons: baseUrl + "write/indentreasons?companyId=",
   updateIndentReasons: baseUrl + "write/indentreasons?reasonId=",
   deleteIndentReasons: baseUrl + "write/indentreasons?reasonId=",

   // Broadcast API's
   getAllEmails: baseUrl + "read/mail/broadcast",
   postAllEmails: baseUrl + "write/mail/broadcast",
   copyAllEmails: baseUrl + "read/mail/broadcastlist",

   //Transporters mapping API's
   getCompanyTransporters: baseUrl + "read/transporterListMaster?companyId=",
   addCompanyTransporters: baseUrl + "write/transporterListMaster?companyId=",
   deleteCompanyTransporters: baseUrl + "write/transporterListMaster?companyId=",
   getModuleLists: baseUrl + "read/fetch/modules?companyId=",
   saveModuleLists: baseUrl + "write/module/details?companyId=",

   getZIPCodeLists: baseUrl + "read/fetch/zipcode",

   // Master Data Governance
   getDriverInfo: baseUrl + "read/driver/information?companyId=",
   deleteDriverInfo: baseUrl+"write/delete/driver",
   addDriverInfo:baseUrl+"write/add/driver/information",
   updateDriverInfo: baseUrl+"write/add/driver/updatedrivers",
   downloadDriverTemplateURL : baseUrl + 'download/driver/details',
   driverUploadURL : baseUrl + 'upload/driver/details',

   getTruckInfo: baseUrl + "read/vehicles/information?companyId=",
   deleteTruckInfo: baseUrl + "write/delete/vehicle",
   addTruckInfo: baseUrl + "write/add/vehicles/information",
   updateTruckInfo: baseUrl + "write/add/vehicles/updatevehicle",
   getVehicleTypes: baseUrl + "read/vehicle/types?",
   getFuleTypes: baseUrl + "read/fuel/types?",
   uploadfiels: baseUrl + "write/file/upload",
   getDelayPenalty: baseUrl + "read/fetch/delay/penalty/details?type=",
   postDelayPenalty: baseUrl + "write/delay/penalty/details",
   deleteDelayPenalty: baseUrl + "write/delete",
   updateDelayPenalty: baseUrl + "write/delay/penalty/details/update",
   downloadVehicleTemplateURL : baseUrl + 'download/vehicle/details',
   uploadVehicleTemplateURL : baseUrl + 'upload/vehicle/details',

   downloadDelayPenalty: baseUrl + "read/delay/penalty/download?type=",
   uploadDelayPenalty: baseUrl + "write/delay/penalty/upload?type=",

   getVendorDetails: baseUrl + "read/vendor/details",
   getModeTypes: baseUrl + "read/fetch/typesof/mode",
   getChannelTypes: baseUrl + "read/gl/channel",
   getDistributionTypes: baseUrl + "read/gl/distribution/type",
   getDelayTypes: baseUrl + "read/delay/types",

   getLiabilityData: baseUrl + "read/liabilities?companyId=",
   postLiabilityData: baseUrl + "write/liabilities",
   updateLiabilityData: baseUrl + "write/liabilities",
   deleteLiabilityData: baseUrl + "write/deleteliabilities",
   downloadLiabilities: baseUrl + "read/downloadliabilities",
   //network master
   getNetworkMasterData: baseUrl + "fetch/NetworkMaster?company_id=",
   postNetworkMaster: baseUrl + "add/NetworkMaster",
   updateNetworkMasterdata:baseUrl+"update/NetworkMaster",
   downloadnetworkmasterData:baseUrl+"download/NetworkMasterTemp?company_id=",
   networkmasterupload:baseUrl+"upload/NetworkMaster?company_id=",
   downloadNetworkMaster:baseUrl+"download/NetworkMasterData?company_id=",
   getorigin_zoneNM:baseUrl+"get/NetworkMaster/Origin",
   getRoutenameNM:baseUrl+"get/NetworkMaster/Routenames",
   getmodeNM:baseUrl+"get/NetworkMaster/Mode",
   getNetworkNM:baseUrl+"get/NetworkMaster/Network?company_id=",


   getIndentFields: baseUrlIndenting + "indenting/getIndentfields",
   postIndentFields: baseUrlIndenting + "indenting/addField",
   getClaimTypes: baseUrl + "read/fetch/typesof/claims",
   getShortageTypes: baseUrl + "read/fetch/typesof/shortages",

   postVolumetricWeight: baseUrl + "write/volumetric/weight/details",
   getVolumetricWeightData: baseUrl + "read/fetch/volumetric/details?type=",
   deleteVolumetricWeightData: baseUrl + "write/volumetric/delete",

   getCityList: baseUrl + "read/locations?",
   getCityPincodes: baseUrl + "read/locations?",
   getLaneRoutes: baseUrl + "read/lans?",
   postLanes: baseUrl + "write/addlans",
   updateLanes: baseUrl + "write/update_lans",
   deleteLanes: baseUrl + "write/delete_compay_routes",
   getHubList: baseUrl + "read/hubslist?",

   uploadLane: baseUrl + "write/upload-lan-data",
   downloadLane: baseUrl + "read/download-lan?",

   updateVolumetricWeightData: baseUrl + "write/volumetric/details/update",
   downLoadVolumetricLink: baseUrl + "read/volumetric/template/download?",
   uploadVolumetricFile: baseUrl + "write/volumetric/weight/upload?",

   readVolumetricWeightTypes: baseUrl + "read/volumetric/weight/types?",
   postVolumetricWeightTypes: baseUrl + "write/volumetric/weight/types",
   deleteVolumetricWeightTypes: baseUrl + "write/volumetric/weight/types",
   // oda structure
   getTypesOfOda: baseUrl + "read/fetch/typesof/oda",
   getOdaMasterData: baseUrl + "read/oda-masters?",
   postOdaMasterData: baseUrl + "write/add-oda-masters",
   deleteOdaMaster: baseUrl + "write/delete-oda-masters",
   putOdaMasterData: baseUrl + "write/update-oda",

   // GL Code Mapping
   // getTypesOfOda: baseUrl + "read/fetch/typesof/oda",
   getGLCodeMasterData: baseUrl + "read/glcode?",
   postGLCodeMasterData: baseUrl + "write/glcode",
   deleteGLCodeMaster: baseUrl + "delete/glcode",
   putGLCodeMasterData: baseUrl + "write/glcode/update",

   uploadSlabData: baseUrl + "write/upload-slab-data",
   downloadSlabData: baseUrl + "read/download-slab-temp?",
   uploadOdaData: baseUrl + "write/upload-oda-data",
   downloadOdaData: baseUrl + "read/download-oda-temp?",

   getAllTypesOfOda: baseUrl + "read/fetch/typesof/oda",

   getPodVendorDetails: baseUrl + "read/pod/vendor",
   deletePodClause: baseUrl + "write/pod/delete",
   updatePodClause: baseUrl + "write/pod/update",
   postPodClause: baseUrl + "write/pod/add",
   getPodClauseData: baseUrl + "read/pod/details",
   getPodStatusUrl: baseUrl + "read/pod/status",
   getPodCyclesUrl: baseUrl + "read/pod/cycle",

   postCityMaster: baseUrl + "write/addtat",
   updateCityMaster: baseUrl + "write/update_tat",
   deleteCityMaster: baseUrl + "write/delete_tat_routes",
   getCityMaster: baseUrl + "read/tats?",
   getHubs: baseUrl + "read/city-hubs?",

   uploadCityMaster: baseUrl + "write/uploadtatmaster",
   downloadCityMaster: baseUrl + "read/downloadtatmaster?",

   getRMVendors: baseUrl + 'read/vendor',
   getRMModes: baseUrl + 'read/mode',
   getRMRegions: baseUrl + 'read/diesel/price/hike',
   getRMData: baseUrl + 'read/rate/master',
   getRMLanes: baseUrl + 'read/vendor/line/item',
   postRMData: baseUrl + 'write/rate/add',
   updateRMData: baseUrl + 'write/update',
   deleteRMData: baseUrl + 'write/rate/delete',
   downloadRMTemplate: baseUrl + 'read/rate/template/download',
   uploadRateMasters: baseUrl + 'write/rate/upload',

   getChargesList: baseUrl + "read/charges-master",
   getPaymentModes: baseUrl + "read/all/payment-mode",
   getScopesList: baseUrl + "read/all/scope",
   getServicesOffered: baseUrl + "read/all/services_offered",
   getAllVendorModes: baseUrl + "read/master/modes",

   getAllVendors: baseUrl + "read/onboard/vendor/all",
   getOnboardVendorDetails: baseUrl + "read/onboard/vendor/details",
   fetchAllOnboardVendors: baseUrl + "read/all/onboard/vendor",
   getVendorModeDropdown: baseUrl +"read/onboard/vendor/mode_types",

   addPrimaryVendorDetails: baseUrl + "write/onboard/vendor/add-obv",
   addSecondaryVendorDetails: baseUrl + "write/onboard/vendor/add-obv2",
   updateAllVendorDetails: baseUrl + "write/onboard/vendor/add-obv",
   verifyVendorContactsURL: baseUrl + 'write/onboard/vendor/add-obv',
   addPrimaryVendorFirstTime: baseUrl + 'write/verify/email',
   sendOTPURL: baseUrl + 'write/get/vendor/otp',
   verifyOTPURL: baseUrl + 'write/verify/vendor/otp',
   uploadVendorOperationalAreas: baseUrl + "write/onboard/vendor/upload-opreational-area-data",
   downloadOperationalAreas: baseUrl + "read/download-opreational-area",
   uploadVendorLogo: baseUrl + "write/onboard/vendor/upload-logo",
   getVendorRatingQuestions: baseUrl + "read/vendor/feedbackquestions",

   vendorApproval: baseUrl + "write/approval-matrix/add-approval", //"write/onboard/vendor/add-approval",
   activateVendor: baseUrl + "write/onboard/vendor/make-active",
   getVendorOnboardAreas: baseUrl + "read/onboard/vendor/operational-areas",
   uploadVendorDocs: baseUrl + "write/upload/onboard/documents",
   uploadVendorDeclarationFileURL: baseUrl + 'write/upload/onboard/declarations',
   vendorGSTDetails: baseUrl + "upload/vendor/gstindetails",
   vendorGSTDownload: baseUrl + "download/vendor/gstindetails",
   getBankDetailsFromIFSCUrl: 'https://ifsc.razorpay.com/',
   uploadExternalApproverDocURL: baseUrl + 'write/upload/onboard/documents/external',
   uploadApproverDocURL: baseUrl + 'write/upload/onboard/documents/approver',
   downloadVehicleDetailsTemplate: baseUrl + "download/vendor/template/vehicledetails",
   getUploadedVehicleDetails: baseUrl + "upload/vendor/vehicledetails",
   getPaymentTermsURL: baseUrl + 'read/onboard/vendor/paymentTerms',
   uploadBankDocumentURL: baseUrl + "write/upload/onboard/bankDocuments",
   saveBankApprovalDataURL: baseUrl + "write/save/onboard/bankDetails",
   getVendorSourceDropdown: baseUrl +"read/onboard/vendor/source?company_id=",

   getRouteCode: contractingUrl + "read/fetchAllSapRouteCode",
   addRouteCode: contractingUrl + "write/addSapRouteCode",
   editRouteCode: contractingUrl + "write/editSapRouteCode",
   getDistrict: baseUrl + "read/districts?code=",
   getToDistrict: baseUrl + "read/districts?code=",
   sendChallengePrice: baseUrl + "write/onboard/vendor/challenge-price",
   getFormStates: baseUrl + "read/all/master/states",
   googleDistance: GoogleAPIUrl + "distancematrix/json?",

   addBayDetails: baseUrl + "write/loadingbay/addloadingBay",
   addGangDetails: baseUrl + "write/loadingbay/addvendor",
   getBayDetails: baseUrl + "read/bay/getLoadingbay?",
   getGangDetails: baseUrl + "read/bay/getvendor?",
   deleteBayDetails: baseUrl + "write/plants/deleteloadingBay",
   deleteGangDetails: baseUrl + "write/plants/deleteVendor",
   editBayDetails: baseUrl + "write/loadingbay/editloadingBay",
   editGangDetails: baseUrl + "write/loadingvendor/editloadingVendor",

   getRapidDieselPrice: baseUrl + 'read/diesel/price/today-hike/rapid-apis',
   approveMonthlyRates: baseUrl + 'write/rate/approve-monthly-dph',

   allMDGDetails: baseUrl + 'read/company/vendor/all-mdg-details?',

   addDemurrage: baseUrl + "write/add/demurrage",
   getDemurrage: baseUrl + "read/demurrages",
   updateDemurrage: baseUrl + "write/update/demurrage",

   getIndentingEditFields: baseUrl + "read/list/field?",
   postMoveStandardTime: baseUrl + "write/moves/standardTimeConfig",
   getMoveStandardTime: baseUrl + "read/moves/standardTimePlant?",
   updateMoveStandardTime: baseUrl + "write/moves/standardTimeConfigupdate",

   getApproverTypes: baseUrl + "read/approver/types",
   getAllChargesForApproval: accountBaseUrl + '/api/v2/trips/dropdown/charges?customer_id=',

   getApproverList: baseUrl + "read/approver/workflow?",
   getAllApproverList: baseUrl + "read/approver/workflows?",
   postApproverList: baseUrl + "write/approver/workflow",
   updateApproverList: baseUrl + "write/approver/workflow/update",
   postApproverToBranch: baseUrl + "write/approver/workflow/apply/branch",

   postWeighbridge: baseUrl + "write/weighment/weightmentConfig",
   readDepartmentUsers: baseUrl + "read/approval-matrix/department/users?",
   downloadContract: baseUrl + "read/download-opreational-area",

   getApproverLevels: baseUrl + "read/approval-matrix/departments",

   getOtherCharges: baseUrl + "read/othercharges",
   postOtherCharges: baseUrl + "write/add/othercharge",
   updateOtherCharges: baseUrl + "write/update/othercharge",
   deleteOtherCharges: baseUrl + "write/delete/othercharge",
   getOtherChargesDropdown: baseUrl + "read/dropdown/othercharges",

   addWBConversion: baseUrl + "write/weighment/addPlant",
   deleteWBConversion: baseUrl + "write/weighment/type/conversion",
   updateWBConversion: baseUrl + "write/weighment/editPlantdetails",
   getWBConversion: baseUrl + "read/unmanned/master?",

   getRFQTypes: baseUrl + "read/rfq/types",
   addCurrencyDetails: baseUrl + "write/add/currency",
   getCurrencyDetails: baseUrl + "read/currency/details?companyId=",

   getHubsDropdown: baseUrl + "read/hubslist?",
   addHubs: baseUrl + "write/vendorhub?",
   edithubs: baseUrl + "write/update/vendorhub?",
   deleteHub: baseUrl + "write/delete/vendorhub?",
   downloadHubs: baseUrl + "read/download/vendorhub?",
   uploadHubs: baseUrl + "write/upload/vendorhub?",

   vendorApprovalCount: baseUrl + "read/onboard/vendor/approvalcount?",
   downloadVendorLanes: baseUrl + "read/download/dphRates",

   getDiscountStructure: baseUrl + "read/dph/vendor/discountrate?",
   postDiscountStructure: baseUrl + "write/dph/vendor/discountrate?",
   updateDiscountStructure: baseUrl + "update/dph/vendor/discountrate?",
   deleteDiscountStructure: baseUrl + "delete/dph/vendor/discountrate?",

   getWarehouseList: baseUrl + "read/warehouse?",
   getWeighbridgeDetails: baseUrl + "read/weighment/weightmentConfig?",

   // Trip Eg
   downloadProviderUserDeatils: baseUrl + "read/provider/user/details?companyId=",
   uploadProviderUserDetails: baseUrl + "write/provider/user/details?",

   downloadProviderBranchDetails: baseUrl + "download/branch/details?companyId=",
   uploadProviderBranchDetails: baseUrl + "upload/branch/details?",

   downloadProviderDepartmentDetails: baseUrl + "download/departments?companyId=",
   uploadProviderDepartmentDetails: baseUrl + "upload/departments?",

   downloadProviderRolesDeatils: baseUrl + "download/roles?companyId=",

   uploadProviderRolesDetails: baseUrl + "upload/user/roles?",

   getMaterialConversion: baseUrl + "read/materialconversion/data",
   addMaterialConversion: baseUrl + "write/add/materialconversion",

   downloadSapRouteCode: contractingUrl + "read/sap/route/template?companyId=",
   uploadSapRouteCode: contractingUrl + "write/upload/sap/code/template",
   deleteSapRouteDeatils: contractingUrl + "write/delete/sap/route",

   addProductCategoryDetails: baseUrl + "write/add/product/category",
   addPackagingTypeDetails: baseUrl + "write/add/packaging/type",
   addPricingBasisDetails: baseUrl + "write/add/pricing/basis",
   addVehicleTypeDetails: baseUrl + "write/add/vehicle/type",
   addVehicleBodyTypeDetails: baseUrl + "write/add/vehicle/body",
   addUomDetails: baseUrl + "write/add/uom/name",
   downloadSapRouteCodeDetails: baseUrl + "download/sheet?companyId=",

   getShortagePilferageTolerance: baseUrl + "read/debit/master/tolerance?companyId=",
   postShortagePilferageTolerance: baseUrl + "write/debit/master/tolerance",
   deleteShortagePilferageTolerance: baseUrl + "delete/debit/master/tolerance",

   schedulerDetails: baseUrl + "read/fetch/routeCode",
   schedulerAddRecord: baseUrl + "write/route/code/scheduler",
   schedulerDeleteRecord: baseUrl + "delete/route/code/scheduler",
   downloadSchedulerDetails: baseUrl + "download/scheduler/sheet",
   uploadSchedulerDetails: baseUrl + "upload/scheduler/sheet",

   getOperationalAreas: baseUrl + "read/operational/zones",
   getServicesProvided: baseUrl + "read/services/offered",
   getAllServicesOffered: baseUrl + "read/all/servicesoffered",
   getWarehouseParticulars: baseUrl + "read/fetch/ware/house/loaction",
   postWarehouseParticulars: baseUrl + "write/ware/house/loaction",
   getWarehouseTemplate: baseUrl + "read/fetch/ware/house/template",
   postWarehouseTemplate: baseUrl + "write/ware/house/template",
   getStateListDetails: baseUrl + "read/fetch/statelist?company_Id=",
   getDPLbranchListDetails: baseUrl + "read/branch/name/id?companyId=",

   downloadSubplantTemplate: baseUrl + "download/subplants",
   uploadSubplantDetails: baseUrl + "upload/subplants/details",
   editSubplantDetails: baseUrl + "write/subplants/edit",
   deleteSubplantDetails: baseUrl + "delete/subplants",

   downloadLoadingBayTemplate: baseUrl + "read/download/loadingbayTemp",
   downloadLoadingBayDetails: baseUrl + "read/download/loadingbayDetails",
   uploadLoadingBayDetails: baseUrl + "write/upload/loadingbayDetails",

   downloadPlantDetails: baseUrl + "download/plants",

   hsdContractDetails: baseUrl + "fetch/allhsd",
   hsdLaneDetails: baseUrl + "read/fetchhsddetails",
   hsdEditContractDetails: baseUrl + "update/hsddetails",
   hsdSaveApprovalDetails: baseUrl + "write/add/hsdapproval",
   hsdZoneDetails: baseUrl + "read/hsd/previousdp",
   hsdConsolidatedView: baseUrl + "read/hsd/consolidatedview",

   downloadMaterialConversionTemplate: baseUrl + "download/materialconversion",
   uploadMaterialConversionDetails: baseUrl + "upload/materialconversion",
   editMaterialConversionDetails: baseUrl + "update/materialconversion",
   deleteMaterialConversionDetails: baseUrl + "delete/materialconversion",

   downloadLoadingVendorTemplate: baseUrl + "read/download/loadingVendorTemp",
   downloadLoadingVendorDetails: baseUrl + "read/download/loadingVendorDetails",
   uploadLoadingVendorDetails: baseUrl + "write/upload/loadingVendorDetails",

   downloadPlantTemplate: baseUrl + "download/plantsTemp",
   uploadPlantDetails: baseUrl + "upload/plants/details",

   deletePlantDepots: baseUrl + "delete/branch/details?companyId=",
   deleteSyncChain: trackingUrl + "master/sync-chain",

   getMasterContractConfig: baseUrl + "read/master/data",
   getPlantWiseContractData: baseUrl + "read/plant/wise/contract/data?companyId=",
   getCompanyMasterContractData: baseUrl + "read/company/master/data?companyId=",

   //Warehouse storage
   getWarehouseDetails: baseUrl + "read/warehouse/storageMaster",
   editWarehouseDetails: baseUrl + "write/warehouse/storageMaster",
   downloadWarehouseTemplate: baseUrl + "read/download/storageMasterTemp",
   downloadWarehouseData: baseUrl + "read/download/storageMasterrDetails",
   uploadWarehouseDetails: baseUrl + "write/upload/storageMasterDetails",

   getVendorMaterialDetails: baseUrl + "read/vendor/material/code?companyId=",
   addVendorMaterialDetails: baseUrl + "write/vendor/material/code?companyId=",
   updateVendorMaterialDetails: baseUrl + "update/vendor/material/code",
   deleteVendorMaterialDetails: baseUrl + "delete/vendor/material/code",
   downloadVendorMaterialTemplate: baseUrl + "download/vendor/material/code/temp",
   downloadVendorMaterialData: baseUrl + "download/vendor/material/code?companyId=",
   uploadVendorMaterialDetails: baseUrl + "upload/vendor/material/code?companyId=",
   getVendorWithSapCode: baseUrl + "read/vendor/sap/code?companyId=",

   operationTimeDetails: baseUrl + "read/plantOperation/getOperationSla",
   addOperationTime: baseUrl + "write/plantOperation/addOperationSla",
   editOperationTime: baseUrl + "write/plantOperation/UpdateOperationSla",
   deleteOperationTime: baseUrl + "write/plantOperation/deleteOperationSla",

   editVendorEmail: baseUrl + "update/onboard/vendor",
   retriggerVendorEmail: baseUrl + "write/retrigger/onboardingvendoremail",

   branchTransfer: baseUrl + "update/change/user/branch",
   deactivateBranchUser: baseUrl + "update/deactivate/user",

   downloadUserDetailsTemplate: baseUrl + "download/User/temp",
   uploadUserDetails: baseUrl + "upload/User/details",
   depotAndUserDetails: baseUrl + "read/plant/dept/User",

   downloanParticularsTemplate: baseUrl + "download/ware/house/particulars",
   downloadParticularsDetails: baseUrl + "download/ware/house/particulars?companyId=",
   uploadParticularsDetailsDetails: baseUrl + "upload/ware/house/particulars/temp?companyId=",
   applyFlowSettingToBranches: baseUrlIndenting + "indenting/flows/apply",

   // Truck Master
   getTruckDetails: baseUrl + "read/truck?companyId=",
   addTruckDetails: baseUrl + "write/add/truck",
   updateTruckDetails: baseUrl + "update/truck",
   deleteTruckDetails: baseUrl + "delete/truck",
   uploadTruckDetails: baseUrl + "upload/truck/details?companyId=",
   downloadTruckDetails: baseUrl + "download/truck/details?companyId=",
   downloadTruckTemp: baseUrl + "download/truck/details/temp?companyId=",
   getTransporterDetails: baseUrl + "read/transporterListMaster?",
   getVehicleSize: baseUrl + "read/vehicle/types?",
   getTruckDriverDetails: baseUrl + "fetch/dedicateddrivermaster?companyId=",
   getGpsVendorData: trackingUrl + "tracking/gps-vendors",

   // Sku Master
   getSkuDetails: baseUrl + "read/part?companyId=",
   addSkuDetails: baseUrl + "write/add/part",
   updateSkuDetails: baseUrl + "update/part",
   deleteSkuDetails: baseUrl + "delete/part",
   downloadSkuDetails: baseUrl + "download/part/details?companyId=",
   downloadSkuTemp: baseUrl + "download/part/details/temp?companyId=",
   uploadSkuDetails: baseUrl + "upload/part/details?companyId=",

   getAutomoveDetails: baseUrl + "read/plantAutomove/getAutomoveId?",
   addAutomoveDetails: baseUrl + "write/plantAutomove/UpdateAutomoveId",
   // Driver Master
   getDriverDetails: baseUrl + "fetch/dedicateddrivermaster?companyId=",
   addDriverDetails: baseUrl + "write/dedicateddrivermaster",
   updateDriverDetails: baseUrl + "update/dedicateddrivermaster",
   deleteDriverDetails: baseUrl + "delete/dedicateddrivermaster?companyId=",
   downloadDriverDetails: baseUrl + "download/dedicateddrivermaster",
   uploadDriverDetails: baseUrl + "upload/dedicateddrivermaster?companyId=",
   downloadDriverTemp: baseUrl + "download/dedicateddrivermaster?companyId=",

   getMaterialItemDetails: baseUrl + "read/materialDetails?companyId=",
   getModeOfTransport: baseUrl + "read/mode/of/transporter",

   addLoadabilityDetails: baseUrl + "write/add/loadability ",
   getConsolidatedViewRFQDetails: baseUrl + "fetch/consolidatedviewrfqs",
   
   syncChainWithVIMS: trackingUrl + "master/sync-chain",

   getRILRouteCode: baseUrl + "read/fetch/all/route",
   addRILRouteCode: baseUrl + "write/add/route",
   editRILRouteCode: baseUrl + "write/update/route",
   deleteRILRouteCode: baseUrl + "delete/route",
   downloadRILRouteCode: baseUrl + "download/rail/coastal/route?companyId=",
   uploadRILRouteCode: baseUrl + "write/upload/rail/coastal/route",
   downloadRILROuteCodeDetails: baseUrl + "download/rail/coastal/route/sheet?companyId=",

   getMasterRouteDetails: baseUrl + "read/fetch/all/master/code",
   addMasterRouteDetails: baseUrl + "write/add/master/code",
   updateMasterRouteDetails: baseUrl + "write/update/master/code",
   deleteMasterRouteDetails: baseUrl + "delete/master/code",
   downloadMasterRouteTemplate: baseUrl + "download/master/code/template?companyId=",
   downloadMasterRouteTemplateDetails: baseUrl + "download/master/code/template/details?companyId=",
   uploadMasterRouteDetails: baseUrl + "write/upload/master/code/template",

   getLOBList: baseUrl + "read/loblist",
   getDisposalMSP: baseUrl + "read/fetch/all/disposal/msp?companyId=",
   addDisposalMSP: baseUrl + "write/add/disposal/msp",
   updateDisposalMSP: baseUrl + "write/update/disposal/msp",
   deleteDisposalMSP: baseUrl + "delete/disposal/msp",
   downloadDisposalMSPTemplate: baseUrl + "download/disposal/msp/template?companyId=",
   uploadDisposalMSP: baseUrl + "write/upload/disposal/msp/template",
   downloadDisposalMSPDetails: baseUrl + "download/disposal/msp/template/details?companyId=",
   getDisposalMSPDropdownList: baseUrl + "read/fetch/all/disposal/product/master?companyId=",

   getMSPContractBasic: baseUrl + "read/rfq/disposal/contracts/basic?companyId=",
   addMSPContractBasic: baseUrl + "write/rfq/disposal/contracts/basic",
   upadteMSPContractBasic: baseUrl + "write/rfq/disposal/contracts/basic",
   applyMSPSettingsToBranches: baseUrl + "write/rfq/disposal/contracts/apply/branch",
   getAutoDecrementDataUrl: baseUrl + "read/fetch/all/autodecrement/range",
   addAutoDecrementDataUrl: baseUrl + "write/add/autodecrement/range",

   tatFormVendorDetails: baseUrl + "read/transporter/tatapproval",
   tatFormUpdateDetails: baseUrl + "write/tatapproval",

   getConsigneeCityList: baseUrl + "fetch/consigneecities?companyId=",

   //SSO
   getSSO: ssoBaseUrl + '/sso',
   ssoUserValidation: ssoBaseUrl + '/sso/validate/',
   ssoDeleteToken: ssoBaseUrl + '/sso/logout/',
   ssoLoginResponse: ssoBaseUrl + '/sso/get-session',
   ssoConfigResponse: ssoBaseUrl + '/sso/get-sso-config',

   getContractTenureTypes: baseUrl + "read/fetch/contract/tenure/types",
   getContractTenureList: baseUrl + "read/fetch/all/contract/tenure",
   addContractTenureList: baseUrl + "write/add/contract/tenure",
   updateConTractTenureList: baseUrl + "write/update/contract/tenure",

   //lr management tab
   lspOptions: baseUrl + "read/fetchAllLrDetails?companyId=",
   selectedOptions: baseUrl + "read/fetchLSPs?companyId=",
   downloads: baseUrl + "download/fetchUploadLRTemplate?companyId=",
   updateThreshold: baseUrl + "edit/updateThreshold",
   uploadFile: baseUrl + "upload/uploadLR?lspName=companyId=boundType=1",
   fetchLRuploadsummary: baseUrl + "read/fetchLrUploadSummary?",
   deleteLRUploadDetails: baseUrl + "delete/deleteLRFile",
   downloadLRUploadDetail: baseUrl + "download/fetchUploadedLRFile?fileName=",
   sendLRtoMoglix: baseUrl + "delete/sendToMoglix?companyId=",
   uploadLRdetail: baseUrl + "upload/uploadLR?lspName=",
   downloadLspName: baseUrl + "download/lspData?companyId=",
   setLrRangeURL: baseUrl + "add/setLrRange?seekerId=",
   setLrRangeForProviderURL: baseUrl + "add/digitalLR/details",
   getBranchForLr: baseUrl + 'read/plants?companyId=',
   getAllSeekerForLR : indentingUrl + 'read/fetch/all/seekers',
   downloadIndividualLRURL: baseUrl + 'download/individualLRdetails?company_id=',
   getProviderLRdetailsURL: baseUrl + 'fetch/digitalLR/details?provider_id=',
   deleteLRForProviderURL: baseUrl + "delete/digitalLR/details",
   editLRDataForProviderURL: baseUrl + "update/digitalLR/details",

   getCityAndStateFromPinURL: 'https://api.postalpincode.in/pincode/',
   deleteUploadedDocumentInVendorURL: baseUrl + 'write/delete/onboard/documents?vendorId=',
   fetchLRRange: baseUrl + "fetch/lr/range/company?",
   deleteLRRange: baseUrl + "delete/lr/range",
   saveLrRange:baseUrl + "add/lr/range",
   updateLrRange:baseUrl + "update/lr/range",
   getLspLRRange:baseUrl + "fetch/lr/range/lsp?",
   sendLrRangeToMoglix:baseUrl + "process/lr/sendToMoglix?",
   sendLrRangeToSimbus:baseUrl + "process/lr/sendToSimbhus?",

   fetchAwbLRRange: baseUrl + "fetch/awb/range/company?",
   deleteAwbLRRange: baseUrl + "delete/awb/range",
   saveAwbLrRange:baseUrl + "add/awb/range",
   updateAwbLrRange:baseUrl + "update/awb/range",
   getLspAwbLRRange:baseUrl + "fetch/awb/range/lsp?",
   sendAwbLrRangeToSimbus:baseUrl + "process/awb/sendToSimbhus?",
   awbSelectedOptions: baseUrl + "read/awb/fetchLSPs?companyId=",
   downloadIndividualAwbURL: baseUrl + 'download/awb/individualLRdetails?company_id=',
   downloadAwbLspName: baseUrl + "download/awb/lspData?companyId=",
   awblspOptions: baseUrl + "read/fetchAllAwbDetails?companyId=",
   awbUpdateThreshold: baseUrl + "edit/awb/updateThreshold",
   //Master Drop Down API's
   getDropDownNameURL: baseUrl + 'fetch/ropDownMasterTypes/details?company_id=',
   getInsideTabDropDown: baseUrl + 'fetch/dropDownMaster/details?company_id=',
   updateMasterDropDownDataUrl: baseUrl + 'update/dropDownMaster/details',
   addMasterDropDownDataUrl: baseUrl + 'add/dropDownMaster/details?dropdown_type_id=',
   deleteMasterDropDownDataUrl: baseUrl + 'update/dropDownMaster/details',

   //SAP vendor
   getAdminNamesURL: baseUrl + 'read/roles/admin',
   getCurrencyOptionsURL: baseUrl + 'read/currencies',
   getCountryOptionsURL: baseUrl + 'read/fetch/country',
   saveSAPVendorFormURL: baseUrl + 'write/onboard/vendor/details/SAP',

   //Emami Parent Child
   getLocationMgmtListing: baseUrl + 'read/fetch/all/child/location?',
   getParentChildViewListing: baseUrl + 'fetch/all/parent/child/view?',
   downloadTemplateInUploadLocationURL: baseUrl + 'read/download/child/location?type=',
   uploadFileInUploadLocationURL: baseUrl + 'write/upload/child/location',
   deleteFileInUploadLocationURL: baseUrl + 'delete/child/location',
   getAllClusterMappingDataURL: baseUrl + 'fetch/all/cluster/mapping',
   getLocChildOptionURL: baseUrl + 'read/fetch/child/location/code',
   addClusterMappingDataURL: baseUrl + 'add/cluster/mapping',
   deleteClusterMappingData: baseUrl + 'delete/cluster/mapping?clusterId=',
   editClusterMappingData: baseUrl + 'update/cluster/mapping',
   deleteParentChildLocationDataURL: baseUrl + 'delete/loc/child?childId=',
   editParentChildLocaitonDataURL: baseUrl + 'update/loc/child',
   deleteAllClusterDataURL: baseUrl + 'delete/all/child/cluster',
   getIndentConfigURL: baseUrl + 'fetch/indentsettings',
   addIndentConfigURL: baseUrl + 'write/indentsettings',
   saveIndentConfigURL: baseUrl + 'update/indentsettings',
   downloadVendorDetailsURL: baseUrl + 'download/vendor/details',

   dailyDPHUrl: baseUrl + 'fetch/dailyzonerates',

   weighbridgeMasterListingUrl: baseUrl + 'get/weihBridgemaster?',
   createWeighbridgeMasterUrl: baseUrl + 'write/weighbridge',
   updateWeighbridgeMasterUrl: baseUrl + 'update/weighBridge',
   deleteWeighbridgeMasterUrl: baseUrl + 'delete/weighbridge',

   getIndentingDashboardListUrl: baseUrl + 'read/indent/dashboard',

   parkingLotListingUrl: baseUrl + 'fetch/parkingLot?',
   createParkingLotUrl: baseUrl + 'write/parkingLot',
   updateParkingLotUrl: baseUrl + 'update/parkingLot',
   deleteParkingLotUrl: baseUrl + 'delete/parkingLot?',

   getAllYardsUrl: baseUrl + 'fetch/yards',

    //Volumetric Master
   getVolumetricMasterDetailsURL: baseUrl + 'fetch/volumetric/master',
   addVolumetricMasterDetailsURL: baseUrl + 'add/volumetric/master',
   updateVolumetricMasterDetailsURL: baseUrl + 'update/volumetric/master',
   deleteVolumetricMasterDetailsURL: baseUrl + 'delete/volumetric/master',
   getPackUOMDropdown: baseUrl + 'fetch/measuringUnit',
   downloadTemplateVolumetric: baseUrl + 'download/template/volumetric/master?',
   downloadDataVolumetric: baseUrl + 'download/data/volumetric/master?',
   uploadDataVolumetric: baseUrl + 'upload/volumetric/master?',

   //SAPVendor groups
   getGroupNamesDropdownURL: baseUrl + 'read/groupNames',
   addGroupName: baseUrl + 'write/groupNames',
   saveGroupNamesURL: baseUrl + 'save/groupNames',

   
   getReportsDashboardsListURL: baseUrl + 'get/reports/dashboards',
   
   //MFS MASTER
   getMfsMasterDetailsUrl: baseUrl + 'fetch/mfsMaster?',
   createMfsMasterDetailsUrl: baseUrl + 'write/mfsMaster',
   updateMfsMasterDetailsUrl: baseUrl + 'update/mfsMaster',
   deleteMfsMasterDetailsUrl: baseUrl + 'delete/mfsMaster?',
   getServiceModesUrl: baseUrl + 'read/servicemodes',

   getMfsMasterUomDetailsUrl: baseUrl + 'fetch/measuringUnit',
   downloadTemplateMFS: baseUrl + 'download/Mfs/temp',
   downloadDataMFS: baseUrl + 'download/Mfs/details',
   uploadDataMFS: baseUrl + 'upload/Mfs/details',

   getCompanyWiseZoneUrl: baseUrl + 'fetch/zones/list',
  // vendorDetails
  getVendorDetailsForSapUrl: baseUrl + "read/onboard/vendor/details",
  getPaymentTermsForSapUrl: baseUrl + "read/onboard/vendor/paymentTerms",
  getCurrencyListForSapUrl: baseUrl + "read/currencies",
  postSapDeatailsUrl: baseUrl + "write/onboard/vendor/details/SAP",
  postSapSendDetailsForCodeUrl : baseUrl + "write/onboard/vendor/code/SAP",

//Exworks Form Details
   postExWorksDeatailsUrl: baseUrl + "write/update/vendor/exworks",

  uploadRateChartUrl: baseUrl + "upload/productRateChart",
  downloadRateChartUrl: baseUrl + "download/productRateChart",
  getProductRateChartUrl: baseUrl + "read/productRateChart",
  addProductRateChartUrl: baseUrl + "write/productRateChart",
  updateProductRateChartUrl: baseUrl + "update/productRateChart",
  deleteProductRateChartUrl: baseUrl + "delete/productRateChart",

  //region-vehicle approver matrix
  getRegionMaster: baseUrl + "read/region/master",

  getIncoTermsoptionsUrl: baseUrl + "read/inco/term/code",

  getSlabRangeUrl: baseUrl + "read/recheck/approver/range?",
  addSlabRangeUrl: baseUrl + "write/recheck/approver/range",
  deleteSlabRangeUrl: baseUrl + "write/recheck/approver/delete",

  addPlantCategoryUrl: baseUrl + "get/plantCategory",
  fetchDepotName:  baseUrl + "read/depot/name/id",

  getAuditLogsUrl:  baseUrl + "read/fetch/audit/logs",
  getAllBranchListUrl:   baseUrl + "read/all/branch",
  downloadAuditLogsUrl:  baseUrl + "download/audit/logs",

  getReasonsUrl: baseUrlIndenting + "indenting/reasons",
  addReasonsUrl: baseUrlIndenting + "indenting/addReason",
  updateReasonsUrl: baseUrlIndenting + "indenting/updateReason",
  deleteReasonUrl: baseUrlIndenting + "indenting/deleteReason",
  //Inventory Master
  getInventoryMasterUrl: baseUrl + "fetch/invetory/master",
  addInventoryMasterUrl: baseUrl + "add/inventory/master",
  updateInventoryMasterUrl: baseUrl + "update/inventory/master",
  deleteInventoryMasterUrl: baseUrl + "delete/inventory/master",
 
  getSapVendorMaterial: baseUrl + "read/vendor/material/code",

  //Banner Settings
  getBannerListUrl:ssoBaseUrl+"/broadcast/list-messages",
  deleteBannerMessageUrl:ssoBaseUrl+"/broadcast/message/",
  createBannerMessageUrl:ssoBaseUrl+"/broadcast/message/create",
  updateBannerMessageUrl:ssoBaseUrl+"/broadcast/message/",
  getBroadCastMessageUrl:ssoBaseUrl+"/broadcast/get-messages",
  
  // Sales_district_mapping  Master
  getSalesdistrictMappingMasterUrl: baseUrl + "fetch/SalesdistrictMapping/master",
  addSalesdistrictMappingMasterUrl: baseUrl + "add/SalesdistrictMapping/master",
  updateSalesdistrictMappingMasterUrl: baseUrl + "update/SalesdistrictMapping/master",
  deleteSalesdistrictMappingMasterUrl: baseUrl + "delete/SalesdistrictMapping/master",

  getBillSequenceTestUrl: accountBaseUrl + '/api/v2/bills/generate-bill-no/',
  getBillSequenceListingUrl: accountBaseUrl + '/api/v2/recon-masters/bill-sequence/listing',
  getBillSequenceDropdownUrl: accountBaseUrl + '/api/v2/recon-masters/bill-sequence/dropdowns',
  getBillSequenceUrl: accountBaseUrl + '/api/v2/recon-masters/bill-sequence/',
  postBillSequenceUrl: accountBaseUrl + '/api/v2/recon-masters/bill-sequence/create',
  //Damage Rate for Material Details
  getDamageRateUrl: baseUrl+"read/fetch/damageRate/data",
  downloadDamageRateTemplateUrl:baseUrl + "download/damageRate/template",
  downloadDamageRateDataUrl:baseUrl + "write/download/damageRate/data",
  uploadDamageRateUrl:baseUrl + "write/upload/damageRate/data",

  //Debit Note Config 
  getDebitNoteDetailsUrl: baseUrl + "fetch/debitnote/congig",
  saveDebitNoteDetailsUrl: baseUrl + "add/debitnote/config",

  postHSDdetailsURL: baseUrl + "update/hsd/lanes",
  downloadExcelHSDUrl: baseUrl + "download/hsd",
  uploadHsdLanesURL: baseUrl + "upload/hsd/lanes",

  fetchAllMoves: baseUrl + "fetch/moves/details",
  fetchTaxCodesUrl: baseUrl+"fetch/tax/codes",
  //DphAmendment
  dphAmendmentMonthlyRates: baseUrl + 'write/rate/approveRequestedMonthDPH',
  getDphAmendmentMonthlyRates: baseUrl + 'read/rate/getRequesedMonthDPH',
  // lorealmasterchanges
  lorealdownloadUserDetailsTemplate: baseUrl + "download/userTemp",
  lorealuploadUserDetails: baseUrl + "upload/userDetails",

  getCustomerLaneVendorMapping: baseUrl + "fetch/customerLaneVendorMapping",
  postCustomerLaneVendorMapping: baseUrl + "write/customerLaneVendorMapping",
  putCustomerLaneVendorMapping: baseUrl + "edit/customerLaneVendorMapping",
  deleteCustomerLaneVendorMapping: baseUrl + "delete/customerLaneVendorMapping",
  
  //Delivery-Charges: extra delivery charges CRUD API'S
  getExtraDeliveryChargeUrl : baseUrl + "fetch/fetchDelivaryCharge",
  deleteExtraDeliveryChargeUrl : baseUrl +"delete/deleteDelivaryCharge",

   //Delivery-Charges: Additional Cost charges CRUD API'S
   getAdditionalCostChargeUrl : baseUrl + "fetch/fetchDelivaryChargeAddCst",
   deleteAdditionalCostChargeUrl : baseUrl +"delete/deleteDelivaryChargeAddCst",

   getCompanyUserInfoList: `${baseUrl}read/company/users`,

   saveEmudhraDetailsUrl : baseUrl + "add/Emudhra",
};
export default apis;
