import React, { Component } from "react";

class Button extends Component {
  render() {
    const { Name, className, onClick, disabled ,type} = this.props;
    return (
      <button className={`${className}`} onClick={onClick} disabled={disabled} type={type || "button"}>
        {Name}
      </button>
    );
  }
}
export default Button;
