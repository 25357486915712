import React, { Component } from "react";
import InputText from "../../../common/components/input-text/input-text";
import "./plants-basic-details.less";
import Select from "react-select";
import Button from "../../button-classic/button-classic";
import Delete from "../../../../assets/images/delete.svg";
import {
  validationCheck,
  legthValidation,
  toPascalCase,
} from "../../../../utils/validate";
import {
  netWorkTypes,
  movementTypes,
  weeklyOff,
  TAT_UNITS,
  CLUSTERS,
  AWL_COMPANY_IDS,
  BEAM_COMPANY_IDS,
  EMAMI_COMPANY_IDS,
  CARGILL_COMPANY_IDS,
  getEmail,
  deliveryConsigneeType,
} from "../../../../utils/common";
import MSGPopUp from "../../../common/components/msg-popup/msg-popup";
import Refresh from "../../../../assets/images/refresh-button.svg";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
// import Geocode from "react-geocode";
import Moment from "moment";
import DeleteDepartment from "../../../components/delete-confirmation/delete-confirmation";
import { connect } from "react-redux";
import ACTIONS from '../../../common/action_constant';
import home from "../../home.constants";
import Add from "../../../../assets/images/add.svg";
import { attachGoogleKeyScript, geocodeAddress } from "../../../../utils";
import { SingleDatePicker } from 'react-dates';
// Geocode.setApiKey("AIzaSyBZS3IcyJ42uPzK8z5ZvbK5Fd99j0NqMRE");

class PlantsBasicDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
      yardsOptions:[],
			details: {
				branch_name: "",
				branch_code: "",
				sap_branch_code: "",
				city: "",
				pan: "",
				gst: "",
        sap_mm_point:"",
        railway_vendor_code:"",
				region: "",
				address: "",
				pincode: "",
				chain_type: "",
				movement_type: "",
				planning_point: "",
				primary_consignee_name: "",
				primary_consignee_contact: "",
				primary_consignee_email: "",
				weekly_off: [],
        delivery_consignee_type:[],
				consignee_email: "",
				primary_consignee_code: "",
				primary_consignee_gst: "",
				business_type:"",
				secondary_consignee_code: "",
				secondary_consignee_gst: "",
				brand:"",
				is_auto_move_enabled: false,
        is_scd_enabled: false,
        is_depot_scd:false,
				cluster: "",
				tolerance: 0,
				contacts: [{
					name: "",
					role: "",
					mobile: "",
					email: ""
				}],
				latitude: "",
				longitude: "",
				start_time: "",
				end_time: "",
				terms_and_conditions_doc:"",
				auto_rfq_bid_duration:"",
				geofence_radius_sim: home.geofence_radius_sim,
			  geofence_radius_gps: home.geofence_radius_gps,
				zone: "",
				zone_id: "",
				// cluster: "",
				cluster_id: "",
        yards:[],
        branch_yards: [],
        dedicated_lanes: [
          {
            from:"",
            to:"",
            from_name:"",
            to_name:"",
            count: null
          }
        ],
        is_recon_enabled: false,
				recon_start_date: null
			},
			iserror: "",
			errormessage: "",
			isFormUpdated: false,
      approval_types: [
        {
          approval_type: 1,
          from: null,
          to: null
        },
        {
          approval_type: 2,
          from: null,
          to: null
        },
        {
          approval_type: 3,
          from: null,
          to: 0
        }
      ]
		};
	}

  getStateListData = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.VENDORS.GET_STATE_LIST_DETAILS,
      data: 'default',
      onSuccess: (data) => {
        if(data && data.list && data.list.length > 0){
          this.setState({ stateList: data.list || [] })
        } 
      }
    })
  }

	onChangeHandler = (value, id, isType) => {
		let details = Object.assign({}, this.state.details);
		if(id === 'tolerance') {
			if(value > 100) {
				return;
			} else {
				value = value && Number(value);
			}
		}

    if(id === 'pan' || id === 'gst' || id === 'primary_consignee_gst' || id === 'secondary_consignee_gst' || id === 'pincode' || id === 'sap_mm_point'){
			details[id] = value.replace(/[&\/\\#,+()$~%.@!^&()-/'":*?<>{}]/g,'');
		}
    if (id === 'railway_vendor_code') {
      value = value.replace(/\D/g, ''); // Remove non-numeric characters
      value = value.slice(0, 10); // Limit to 10 digits
      details[id] = value;
  } 
   else{
      details[id] = validationCheck(value, details[id], isType);
    }
		const errMsg = legthValidation(id, value);
		if (JSON.stringify(details) !== JSON.stringify(this.props.plantsDepots.currentDepot)) {
			this.setState({ isFormUpdated: true });
		} else {
			this.setState({ isFormUpdated: false });
		}
		this.setState({
			details, errormessage: errMsg
		}, () => {
			// this.props.isFormEdited(this.state.isFormUpdated);
		});
	};

	onBlurChange = (e) => {
		const { value, id } = e.target;
		let errMsg = legthValidation(e.target.id, e.target.value);
		if (errMsg && e.target.value) {
			this.setState({
				iserror: e.target.id,
				errormessage: errMsg
			})
		} else if (e.target.id === 'address') {
			this.setState({ iserror: '' }, () => {
				this.getLatLong(value.replace('#', ''));
			});
		} else if (this.state.iserror) {
			this.setState({ iserror: '' });
		}
	}

  getAllYards = () => {
    const {dispatch} = this.props;

    dispatch({
      type:ACTIONS.PLANTS.GET_ALL_YARDS,
      params:`?companyId=${this.props.company_id}`,
      onSuccess:((data) => {
        let temp = [];
        data.list && data.list.length > 0 && data.list.map((d) => {
          temp.push({
            value: d.branch_id,
            label: d.branch_name
          })
        })
        this.setState({yardsOptions: temp})
      })
    })
  }

	componentDidMount() {
		this.init(this.props);
		this.getState()
    this.props.getAllBranch(this.props.company_id);
    this.getAllYards()
    this.getInsideTabDropDownName()
    // this.getStateListData()
    this.handleSetPreviousValue()
    attachGoogleKeyScript('AIzaSyBZS3IcyJ42uPzK8z5ZvbK5Fd99j0NqMRE')

	}

  handleSetPreviousValue =()=>{
    const {plantsDepots,} = this.props
    const trackDetails = JSON.parse(JSON.stringify((plantsDepots.trackDetails || {})))
    let updateTrackDetails =  {
      ...trackDetails,
      update_flag:true,
      master_type:1,
      user_email:getEmail(),
      chain_type: (trackDetails.chain_type||{}).value,
      movement_type: (trackDetails.movement_type||{}).value,
      weekly_off:(trackDetails.weekly_off||[]).map((ele)=>((ele||{}).value)),
      delivery_consignee_type:trackDetails.delivery_consignee_type,
      yards:(trackDetails.yards||[]).map((ele)=>{
        const newEle = {
          yard_id:ele.value,
          yard_name:ele.label,
        }
        return newEle
      })
    }

    if(!!updateTrackDetails.previous_data){
      delete updateTrackDetails.previous_data
    }
   this.setState((prevState)=>{
    const updatedState  = { ...prevState}
    updatedState.trackDetails = updateTrackDetails
    return  updatedState
   })
  }

	componentWillReceiveProps(newProps) {
		// if (!this.state.isFormUpdated) {
		this.init(newProps);
		// }
	}
	init = props => {
		if (
			props.plantsDepots.currentDepot &&
			props.plantsDepots.currentDepot.branch_type
		) {
			let details = props.plantsDepots.currentDepot;
			details.branch = {
				value: props.plantsDepots.currentDepot.branch_type || "",
				label: props.plantsDepots.currentDepot.branch_type_name || ""
			};
			// details.zone = {
			// 	value: props.plantsDepots.currentDepot.zone_id || "",
			// 	label: props.plantsDepots.currentDepot.zone_name || ""
			// };

      details.zone = props.plantsDepots.currentDepot.zone_name || ""

			if (details.chain_type && typeof (details.chain_type) !== 'object') {
				details.chain_type = netWorkTypes.filter(address => address.value === details.chain_type)[0];
			}
			if (details.movement_type && typeof (details.movement_type) !== 'object') {
				details.movement_type = movementTypes.filter(movement => movement.value === details.movement_type)[0];
			}
			// if(details.cluster && typeof(details.cluster) !== 'object') {
			// 	details.cluster = CLUSTERS.find(el => el.label === details.cluster);
			// } 
			if (details.weekly_off && details.weekly_off.length > 0) {
				let weekOff = [];
				weeklyOff.map(week => {
					if (details.weekly_off.indexOf(week.value) > -1) {
						weekOff.push(week);
					}
				})
				if (weekOff.length > 0) {
					details.weekly_off = weekOff
				}
			}
      if (details.delivery_consignee_type && details.delivery_consignee_type.length > 0) {
				let delivery_consigneetype = [];
				delivery_consigneetype.forEach(type => {
					if (details.delivery_consigneetype.indexOf(type.label) > -1) {
						delivery_consigneetype.push(type);
					}
				})
				if (delivery_consigneetype.length > 0) {
					details.delivery_consignee_type = delivery_consigneetype
				}
			}

			details.city = props.plantsDepots.currentDepot.city;
			if(props.termAndConditions && props.termAndConditions!=details.terms_and_conditions_doc){
				details.terms_and_conditions_doc = props.termAndConditions;
			} 
			this.setState({
				details
			});
		}

    if(props.allPlantBranches){
      const tempBranches = []
      props.allPlantBranches.map((branch) => {
        tempBranches.push({
          label:branch.branch_name,
          value:branch.branch_id,
          branch_code: branch.branch_code
        })
      })
      this.setState({allBranches : tempBranches})
    }

    if (props.plantsDepots.currentDepot && props.plantsDepots.currentDepot.approval_types && props.plantsDepots.currentDepot.approval_types.length){
      this.setState({
        approval_types: props.plantsDepots.currentDepot.approval_types
      })
    }

    if (props.plantsDepots.currentDepot &&
      props.plantsDepots.currentDepot.branch_type) {

      let details = props.plantsDepots.currentDepot;
      if (details.yards && details.yards.length > 0 && details.yards.find(f => f.yard_id)) {
        const tempYards = [];
        details.yards.map((y) => {
          tempYards.push({
            label: y.yard_name,
            value: y.yard_id
          })
        });
        if (tempYards.length > 0) {
          details.yards = tempYards
        }
      }
      this.setState(details)
    }

    if (props.plantsDepots.currentDepot && props.plantsDepots.currentDepot.branch_type && [46,47].includes(props.plantsDepots.currentDepot.branch_type)) {
      let details = props.plantsDepots.currentDepot;
      if (details.branch_yards && details.branch_yards.length > 0 && details.branch_yards.find(f => f.branch_yard_id)) {
        const tempBranchYards = [];
        details.branch_yards.map((y) => {
          tempBranchYards.push({
            label: y.branch_yard_name,
            value: y.branch_yard_id
          })
        });
        if (tempBranchYards.length > 0) {
          details.branch_yards = tempBranchYards
        }
      }
      this.setState(details)
    }

	};

  componentWillUnmount(){
    const {dispatch} = this.props;
    dispatch({ type: ACTIONS.PLANTS.SET_TRACK_DETAILS, data:{} })
    // dispatch({ type : ACTIONS.PLANTS.STORE_TERM_CONDITION,data:'' })
  }

	handleChange = (id, selectedOption) => {
		let { isFormUpdated, details } = this.state;
		isFormUpdated = !(details[id] === selectedOption);
		if(id === 'region'){
      details['cluster'] = selectedOption.cluster;
      details['cluster_id'] = selectedOption.cluster_id;
      details['zone_id'] = selectedOption.zone_id;
      details['zone'] = selectedOption.zone;
      details[id] = selectedOption.name;
		} 
    else if (id === 'business_type')
    {
      details[id] = selectedOption.dropdown_name;
    }
    else {
      details[id] = selectedOption;
    }
		this.setState({ details });
	};

	validation = () => {
		const details = Object.assign({}, this.state.details)
		let error = "";
		Object.keys(details).forEach((detail) => {
			if (detail != "is_admin" && 
			(
			// ["sap_branch_code", "region", "planning_point", "branch_code", "chain_type", "movement_type", "start_time", "end_time", "pan", "gst", "pincode", "primary_consignee_name", "primary_consignee_contact", "movement_type", "latitude", "longitude", "zone_id", "zone_name", "primary_consignee_email", "zip_code", "consignee_email", "primary_consignee_code", "primary_consignee_gst", "secondary_consignee_code", "secondary_consignee_gst", "actions"].indexOf(detail) === -1) &&
			 AWL_COMPANY_IDS.includes(details.company_id) ? 
       (['branch_name', 'city', 'branch_code', 'branch', 'address', 'zone','cluster'].indexOf(detail) > -1) :
          (CARGILL_COMPANY_IDS.includes(this.props.company_id)) ? (['branch_name', 'city', 'branch_code', 'branch', 'address', 'zone','business_type'].indexOf(detail) > -1) :
        (['branch_name', 'city', 'branch_code', 'branch', 'address', 'zone'].indexOf(detail) > -1)) &&
			 typeof (details[detail]) !== 'object' && details[detail] == "") {
				error = "Please Enter All The Details And Then Submit"
			}
		})
		if (error != "") {
			this.setState({
				error
			})
			return false;
		}
		if (!details.zone || !details.branch) {
			this.setState({
				error: "Please Enter All The Details And Then Submit"
			})
			return false;
		}
		this.setState({
			error: ""
		})
		return true;
	}
	submitBranchDetails = () => {
    const {dispatch} = this.props;
    let {trackDetails} = this.state;
    const approvalData = this.state.approval_types;
    approvalData.map(app=>{
      if(!(app.from || app.from === 0)){
        app.from = 0
      }
      if (!(app.to || app.to === 0)) {
        app.to = 0
      }
    })
		if (this.validation()) {
			let stateData = Object.assign({}, this.state.details);
			console.log('stateData:', stateData)
			let branch_type = "",
				branch_type_name = "",
				zone_id = "",
				zone_name = "";

			if (stateData.branch && Object.keys(stateData.branch).length > 0) {
				branch_type = stateData.branch.value;
				branch_type_name = stateData.branch.label;
			}
			if (stateData.zone && Object.keys(stateData.zone).length > 0) {
				zone_id = stateData.zone.value;
				zone_name = stateData.zone.label;
			}
     
      const NewYards = []
      if (stateData.yards && stateData.yards.length > 0) {
        stateData.yards.map((d) => {
          NewYards.push({
            yard_id: d.value,
            yard_name: d.label
          })
        })
      }

      const BranchYards = []
      if (stateData.branch_yards && stateData.branch_yards.length > 0) {
        stateData.branch_yards.map((d) => {
          BranchYards.push({
            branch_yard_id: d.value,
            branch_yard_name: d.label
          })
        })
      }
			let finalData = {
				company_id: this.props.company_id,
				company_type: this.props.company_type,
				branch_name: stateData.branch_name,
				branch_code: stateData.branch_code,
				sap_branch_code: stateData.sap_branch_code,
				branch_type,
				branch_type_name,
				region: stateData.region,
				address: stateData.address,
				pincode: stateData.pincode,
				planning_point: stateData.planning_point,
				is_auto_move_enabled: stateData.is_auto_move_enabled,
        is_scd_enabled: stateData.is_scd_enabled,
        is_depot_scd:stateData.is_depot_scd,
				zone_name: stateData.zone,
				zone_id: stateData.zone_id,
				city: stateData.city,
				pan: stateData.pan,
				gst: stateData.gst,
				primary_consignee_code: stateData.primary_consignee_code,
				primary_consignee_gst: stateData.primary_consignee_gst,
				business_type: stateData.business_type,
				secondary_consignee_code: stateData.secondary_consignee_code,
				secondary_consignee_gst: stateData.secondary_consignee_gst,
				brand: stateData.brand,
				consignee_email: stateData.consignee_email,
				chain_type: stateData.chain_type && Object.keys(stateData.chain_type).length > 0 ? stateData.chain_type.value : 0,
				movement_type: stateData.movement_type && Object.keys(stateData.movement_type).length > 0 ? stateData.movement_type.value : 0,
				primary_consignee_name: stateData.primary_consignee_name,
				primary_consignee_contact: stateData.primary_consignee_contact,
				primary_consignee_email: stateData.primary_consignee_email,
				weekly_off: stateData.weekly_off ? stateData.weekly_off.map(week => { return (week||{}).value }) : stateData.weekly_off,
        delivery_consignee_type: stateData.delivery_consignee_type,
				primary_consignee: stateData.primary_consignee,
				contacts: stateData.contacts,
				latitude: stateData.latitude,
				longitude: stateData.longitude,
				start_time: stateData.start_time,
				end_time: stateData.end_time,
				cluster: stateData.cluster,
				cluster_id: stateData.cluster_id,
				terms_and_conditions_doc : stateData.terms_and_conditions_doc,
				auto_rfq_bid_duration : stateData.auto_rfq_bid_duration,
				tolerance: stateData.tolerance || 0,
        ...(AWL_COMPANY_IDS.includes(this.props.company_id) ? {sap_mm_point : stateData.sap_mm_point} : {}) ,
        ...(AWL_COMPANY_IDS.includes(this.props.company_id) ? {railway_vendor_code : stateData.railway_vendor_code} : {}) ,
        ...(EMAMI_COMPANY_IDS.includes(this.props.company_id) ? {is_dedicated_vehicle : stateData.is_dedicated_vehicle} : {}),
        ...(EMAMI_COMPANY_IDS.includes(this.props.company_id) ? {dedicated_lanes : stateData.dedicated_lanes} : {}) ,
        ...(AWL_COMPANY_IDS.includes(this.props.company_id) && stateData.has_recheck_approval ? {
          has_recheck_approval: true,
          approval_types: [...approvalData],
        is_recon_enabled: stateData.is_recon_enabled,
				recon_start_date: stateData.recon_start_date
        }:
        {}),
        yards: NewYards,
        branch_yards: BranchYards,
        master_type:1,
        user_email:getEmail(),
        has_associated_branch : (stateData || {}).has_associated_branch,
        associated_branch : (stateData || {}).associated_branch || []
      	};

        console.log('finalData:', finalData.terms_and_conditions_doc)
      // if (typeof (finalData.terms_and_conditions_doc) === 'string') {
      //   dispatch(
      //     {
      //       type: ACTIONS.MSG_DISPLAY.SHOW_MSG,
      //       data: { type: 1, msg: "Please Upload File" }
      //     }
      //   )
      //   return
      // }

			if (this.props.editMode) {
				finalData["branch_id"] = this.props.branch_id
				finalData["update_flag"] = true;
     
				finalData["previous_data"] = trackDetails;
				this.props.updatePlant(finalData);
			} else {
        finalData["new_flag"] = true;
				this.props.createNewPlant(finalData);
			}
			this.setState({ isFormUpdated: false }, () => {
				this.props.isFormEdited(this.state.isFormUpdated);
			});
		}
	};

	onAddConsignee = () => {
		const { details } = this.state;
		const data = {
			code: "",
			gst: ""
		}
		details.primary_consignee.push(data);
		this.setState({ details, isFormUpdated: true }, () => {
			this.props.isFormEdited(this.state.isFormUpdated);
		});
	}

	onDeleteConsignee = (index) => {
		const { details } = this.state;
		details.primary_consignee.splice(index, 1);
		this.setState({ details, isFormUpdated: true }, () => {
			this.props.isFormEdited(this.state.isFormUpdated);
		});
	}

	onAddPointofContact = () => {
		const { details } = this.state;
		const data = {
			name: "",
			role: "",
			mobile: "",
			email: ""
		}
		if (!details.contacts) {
			details.contacts = [data];
		} else {
			details.contacts.push(data);
		}

		this.setState({ details, isFormUpdated: true }, () => {
			this.props.isFormEdited(this.state.isFormUpdated);
		});
	}

	onDeletePointofContact = (index) => {
		const { details } = this.state;
		details.contacts.splice(index, 1);
		this.setState({ details, isFormUpdated: true }, () => {
			this.props.isFormEdited(this.state.isFormUpdated);
		});
		this.toggleDelete()
	}

	getLatLong = (address) => {
		if (address) {
			// Geocode.fromAddress(address).then(
				// response => {
				// 	const { lat, lng } = response.results[0].geometry.location;
				// 	let { details } = this.state;
				// 	details.latitude = lat;
				// 	details.longitude = lng;
				// 	this.setState({ details });
				// },
			// 	error => {
			// 		console.error(error);
			// 	}
			// );

      geocodeAddress(address).then(
        (response) => {
          const { lat, lng } = response[0].geometry.location;
          let { details } = this.state;
					details.latitude = lat();
					details.longitude = lng();
					this.setState({ details });
        },
        error => {
          console.error(error);
        }
      );
		} else {
			const { details } = this.state;
			details.longitude = '';
			details.latitude = '';
			this.setState({ details });
		}
	}

	onChangePointofContact = (e, id, index) => {
    let { details } = this.state;
		if (id == 'mobile' && e.target.value.length > 10) {
			return;
		}
      details.contacts[index][id] = e.target.value;
		this.setState({ details, isFormEdited: true }, () => {
			this.props.isFormEdited(this.state.isFormEdited);
		});
	}

	onChangeConsignee = (index, value, id) => {
		const { details } = this.state;
		details.primary_consignee[index][id] = value;
		this.setState({ details, isFormEdited: true }, () => {
			this.props.isFormEdited(this.state.isFormEdited);
		});
	}

	toggleDelete = (index) => {
		this.setState({
			showDelete: !this.state.showDelete,
			selectedIndex: index
		})
	}
	getState = () => {
		const { dispatch } = this.props;
		const data = {
			company_id: this.props.company_id
		}
		dispatch({
      type: ACTIONS.MASTER.LOCATION_NETWORK.GET_STATE, 
      data, 
      onSuccess: (data) => {
        if((data || []).length > 0){
          this.setState({ stateList: data || [] })
        } 
      } 
    });
	}

	fileUploaded = e => {
		        e.persist();
		        this.setState({
		            fileName: e.target.files[0]
		        });
		        const fileObject = e.target.files[0];
		        const reader = new FileReader();
		        reader.onload = e => {
		            const details = Object.assign({}, this.state.details);
		            details.terms_and_conditions_doc = e.target.result;
		            this.setState({
		                details
		            });
		        };
		        reader.readAsDataURL(fileObject);
		  };

		fileClicked = (e) => {
			        e.preventDefault();
			        document.getElementById("file").click();
			 };
			
			termAndConditionsUpload = () => {
        const{dispatch}=this.props;
				        if (this.state.fileName) {
				            let formData = new FormData();
				            formData.append("file", this.state.fileName);
				//             this.props.termAndConditionUpload(formData);
        const onSuccess = (data)=>{
          this.setState((prevState) => {
            const updatedState = { ...prevState }
            updatedState.details.terms_and_conditions_doc = data;
            return updatedState
      });
        }
          dispatch({
            type: ACTIONS.PLANTS.UPLOAD_TERM_CONDITION,
            data: formData,
            onSuccess
          })
        }
      };

  dedicatedAddField = () => {
    const { details } = this.state;
    details.dedicated_lanes.push({
      from: "",
      to: "",
      from_name:"",
      to_name:"",
      count: null
    })
    this.setState({ details })
  }

  dedicatedRemoveField = (index) => {
    const { details } = this.state;
    details.dedicated_lanes.splice(index, 1)
    this.setState({ details })
  }
    
  onChangeRecheckApproval = (e, id, type) => {
    const { approval_types } = this.state;
    
    approval_types.map(appType=>{
      if(appType.approval_type == id){
        appType[type] = isNaN(parseInt(e)) ? null : parseInt(e);
      }
      if(type == 'to' && (id == appType.approval_type - 1)){
        appType['from'] = isNaN(parseInt(e)) ? null : parseInt(e);
      }
      if(type == 'from' && (id > 1) && (appType.approval_type == id - 1)){
        appType['to'] = isNaN(parseInt(e)) ? null : parseInt(e);
      }
      return appType;
    })

    this.setState({
      approval_types
    })


  }

  getRecheckApprovalValue = (type, id) => {
    const { approval_types } = this.state;

    const requiredVal = approval_types.find(appType => appType.approval_type == id)

    if(requiredVal){
      return requiredVal[type]
    }else{
      return null
    }

  }
  getInsideTabDropDownName = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.MASTER.MASTER_DROP_DOWN.GET_INSIDE_TAB_DROPDOWN,
      param: `${this.props.company_id}&dropdown_type_id=${14}`,
    })
  }
				
	render() {
    const { stateListData, insideTabDropDownName ,allPlantBranches}=this.props;
    const {stateList} = this.state;
    console.log("this.state",this.state.trackDetails)
		const StateDataList =
		(stateList || []).map((data) => ({
		label: data.name,
		value: data.code,
		...data
		})) || [];
    const businessTypeList = 
    (insideTabDropDownName||[]).map((data)=>({
      label:data.dropdown_name,
      value:data.dropdown_id
    }))||[]
		const details = this.state.details || {};
		console.log('details:', details)
		let optionZone = [],
			optionBranchType = [];
		if (this.props.plantsDepots.zones) {
			for (let i = 0; i < this.props.plantsDepots.zones.length; i++) {
				optionZone.push({
					value: this.props.plantsDepots.zones[i].id,
					label: this.props.plantsDepots.zones[i].name
				});
			}
		}
		if (this.props.plantsDepots.branchTypes) {
			for (let i = 0; i < this.props.plantsDepots.branchTypes.length; i++) {
				optionBranchType.push({
					value: this.props.plantsDepots.branchTypes[i].id,
					label: this.props.plantsDepots.branchTypes[i].name
				});
			}
		}
    const selectedBranch = this.state.details.branch;
    
		return (
			<div className="plants-basic-details-wrap">
				{details && details.sync_errors && details.sync_errors.length > 0 &&
			   	<div className='error-sec'> Please resolve the below issues for proper tracking: 
					<ul>
					{(details.sync_errors || []).map((error) => {
						return (
							<li className="mt-5 ml-10">
								{error}
							</li>
					)})}
			   		</ul>
			   	</div>
			   }
			   {details && details.warning_errors && details.warning_errors.length > 0 &&
			   <div className='warning-sec'> Warning: Some features of tracking might not be functional due to below errors
				<ul>
				{(details.warning_errors || []).map((warnings) => {
					return (
						<li className="mt-5 ml-10">
							{warnings}
						</li>
				)})}
					</ul>
				</div>
			   }
				<div className="details">
					<InputText
						placeholder="Name"
						id={"branch_name"}
						label="Name"
						value={details.branch_name}
						changeHandler={this.onChangeHandler}
						required={true}
					/>
					<InputText
						placeholder="City"
						id={"city"}
						label="City"
						value={details.city}
						changeHandler={this.onChangeHandler}
						required={true}
					/>
					<InputText
						type="number"
						placeholder="Tolerance"
						id={"tolerance"}
						label="Tolerance"
						value={details.tolerance}
						changeHandler={this.onChangeHandler}
						required={true}
					/>
					{/* <InputText
            placeholder="Code"
            id={"branch_code"}
            label="Code"
            value={details.branch_code}
            changeHandler={this.onChangeHandler}
          />
          <InputText
            placeholder="Planning Point"
            id={"planning_point"}
            label="Planning Point"
            value={details.planning_point}
            changeHandler={this.onChangeHandler}
          /> */}
        </div>
        <div className="details">
          <InputText
            placeholder="Code"
            id={"branch_code"}
            label="Code"
            value={details.branch_code}
            changeHandler={this.onChangeHandler}
            required={true}
          />

          <InputText
            placeholder="SAP Branch Code"
            id={"sap_branch_code"}
            label="SAP Branch Code"
            value={details.sap_branch_code}
            changeHandler={this.onChangeHandler}
          />

          <InputText
            placeholder="Planning Point"
            id={"planning_point"}
            label="Planning Point"
            value={details.planning_point}
            changeHandler={this.onChangeHandler}
          />
        </div>
        <div className="details">
          <InputText
            placeholder="PAN"
            id={"pan"}
            label="PAN"
            value={details.pan}
            changeHandler={this.onChangeHandler}
            length={10}
          />

          <InputText
            placeholder="GST"
            id={"gst"}
            label="GST"
            value={details.gst}
            changeHandler={this.onChangeHandler}
            length={'16'}
          />

          {AWL_COMPANY_IDS.includes(this.props.company_id) &&
            <InputText
              placeholder="SAP MultiModel Point "
              id={"sap_mm_point"}
              label="SAP MultiModel Point "
              value={details.sap_mm_point}
              changeHandler={this.onChangeHandler}
            />
            }
          {AWL_COMPANY_IDS.includes(this.props.company_id) && 
          (details.branch_type===27) &&
            <InputText
              placeholder="Railway SAP Vendor Code "
              id={"railway_vendor_code"}
              label="Railway SAP Vendor Code "
              value={details.railway_vendor_code}
              changeHandler={this.onChangeHandler}
            />
            }

          <div className="select-wrap-depot auto_move">
            <CustomCheckBox
              id="is_auto_move_enabled"
              selected={details.is_auto_move_enabled}
              toggle={() => {
                const { details } = this.state;
                details.is_auto_move_enabled = !details.is_auto_move_enabled;
                this.setState({ details });
              }}
            />
            <div className="title">Auto Move</div>
          </div>

          {AWL_COMPANY_IDS.includes(this.props.company_id) && 
          <div className="select-wrap-depot auto_move">
						<CustomCheckBox 
							id="is_recon_enabled" 
							selected={details.is_recon_enabled} 
							toggle={() => {
							const { details } = this.state;
							details.is_recon_enabled = !details.is_recon_enabled
							this.setState({ details })
						}}/>
						<div className="title">Enable Recon</div>
					</div>}

					{AWL_COMPANY_IDS.includes(this.props.company_id) && details.is_recon_enabled &&
					<div className="select-wrap-depot single-date-input border-bottom">
						<div className='label'>Recon Enable Date</div> 
						<SingleDatePicker
							noBorder={true}
							displayFormat={"DD MMM YYYY"}
							hideKeyboardShortcutsPanel={true}
							placeholder={"Recon Date"}
							numberOfMonths={1}
							date={details.recon_start_date && Moment(details.recon_start_date) || null} 
							onDateChange={(from) =>{
								const {details} = this.state;
								details.recon_start_date = Moment(from).valueOf() || null;
								this.setState({ details });
							}}
							focused={this.state.focusedStart} 
							onFocusChange={({ focused }) => this.setState({ focusedStart: focused })} 
							isOutsideRange={(date) => date < new Date().setDate(new Date().getDate() - 45)}
						/>
					</div>}
        </div>
        <div className="details ">
          <div className="select-wrap-depot">
            <div className="label">
              {"Location Type"}
              <sup className={"error error-font"}>*</sup>
            </div>
            <Select
              className="select"
              value={details.branch}
              onChange={this.handleChange.bind(this, "branch")}
              options={optionBranchType}
            />
          </div>

          {details.branch && details.branch.label == 'Depot' &&
            <div className="select-wrap-depot">
              <div className="label">
                {"Destination Yard"}
                {/* <sup className={"error error-font"}>*</sup> */}
              </div>
              <Select
                isMulti
                className="select"
                value={details.yards}
                onChange={(value) => {
                  details.yards = value;
                  this.setState({ details })
                }}
                options={this.state.yardsOptions}
              />
            </div>}

            {((details.branch && [46,47].includes((details.branch || {}).value)) || (details.branch_type && [46,47].includes(details.branch_type))) &&
            <div className="select-wrap-depot">
              <div className="label">
                {"Branch Yard Mapping"}
                {/* <sup className={"error error-font"}>*</sup> */}
              </div>
              <Select
                isMulti
                className="select"
                value={details.branch_yards}
                onChange={(value) => {
                  details.branch_yards = value;
                  this.setState({ details })
                }}
                options={this.state.allBranches || []}
              />
            </div>}

          <div className="select-wrap-depot">
            <div className="label">{"Chain Type"}</div>
            <Select
              className="select"
              value={details.chain_type}
              onChange={this.handleChange.bind(this, "chain_type")}
              options={netWorkTypes}
            />
          </div>
          <div className="select-wrap-depot">
            <div className="label">{"Movement Type"}</div>
            <Select
              className="select"
              value={details.movement_type}
              onChange={this.handleChange.bind(this, "movement_type")}
              options={movementTypes}
            />
          </div>
          {BEAM_COMPANY_IDS.includes(this.props.company_id) &&
          <div className="select-wrap-depot">
            <div className="label">{"Delivery Consignee Type"}</div>
            <Select
              className="select"
              value={details.delivery_consignee_type}
              onChange={this.handleChange.bind(this, "delivery_consignee_type")}
              options={deliveryConsigneeType}
              isMulti={true}
            />
          </div>}
          {/* <div className="label">{'Zone'}</div>
            <Select
              className="select"
              value={this.state.zone}
              onChange={this.handleChange.bind(this, "zone")}
              options={optionZone}
            />
          </div> 
          <InputText
            placeholder="Region"
            id={"region"}
            label="region"
            value={details.region}
            changeHandler={this.onChangeHandler}
        /> */}
        </div>

        <div className="details">
          <InputText
            placeholder={"Primary Consignee Code"}
            id={"primary_consignee_code"}
            label={"Primary Consignee Code"}
            value={details.primary_consignee_code}
            changeHandler={this.onChangeHandler}
            length={'10'}
          />
          <InputText
            placeholder={"Primary Consignee GST"}
            id={"primary_consignee_gst"}
            label={"Primary Consignee GST"}
            value={details.primary_consignee_gst}
            changeHandler={this.onChangeHandler}
          />
          {(CARGILL_COMPANY_IDS.includes(this.props.company_id)) ?
           <div className="select-wrap-depot">
              <div className="label">{"Business Type"}
                <sup className={"error error-font"}>*</sup>
                </div>
            <Select
              className="select"
                value={businessTypeList && businessTypeList.find((val) => val.label == details.business_type)}
                onChange={this.handleChange.bind(this, "business_type")}
                options={businessTypeList}
                required={true}
            />
          </div> :
          <InputText
            placeholder={"Business Type"}
            id={"business_type"}
            label={"Business Type"}
            value={details.business_type}
            changeHandler={this.onChangeHandler}
          />}
        </div>

        <div className="details">
          <InputText
            placeholder={"Secondary Consignee Code"}
            id={"secondary_consignee_code"}
            label={"Secondary Consignee Code"}
            value={details.secondary_consignee_code}
            changeHandler={this.onChangeHandler}
            length={'10'}
          />
          <InputText
            placeholder={"Secondary Consignee GST"}
            id={"secondary_consignee_gst"}
            label={"Secondary Consignee GST"}
            value={details.secondary_consignee_gst}
            changeHandler={this.onChangeHandler}
          />
          <InputText
            placeholder={"Brand"}
            id={"brand"}
            label={"Brand"}
            value={details.brand}
            changeHandler={this.onChangeHandler}
          />
        </div>
        <div className="details">
          <div className="select-wrap-depot auto_move">
            <CustomCheckBox
              id="has_associated_branch"
              selected={details.has_associated_branch}
              toggle={() => {
                const { details } = this.state;
                details.has_associated_branch = !details.has_associated_branch
                this.setState({ details })
              }} />
            <div className="title">Associate Branch</div>
          </div>
          {(details || {}).has_associated_branch &&
            <div className="select-wrap-depot">
              <div className="label">Select Associate Branches</div>
              <Select
                isMulti
                className="select"
                value={
                  (details.associated_branch || []).map(value =>
                    this.state.allBranches.find(branch => branch.value === value)
                  )
                }
                onChange={(selectedOptions) => {
                  details.associated_branch = selectedOptions.map(option => option.value);
                  this.setState({ details })
                }}
                options={this.state.allBranches || []}
              />
            </div>
          }
        </div>
        <div className={"details-section"}>
          <div className="heading-section">Primary Contact</div>
          <div className="details">
            <InputText
              placeholder="Name"
              id={"primary_consignee_name"}
              label="Name"
              value={details.primary_consignee_name}
              changeHandler={this.onChangeHandler}
            />
            <InputText
              placeholder="Email"
              id={"primary_consignee_email"}
              label="Email"
              value={details.primary_consignee_email}
              changeHandler={this.onChangeHandler}
            />
            <InputText
              placeholder="Contact"
              id={"primary_consignee_contact"}
              label="Contact"
              value={details.primary_consignee_contact}
              changeHandler={this.onChangeHandler}
              type='number'
            />
          </div>
        </div>

        <div className={"address-section details-section"}>
          <div className="heading-section">Address</div>
          <div className={"details"}>
            <div className={"refresh"}>
              <div
                className={"refresh-icon"}
                style={{ backgroundImage: `url(${Refresh})` }}
              >
                {" "}
              </div>
              <InputText
                placeholder="Address"
                id={"address"}
                label={"Address"}
                changeHandler={this.onChangeHandler}
                additionalProps={{ onBlur: this.onBlurChange }}
                value={details.address}
                required={true}
              />
            </div>
            <div className={"refresh"}>
              {/*<div
                className={"refresh-icon"}
                style={{ backgroundImage: `url(${Refresh})` }}
                onClick={() => this.getLatLong(details.address)}
	></div> */}
              <InputText
                placeholder="Latitude"
                id={"latitude"}
                label={"Latitude"}
                value={details.latitude}
				changeHandler={this.onChangeHandler}
				additionalProps={{ onBlur: this.onBlurChange }}
              />
            </div>
            <div className={"refresh"}>
              {/*  <div
                className={"refresh-icon long"}
                style={{ backgroundImage: `url(${Refresh})` }}
                onClick={() => this.getLatLong(details.address)}
></div> */}
              <InputText
                placeholder="Longitude"
                id={"longitude"}
                label={"Longitude"}
                value={details.longitude}
				changeHandler={this.onChangeHandler}
				additionalProps={{ onBlur: this.onBlurChange }}
              />
            </div>

            <div className="select-wrap-depot">
              <div className="label">
                Region/State<sup className={"error error-font"}>*</sup>
              </div>
              <Select
                className="select"
                options={StateDataList}
                value={StateDataList.find(s => s.name == details.region)}
                onChange={this.handleChange.bind(this, "region")}
              />
            </div>

            <div className="select-wrap-depot">
              <div className="label">
                Zone<sup className={"error error-font"}>*</sup>
              </div>
			  <InputText
			  	disabled
				placeholder="Zone"
				id={"zone"}
				value={details.zone}
        changeHandler={()=>{}}
			/>
            </div>

            <div className="select-wrap-depot">
              <div className="label">
                Cluster
                {AWL_COMPANY_IDS.includes(details.company_id) && (
                  <sup className={"error error-font"}>*</sup>
                )}
              </div>
			  <InputText
			  	disabled
				placeholder="Cluster"
				id={"cluster"}
				value={details.cluster}
			/>
            </div>

					<div>
					<InputText
							label="Geofence radius sim"
							placeholder="geofence_radius_sim"
							id={"geofence_radius_sim"}
							value={details.geofence_radius_sim}
							changeHandler={this.onChangeHandler}
							additionalProps={{ onBlur: this.onBlurChange }}
							errormessage={this.state.errormessage}
							type='number'
						/>

                        <InputText
							label="Geofence radius gps"
							placeholder="geofence_radius_gps"
							id={"geofence_radius_gps"}
							value={details.geofence_radius_gps}
							changeHandler={this.onChangeHandler}
							additionalProps={{ onBlur: this.onBlurChange }}
							errormessage={this.state.errormessage}
							type='number'
						/>
				</div>
			<div className="select-wrap-depot">
            <div className="label">
              Zip Code<sup className={"error error-font"}>*</sup>
            </div>
            <InputText
              placeholder="ZIP Code"
              id={"pincode"}
              //label={"ZIP Code"}
              changeHandler={this.onChangeHandler}
              value={details.pincode}
              length={'6'}
            />
			</div>

            <InputText
              label="Indent Request Approval Email"
              placeholder="Email"
              id={"consignee_email"}
              value={details.consignee_email}
              changeHandler={this.onChangeHandler}
              additionalProps={{ onBlur: this.onBlurChange }}
              errormessage={this.state.errormessage}
            />
          </div>
        </div>

        <div className={"details-section"}>
          <div className="heading-section">Start And End Timings</div>
          <div className={"details"}>
            <div className="data-picker">
              <div className="label">
                Daily Report Start Time
                {/*<sup className={'error error-font'}>*</sup>*/}
              </div>
              <TimePicker
                format={"h:mm a"}
                showSecond={false}
                placeholder={"Time"}
                value={
                  details.start_time
                    ? Moment(details.start_time, "HH:mm")
                    : null
                }
                onChange={(value) => {
                  let { details } = this.state;
                  details.start_time = Moment(value).format("HH:mm");
                  this.setState({ details, isFormUpdated: true }, () => {
                    this.props.isFormEdited(this.state.isFormUpdated);
                  });
                }}
                use12Hours
              />
            </div>
            <div className="data-picker">
              <div className="label">
                Daily Report End Time
                {/*<sup className={'error error-font'}>*</sup>*/}
              </div>
              <TimePicker
                format={"h:mm a"}
                showSecond={false}
                placeholder={"Time"}
                value={
                  details.end_time ? Moment(details.end_time, "HH:mm") : null
                }
                onChange={(value) => {
                  let { details } = this.state;
                  details.end_time = Moment(value).format("HH:mm");
                  this.setState({ details, isFormUpdated: true }, () => {
                    this.props.isFormEdited(this.state.isFormUpdated);
                  });
                }}
                use12Hours
              />
            </div>
            <div className="select-wrap-depot">
              <div className="label">
                Week-off
                {/*<sup className={'error error-font'}>*</sup>*/}
              </div>
              <Select
                className="select"
                options={weeklyOff}
                value={details.weekly_off}
                onChange={this.handleChange.bind(this, "weekly_off")}
                isMulti={true}
              />
            </div>
          </div>
        </div>

        <div className="point-of-contact content">
          <div className={"heading-section"}>Point Of Contact</div>
          <div className={"list-section header"}>
            <div className={"tabel-header name"}>Name</div>
            <div className={"tabel-header"}>Role</div>
            <div className={"tabel-header"}>Mobile</div>
            <div className={"tabel-header email"}>Email</div>
            <div className={"tabel-header"}>Action</div>
          </div>
          {details.contacts &&
            details.contacts.map((val, index) => {
              return (
                <div className={"list-section"} key={index}>
                  <input
                    className={"tabel-header-input name code-value"}
                    value={val.name}
                    onChange={(e) =>
                      this.onChangePointofContact(e, "name", index)
                    }
                  ></input>
                  <input
                    className={"tabel-header-input code-value"}
                    value={val.role}
                    onChange={(e) =>
                      this.onChangePointofContact(e, "role", index)
                    }
                  ></input>
                  <input
                    className={"tabel-header-input code-value"}
                    type="number"
                    value={val.mobile}
                    onChange={(e) =>
                      this.onChangePointofContact(e, "mobile", index)
                    }
                  ></input>
                  <input
                    className={"tabel-header-input email code-value"}
                    value={val.email}
                    onChange={(e) =>
                      this.onChangePointofContact(e, "email", index)
                    }
                  ></input>
                  <div className={"tabel-header"}>
                    <div
                      className={"delete-icon"}
                      style={{ backgroundImage: `url(${Delete})` }}
                      onClick={() => this.toggleDelete(index)}
                    ></div>
                  </div>
                </div>
              );
            })}
          <div className="contact-btn">
            <Button value="+ Add" click={this.onAddPointofContact} />
          </div>
        </div>

        {EMAMI_COMPANY_IDS.includes(this.props.company_id) &&
          <div className="dedicated-vehicle">
            <div className="first-header">
              <CustomCheckBox
                id="has_dedicated_vehicle"
                selected={details.has_dedicated_vehicle}
                toggle={() => {
                  const { details } = this.state;
                  details.has_dedicated_vehicle = !details.has_dedicated_vehicle;
                  this.setState({ details });
                }}
              />
              <p style={{ marginLeft: '10px' }}>Dedicated Vehicle</p>
            </div>

            {details.has_dedicated_vehicle &&
              <>
                {(details.dedicated_lanes || []).map((data, dIdx) => {
                  return (
                    <div className="dedicated-content">
                      <div className="select-wrap-depot">
                        <div className="label">
                          From
                        </div>
                        <Select
                          className="select"
                          options={this.state.allBranches || []}
                          value={(this.state.allBranches || []).filter((branch) => { return branch.label === data.from_name })}
                          onChange={(selectedOption) => {
                            const { details } = this.state;
                            details.dedicated_lanes[dIdx].from_name = selectedOption.label
                            details.dedicated_lanes[dIdx].from = selectedOption.branch_code
                            this.setState({ details })
                          }}
                        />
                      </div>

                      <div className="select-wrap-depot">
                        <div className="label">
                          To
                        </div>
                        <Select
                          className="select"
                          options={this.state.allBranches || []}
                          value={(this.state.allBranches || []).filter((branch) => { return branch.label === data.to_name })}
                          onChange={(selectedOption) => {
                            const { details } = this.state;
                            details.dedicated_lanes[dIdx].to_name = selectedOption.label
                            details.dedicated_lanes[dIdx].to = selectedOption.branch_code
                            this.setState({ details })
                          }}
                        />
                      </div>

                      <InputText
                        placeholder="count"
                        id={"count"}
                        label={"Count"}
                        type={"number"}
                        changeHandler={(e) => {
                          const { details } = this.state;
                          details.dedicated_lanes[dIdx].count = parseInt(e)
                          this.setState({ details })
                        }}
                        value={data.count || ""}
                      />

                      <div style={{ display: 'flex', marginLeft: '20px', alignItems: 'center', width: '100px' }}>
                        {(dIdx !== 0) &&
                          <div
                            className={"delete-icon"}
                            style={{ backgroundImage: `url(${Delete})` }}
                            onClick={() => this.dedicatedRemoveField(dIdx)}
                          >
                          </div>
                        }
                        {(dIdx == details.dedicated_lanes.length - 1) &&
                          <div
                            className={"delete-icon"}
                            style={{ backgroundImage: `url(${Add})` }}
                            onClick={() => this.dedicatedAddField()}
                          >
                          </div>
                        }
                      </div>
                    </div>
                  )
                })}
              </>
            }

          </div>
        }
        {AWL_COMPANY_IDS.includes(this.props.company_id) && 
         (this.props.plantsDepots.currentDepot.branch_type === 13 ||(!!selectedBranch?selectedBranch.value === 13 :false))
          &&
          <div className="select-wrap-depot auto_move mt-10">
          <CustomCheckBox
            id="is_scd_enabled"
            selected={details.is_scd_enabled}
            toggle={() => {
              const { details } = this.state;
              details.is_scd_enabled = !details.is_scd_enabled;
              details.is_depot_scd = !details.is_depot_scd;
              this.setState({ details });
            }}
          />
          <div className="title">&nbsp;&nbsp;New SCD</div>
          </div>
        }

        {AWL_COMPANY_IDS.includes(this.props.company_id) &&
          <div className="recheck-approval">
            <div className="first-header">
              <CustomCheckBox
                id="has_recheck_approval"
                selected={details.has_recheck_approval}
                toggle={() => {
                  const { details } = this.state;
                  details.has_recheck_approval = !details.has_recheck_approval;
                  this.setState({ details });
                }}
              />
              <p style={{ marginLeft: '10px' }}>Recheck Approval</p>
            </div>
            
            {details.has_recheck_approval &&
              <>
              <div className="input-container">
                <div className="label mt-25">
                  Recheck Approval Level 1
                </div>
                <div className="approval-content">
                  <div className="select-wrap-depot">
                    <InputText
                      placeholder="Enter Value.."
                      type="number"
                      id={"from"}
                      label="From"
                      value={this.getRecheckApprovalValue('from', 1)}
                      changeHandler={(e) => { this.onChangeRecheckApproval(e, 1, 'from') }}
                    />
                  </div>
                  <span>Kg</span>

                  <div className="select-wrap-depot">
                    <InputText
                      type="number"
                      placeholder="Enter Value.."
                      id={"to"}
                      label="To"
                      value={this.getRecheckApprovalValue('to', 1)}
                      changeHandler={(e) => { this.onChangeRecheckApproval(e, 1, 'to') }}
                    />
                  </div>
                  <span>Kg</span>
                </div>
              </div>

              <div className="input-container">
                <div className="label mt-25">
                  Recheck Approval Level 2
                </div>
                <div className="approval-content">
                  <div className="select-wrap-depot">
                    <InputText        
                      type="number"              
                      placeholder="Enter Value.."
                      id={"from"}
                      label="From"
                      value={this.getRecheckApprovalValue('from', 2)}
                      changeHandler={(e) => { this.onChangeRecheckApproval(e, 2, 'from') }}
                    />
                  </div>
                  <span>Kg</span>

                  <div className="select-wrap-depot">
                    <InputText    
                      type="number"                  
                      placeholder="Enter Value.."
                      id={"to"}
                      label="To"
                      value={this.getRecheckApprovalValue('to', 2)}
                      changeHandler={(e) => { this.onChangeRecheckApproval(e, 2, 'to') }}
                    />
                  </div>
                  <span>Kg</span>
                </div>
                </div>

              <div className="input-container">
                <div className="label mt-25">
                  Recheck Approval Level 3
                </div>
                <div className="approval-content">
                  <div className="select-wrap-depot">
                    <InputText    
                      type="number"                  
                      placeholder="Enter Value.."
                      id={"from"}
                      label="From"
                      value={this.getRecheckApprovalValue('from', 3)}
                      changeHandler={(e) => { this.onChangeRecheckApproval(e, 3, 'from') }}
                    />
                  </div>
                  <span>Kg And Above</span>
                </div>
                </div>
              </>

            }

          </div>
        }

        <div className={"address-section details-section mt-30"}>
          <div className="file-lable-section">
            <div className="file-upload-details">
              <div className="title">Auto RFQ Term and Condition</div>
              <div className="file-holder">
                <input id="file" type="file" onChange={this.fileUploaded} />
                <span className="file-choose" onClick={this.fileClicked}></span>
                <span className="file-name">
                  {this.state.fileName
                    ? this.state.fileName.name
                    : (this.state.details.terms_and_conditions_doc || {}).name}
                </span>
                <div className="submit-section-depot">
                  <Button value="Upload" click={this.termAndConditionsUpload} />
                </div>
              </div>
            </div>
              
          </div>
          <InputText
            placeholder="Auto Rfq Bid Duration (Minutes)"
            id={"auto_rfq_bid_duration"}
            label={"Auto Rfq Bid Duration (Minutes)"}
            changeHandler={this.onChangeHandler}
            value={details.auto_rfq_bid_duration}
          />
        </div>

        {/* <div className="details address-details">
          <div className="address">
            <InputText
              placeholder="Address"
              id={"address"}
              label="address"
              value={details.address}
              changeHandler={this.onChangeHandler}
            />
          </div>
          <div className="pin-code">
            <InputText
              placeholder="Pin code"
              id={"pincode"}
              label="Pin code"
              value={details.pincode}
              changeHandler={this.onChangeHandler}
              length={'6'}
              isValidate={'numeric'}
              additionalProps={{onBlur : this.onBlurChange}} 
              iserror={this.state.iserror} 
              errormessage={this.state.errormessage}
            />
          </div>
        </div>
        <div className="details">
          <InputText
            placeholder="SAP Branch code"
            id={"sap_branch_code"}
            label="SAP branch code"
            value={details.sap_branch_code}
            changeHandler={this.onChangeHandler}
          />
        </div> */}
        {this.state.error != "" && (
          <div className="error-section">{this.state.error}</div>
        )}
        <div className="submit-section-depot">
          <Button value="Submit" click={this.submitBranchDetails} />
        </div>

        {this.state.showDelete && (
          <div className="delete-company-wrap">
            <div className="overlay"></div>
            <div className="modal-popup1 small-modal">
              <div>
                <div className="question"> Are You Sure ?</div>
                <div className="submit-section submit-area">
                  <div className="button-section">
                    <div
                      className="add-button"
                      onClick={
                        this.onDeletePointofContact || this.props.toggleDelete
                      }
                    >
                      Delete
                    </div>
                  </div>
                  <div className="button-section">
                    <div className="add-button" onClick={this.toggleDelete}>
                      Close
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const CustomCheckBox = (props) => {
	return (
		<div className={props.selected ? "custome-checkbox-wrap selected" : "custome-checkbox-wrap"} onClick={() => props.toggle(props.id)}>
			{props.selected && <div className="inner-circle"></div>}
		</div>
	)
}

const mapStateToProps = state => {
	return {
    master:state.master,
		stateListData:state.master.stateList,
    insideTabDropDownName:state.master.insideTabDropDownName,
	};
};

export default connect(mapStateToProps)(PlantsBasicDetails);
