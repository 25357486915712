import { getCookie } from "../utils/cookies";
function isEmpty(name) {
  if (!name && name == "") return true;
  return false;
}
export { isEmpty };
export var SUPER_ADMIN = 1;
export var COMPANY_ADMIN = 2 || 3 || 4;
export var COMPANY_ADMIN_SEEKER = 2;
export var COMPANY_ADMIN_PROVIDER = 3;
export var COMPANY_ADMIN_3PL = 4;
export var COMPANY_ADMIN_FLEET_OWNER_AND_BROKER = 5;
export var COMPANY_ADMIN_FLEET_OWNER = 6;
export var COMPANY_ADMIN_BROKER = 7;
export var COMPANY_ADMIN_FLEET_OWNER_AND_TRANSPORTER = 8;
export var COMPANY_ADMIN_AGGREGATORS = 9;
export var COMPANY_ADMIN_SURVEYORS = 10;
export var COMPANY_ADMIN_LASHERS = 11;
export var COMPANY_ADMIN_HANDLING_AGENT = 21;
export var COMPANY_ADMIN_SHIPPER_LINERS = 22;
export var ABFRL_COMPANY_IDS = ['782bd60a53f148b3b342474da97f5be8', '62cd3ea65c60478182b5aa9da9f525fc'];
export var DEEPAK_COMPANY_ID = ['55afbf6dc2154d12a6ed5b9306d39eb5', '32b21780a04849cfa7f0ef9b868ce381', 'db6c293235a643dd9fc5620addf13768', 'ad603a0abb0f4719bcb3b92054ff2545', 'f13b2e9106724bca8e3e99f5c8751aa4', '6bd9e414d76d4f259d8a08aad04ae731', '5dc2459d1d40478984dc35132b90ee30'];
export var TTBIL_COMPANY_IDS = ['c8d94d5ce304440e86ee51bfc422f247', '52920784121d4d639be3a13ace865e50'];
export var AWL_COMPANY_IDS = ['999', '872'];
export var PANTALOONS_COMPANY_IDS = ['3d93fe6f91424c4b894896410b734bbc', '5e621316bbfc45c4a2e46a7a1975f831', '71f16d1bb8604c7d8d2b4821b4ab0122', '8274e9c6-4402-11ed-b878-0242ac120002'];
export var GREENPLY_COMPANY_IDS = ['3f068bae400d4713b6b784a6282596e4'];
export var RAJ_PETRO_SPECIALITIES = ['f5862184a9b648b8ae3c8cfc4dbe242d', '344d1da11e894163ba8aa25b041cee37'];
export var ROQUETTE = ['a5dfe89d1723470aa6058fb8f0f10694', '87a5e8a5d89b4b58ae7d1fcde7c5226e'];
export var CENTURY_PLY = ['1925f143bd604e73aeb3d593d1250aa3', '8fc235d589bb45c082d93e6f634265f0'];
export var CARGILL_COMPANY_IDS = ['04d229c7c1934119960cc4f5312dc45d', '5487b3a6065c47e1b764c03b693fc52b'];
export var EMAMI_COMPANY_IDS = ['66204f3fc0de46a99c7556c7c0b05699', '810e630cbfce43c79b2693c27c35103c'];
export var GENERIC_TOKEN = '5eb7c647379c443a8ab1b6ad1de9403e';
export var EMAMI = ['66204f3fc0de46a99c7556c7c0b05699', '810e630cbfce43c79b2693c27c35103c'];
export var LR_FOR_PANTALOON_COMPANY_ID = ['5e621316bbfc45c4a2e46a7a1975f831', '71f16d1bb8604c7d8d2b4821b4ab0122', '8274e9c6-4402-11ed-b878-0242ac120002'];
export var BEAM_COMPANY_IDS = ['05bb3a74106f4a53a63abdb84867726f', '36285a19742b42d1a9e56570e3caf110'];
export var SAP_DEEPAK_PHENOLICS = ['5dc2459d1d40478984dc35132b90ee30', '6bd9e414d76d4f259d8a08aad04ae731'];
export var LOREAL = ['a0d488121c6e4bc1aa88347a244008d0'];
function isCompanyAdmin() {
  var userInfo = JSON.parse(getCookie('user'));
  if (userInfo.user_type === COMPANY_ADMIN_SEEKER || userInfo.user_type === COMPANY_ADMIN_PROVIDER || userInfo.user_type === COMPANY_ADMIN_3PL || userInfo.user_type === COMPANY_ADMIN_FLEET_OWNER_AND_BROKER || userInfo.user_type === COMPANY_ADMIN_FLEET_OWNER || userInfo.user_type === COMPANY_ADMIN_BROKER || userInfo.user_type === COMPANY_ADMIN_FLEET_OWNER_AND_TRANSPORTER || userInfo.user_type === COMPANY_ADMIN_FLEET_OWNER_AND_TRANSPORTER || userInfo.user_type === COMPANY_ADMIN_SURVEYORS || userInfo.user_type === COMPANY_ADMIN_LASHERS || userInfo.user_type === COMPANY_ADMIN_HANDLING_AGENT || userInfo.user_type === COMPANY_ADMIN_SHIPPER_LINERS) {
    return true;
  }
  return false;
}
export { isCompanyAdmin };
export var scroll = function scroll() {
  var y = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2000;
  var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 200;
  var initialY = document.documentElement.scrollTop || document.body.scrollTop;
  var baseY = (initialY + y) * 0.5;
  var difference = initialY - baseY;
  var startTime = performance.now();
  function step() {
    var normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) normalizedTime = 1;
    window.scrollTo(0, baseY + difference * Math.cos(normalizedTime * Math.PI));
    if (normalizedTime < 1) window.requestAnimationFrame(step);
  }
  window.requestAnimationFrame(step);
};
export var getUserCompanyId = function getUserCompanyId() {
  return JSON.parse(getCookie('user')).company_id;
};
export var getUserId = function getUserId() {
  return JSON.parse(getCookie('user')).user_id;
};
export var getUserCompanyName = function getUserCompanyName() {
  return JSON.parse(getCookie('teg-user')).companyName;
};
export var getSeekerCompanyId = function getSeekerCompanyId() {
  return JSON.parse(getCookie('teg-user')).companyId;
};
export var isBeams = function isBeams(companyId) {
  var isBeams = BEAM_COMPANY_IDS.includes(!!companyId ? companyId : getUserCompanyId());
  return isBeams;
};
export var isAWL = function isAWL(companyId) {
  var isAWL = AWL_COMPANY_IDS.includes(!!companyId ? companyId : getUserCompanyId());
  return isAWL;
};
export var isABFRL = function isABFRL(companyId) {
  var isABFRL = ABFRL_COMPANY_IDS.includes(!!companyId ? companyId : getUserCompanyId());
  return isABFRL;
};
export var isDPL = function isDPL() {
  var isDPL = DEEPAK_COMPANY_ID.includes(getUserCompanyId());
  return isDPL;
};
export var isLoreal = function isLoreal() {
  var isLoreal = LOREAL.includes(getUserCompanyId());
  return isLoreal;
};
export var netWorkTypes = [{
  label: 'All',
  value: 3
}, {
  label: 'External',
  value: 1
}, {
  label: 'Internal',
  value: 2
}];
export var channelTypes = [{
  label: 'B2B',
  value: 1
}, {
  label: 'B2b',
  value: 2
}, {
  label: 'Trade',
  value: 3
}, {
  label: 'Retail',
  value: 4
}, {
  label: 'DK',
  value: 5
}, {
  label: 'DS',
  value: 6
}, {
  label: 'IS',
  value: 7
}, {
  label: 'VC',
  value: 8
}];
export var weeklyOff = [{
  label: 'Sunday',
  value: 1
}, {
  label: 'Monday',
  value: 2
}, {
  label: 'Tuesday',
  value: 3
}, {
  label: 'Wednesday',
  value: 4
}, {
  label: 'Thursday',
  value: 5
}, {
  label: 'Friday',
  value: 6
}, {
  label: 'Saturday',
  value: 7
}];
export var netWorkOptions = [{
  label: 'All',
  value: 0
}, {
  label: 'External',
  value: 1
}, {
  label: 'Internal',
  value: 2
}];
export var movementOptions = [{
  label: 'All',
  value: 0
}, {
  label: 'Outward',
  value: 1
}, {
  label: 'Inward',
  value: 2
}, {
  label: 'Interdepo',
  value: 3
}];
export var fuelStatus = [{
  label: 'Increase',
  value: 1
}, {
  label: 'Decrease',
  value: 2
}];
export var Uom = [{
  label: 'Per RFQ',
  value: 'Per RFQ'
}, {
  label: 'Per Line Item',
  value: 'Per Line Item'
}, {
  label: 'Per Unit Intended',
  value: 'Per Unit Intended'
}];
export var uomEquality = [{
  label: 'Equal to',
  value: "="
}, {
  label: 'Greater than',
  value: ">"
}, {
  label: 'Less than',
  value: "<="
}, {
  label: 'Greater than or Equal to',
  value: ">="
}, {
  label: 'Less than or Equal to',
  value: "<="
}];
export var entityType = [{
  label: 'Pfizer Ltd - IN45',
  value: 1
}, {
  label: 'PPIPL - INO4',
  value: 2
}];
export var addressType = [{
  label: 'Plant',
  value: 1
}, {
  label: 'Hub',
  value: 2
}, {
  label: 'CFA',
  value: 3
}, {
  label: 'Depot',
  value: 4
}, {
  label: 'I & I',
  value: 5
}, {
  label: 'Project',
  value: 6
}, {
  label: 'Dealer',
  value: 7
}, {
  label: 'HW',
  value: 8
}, {
  label: 'Export',
  value: 9
}, {
  label: 'Toll unit',
  value: 10
}, {
  label: 'Trade',
  value: 11
}, {
  label: 'Yard',
  value: 12
}, {
  label: 'Port',
  value: 13
}, {
  label: 'Customer',
  value: 14
}, {
  label: 'Distributor',
  value: 15
}, {
  label: 'Transfer',
  value: 16
}, {
  label: 'Supplier',
  value: 47
}];
export var movementTypes = [{
  label: 'All',
  value: 4
}, {
  label: 'Inward',
  value: 1
}, {
  label: 'Outward',
  value: 2
}, {
  label: 'Interdepo',
  value: 3
}];
export var metroType = [{
  label: 'Metro',
  value: 1
}, {
  label: 'Non-Metro',
  value: 0
}];
export var claimTypes = [{
  label: 'Direct Debit',
  value: 1
}, {
  label: 'Insurance',
  value: 2
}, {
  label: 'Write-off',
  value: 3
}];
export var deliveryConsigneeType = [{
  label: 'CSD',
  value: 1
}, {
  label: 'CIVIL',
  value: 2
}];
export var PAN_CARD_DOC = 1;
export var BANK_DETAILS_DOC = 2;
export var MEMO_ASSOCIATION_DOC = 3;
export var ARTICLE_ASSOCIATION_DOC = 4;
export var COMPLIANCE_DEC_DOC = 5;
export var GST_CERT_DOC = 6;
export var FSSAI_DOC = 7;
export var MSA_DOC = 8;
export var SEEKER_TYPE = 1;
export var PROVIDER_TYPE = 2;
export var SURVEYORS_TYPE = 9;
export var LASHERS_TYPE = 10;
export var HANDLING_AGENT_TYPE = 21;
export var MODULE_LIST = [{
  enable: false,
  id: 1,
  module_name: "Contracting"
}, {
  enable: false,
  id: 2,
  module_name: "Indenting"
}, {
  enable: false,
  id: 3,
  module_name: "Tracking/VIMS"
}, {
  enable: false,
  id: 4,
  module_name: "Accounts"
}, {
  enable: false,
  id: 5,
  module_name: "Reports"
}];
export var TAT_UNITS = [{
  label: 'Days',
  value: 'days'
}, {
  label: 'Hours',
  value: 'hours'
}];
export var CLUSTERS = [{
  label: 'Cluster1',
  value: 1
}, {
  label: 'Cluster2',
  value: 2
}, {
  label: 'Cluster3',
  value: 3
}];
export var CONTRACT_TENURE_UNITS = [{
  name: 'Days',
  value: 'days'
}, {
  name: 'Weeks',
  value: 'weeks'
}, {
  name: 'Months',
  value: 'months'
}];
export var VEHICLE_BODY_TYPE = [{
  label: "Any Body",
  value: "Any Body"
}, {
  label: "BCN",
  value: "BCN"
}, {
  label: "Bottom loading",
  value: "Bottom loading"
}, {
  label: "Closed Body",
  value: "Closed Body"
}, {
  label: "Container Body",
  value: "Container Body"
}, {
  label: "Containerized Ventilated Vehicle",
  value: "Containerized Ventilated Vehicle"
}, {
  label: "Dala Body",
  value: "Dala Body"
}, {
  label: "Dumper",
  value: "Dumper"
}, {
  label: "Dwarf Container",
  value: "Dwarf Container"
}, {
  label: "Flat Bed",
  value: "Flat Bed"
}, {
  label: "Full Punjabi Body",
  value: "Full Punjabi Body"
}, {
  label: "General Purpose",
  value: "General Purpose"
}, {
  label: "Half Body",
  value: "Half Body"
}, {
  label: "High Cube Unit",
  value: "High Cube Unit"
}, {
  label: "Insulated Body",
  value: "Insulated Body"
}, {
  label: "ISO Tanker",
  value: "ISO Tanker"
}, {
  label: "Jacketed",
  value: "Jacketed"
}, {
  label: "Low Bed with Ramp",
  value: "Low Bed with Ramp"
}, {
  label: "Mining Body",
  value: "Mining Body"
}, {
  label: "MS Tanker",
  value: "MS Tanker"
}, {
  label: "Multi Compartment",
  value: "Multi Compartment"
}, {
  label: "Non Jacketed",
  value: "Non Jacketed"
}, {
  label: "Open Body",
  value: "Open Body"
}, {
  label: "Others",
  value: "Others"
}, {
  label: "Reefer Container",
  value: "Reefer Container"
}, {
  label: "Refer Unit",
  value: "Refer Unit"
}, {
  label: "Refrigerated Body",
  value: "Refrigerated Body"
}, {
  label: "Semi Low Bed",
  value: "Semi Low Bed"
}, {
  label: "Side Open",
  value: "Side Open"
}, {
  label: "Single Compartment",
  value: "Single Compartment"
}, {
  label: "SS Tanker",
  value: "SS Tanker"
}, {
  label: "Standard Unit",
  value: "Standard Unit"
}, {
  label: "Tanker",
  value: "Tanker"
}, {
  label: "Top loading",
  value: "Top loading"
}, {
  label: "Trailer",
  value: "Trailer"
}, {
  label: "Trailer And Container",
  value: "Trailer And Container"
}, {
  label: "Trailer MS",
  value: "Trailer MS"
}, {
  label: "Trailer MSI",
  value: "Trailer MSI"
}, {
  label: "Trailer SSI",
  value: "Trailer SSI"
}, {
  label: "Z Bed",
  value: "Z Bed"
}];
export var STATES = [{
  id: 1,
  state: "Bihar",
  is_enable: false
}, {
  id: 2,
  state: "Delhi",
  is_enable: false
}, {
  id: 3,
  state: "Assam",
  is_enable: false
}, {
  id: 4,
  state: "Daman and Diu",
  is_enable: false
}, {
  id: 5,
  state: "Gujarat",
  is_enable: false
}, {
  id: 6,
  state: "Telangana",
  is_enable: false
}, {
  id: 7,
  state: "Andhra Pradesh",
  is_enable: false
}, {
  id: 8,
  state: "Madhya Pradesh",
  is_enable: false
}, {
  id: 9,
  state: "Jammu and Kashmir",
  is_enable: false
}, {
  id: 10,
  state: "Tamil Nadu",
  is_enable: false
}, {
  id: 11,
  state: "Dadra and Nagar Haveli",
  is_enable: false
}, {
  id: 12,
  state: "Puducherry",
  is_enable: false
}, {
  id: 13,
  state: "Chhattisgarh",
  is_enable: false
}, {
  id: 14,
  state: "Karnataka",
  is_enable: false
}, {
  id: 15,
  state: "Mizoram",
  is_enable: false
}, {
  id: 16,
  state: "Andaman and Nicobar Islands",
  is_enable: false
}, {
  id: 17,
  state: "Goa",
  is_enable: false
}, {
  id: 18,
  state: "Ladakh",
  is_enable: false
}, {
  id: 19,
  state: "Odisha",
  is_enable: false
}, {
  id: 20,
  state: "West Bengal",
  is_enable: false
}, {
  id: 21,
  state: "Maharashtra",
  is_enable: false
}, {
  id: 22,
  state: "Kerala",
  is_enable: false
}, {
  id: 23,
  state: "Chandigarh",
  is_enable: false
}, {
  id: 24,
  state: "Lakshadweep",
  is_enable: false
}, {
  id: 25,
  state: "Sikkim",
  is_enable: false
}, {
  id: 26,
  state: "Meghalaya",
  is_enable: false
}, {
  id: 27,
  state: "Punjab",
  is_enable: false
}, {
  id: 28,
  state: "Rajasthan",
  is_enable: false
}, {
  id: 29,
  state: "Tripura",
  is_enable: false
}, {
  id: 30,
  state: "Uttar Pradesh",
  is_enable: false
}, {
  id: 31,
  state: "Jharkhand",
  is_enable: false
}, {
  id: 32,
  state: "Haryana",
  is_enable: false
}, {
  id: 33,
  state: "Himachal Pradesh",
  is_enable: false
}, {
  id: 34,
  state: "Uttarakhand",
  is_enable: false
}, {
  id: 35,
  state: "Arunachal Pradesh",
  is_enable: false
}, {
  id: 36,
  state: "Manipur",
  is_enable: false
}, {
  id: 37,
  state: "Nagaland",
  is_enable: false
}];
export var years = Array.from(Array(new Date().getFullYear() - 1949), function (_, i) {
  return (i + 1950).toString();
}).map(function (yr) {
  return {
    label: yr,
    value: yr
  };
}).reverse();
export var FUEL_TYPE = [{
  "label": "Petroleum",
  "value": 1
}, {
  "label": "CNG",
  "value": 2
}, {
  "label": "EV",
  "value": 3
}, {
  "label": "Diesel",
  "value": 4
}];
export var DISTRIBUTION_TYPE_DELAY_PENALTY_DEFINE_SLAB = [{
  distribution_type_id: 1,
  distribution_type: "VENDOR_TO_DC"
}, {
  distribution_type_id: 2,
  distribution_type: "DC_TO_STORE"
}, {
  distribution_type_id: 3,
  distribution_type: "STORE_TO_STORE"
}, {
  distribution_type_id: 4,
  distribution_type: "DC_TO_DC"
}, {
  distribution_type_id: 5,
  distribution_type: "STORE_TO_VENDOR"
}, {
  distribution_type_id: 6,
  distribution_type: "DC_TO_VENDOR"
}, {
  distribution_type_id: 7,
  distribution_type: "STORE_TO_DC"
}];
export var SERVICE_TYPE_DELAY_PENALTY_DEFINE_SLAB = [{
  service_type_id: 1,
  service_type: "MILKRUN"
}, {
  service_type_id: 2,
  service_type: "DED"
}, {
  service_type_id: 3,
  service_type: "LCL"
}];
export var ACTION_REQUIRED_OPTIONS = [{
  label: "ADD - COMPLETE SECTION 2 THROUGH TO 5 WHERE INFORMATION IS APPLICABLE",
  value: "ADD - COMPLETE SECTION 2 THROUGH TO 5 WHERE INFORMATION IS APPLICABLE"
}, {
  label: "CHANGE - COMPLETE SECTION 1 AND RELEVANT CHANGES",
  value: "CHANGE - COMPLETE SECTION 1 AND RELEVANT CHANGES"
}, {
  label: "INACTIVATE - COMPLETE SECTION 1 ONLY",
  value: "INACTIVATE - COMPLETE SECTION 1 ONLY"
}];
export var DISTRIBUTION_TYPES = [{
  distribution_type: 'Primary(Vendors to DC)',
  distribution_type_id: 1
}, {
  distribution_type: 'Secondary(DC to stores)',
  distribution_type_id: 2
}, {
  distribution_type: 'Store to store',
  distribution_type_id: 3
}, {
  distribution_type: 'Store to DC',
  distribution_type_id: 4
}, {
  distribution_type: 'DC to DC',
  distribution_type_id: 5
}];
export function isSapDeepakPhenolics(seekerCompany) {
  return SAP_DEEPAK_PHENOLICS.indexOf(seekerCompany) > -1;
}
export function getEmail() {
  var email = JSON.parse(getCookie("user")).email;
  return email;
}
export function getCompanyType() {
  return (JSON.parse(getCookie("user")) || {}).company_type;
}

// export const getEpochFromDate=(date)=> {
//    const dateObj = new Date(date);
//    return Math.floor(dateObj.getTime());

//  }

export var getEpochFromDate = function getEpochFromDate(date) {
  var dateObj = new Date(date);
  dateObj.setHours(1, 0, 0, 0);
  return Math.floor(dateObj.getTime());
};
export var getEpochToDate = function getEpochToDate(date) {
  var dateObj = new Date(date);
  dateObj.setHours(23, 0, 0, 0);
  return Math.floor(dateObj.getTime());
};
export var getOneMonthBeforeDate = function getOneMonthBeforeDate(date) {
  var oneMonthBefore = new Date(date);
  oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
  return oneMonthBefore;
};
export var downloadFile = function downloadFile(fileUrl) {
  var link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
export var getToken = function getToken() {
  var data = getCookie('user') ? getCookie('user') : "";
  var token = data ? JSON.parse(data).token : "";
  return token;
};
export var convertEpochToNormalDate = function convertEpochToNormalDate(epochMilliseconds) {
  var date = new Date(epochMilliseconds);
  var day = date.getDate();
  var month = date.getMonth() + 1;
  var year = date.getFullYear();
  var formattedDay = day < 10 ? "0".concat(day) : day;
  var formattedMonth = month < 10 ? "0".concat(month) : month;
  return "".concat(formattedDay, "/").concat(formattedMonth, "/").concat(year);
};
export var isSuperAdmin = function isSuperAdmin() {
  var userInfo = JSON.parse(getCookie('user'));
  if (SUPER_ADMIN === userInfo.user_type) {
    return true;
  }
  return false;
};
export var isAdmin = function isAdmin() {
  var userInfo = JSON.parse(getCookie('user'));
  if (userInfo.is_admin) {
    return true;
  }
  return false;
};