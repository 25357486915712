import React, { Component } from "react";
import "./home-navigation-panel.less";
import BasicDetails from "../basic_details/basic_details";
import PlantDepots from "../plants-depots/plants-depots-list/plants-depots-list";
import ACTIONS from "../../common/action_constant";
import { connect } from "react-redux";
import history from "../../history";
import phoneImg from "../../../assets/images/call.png";
import WebImg from "../../../assets/images/web.png";
import CheckBox from "../../components/checkbox/checkbox";
import DepartmentRoles from "../company-details/department-roles/department-roles";
import Loader from "../../common/components/loader/loader";
import MSGPopUp from "../../common/components/msg-popup/msg-popup";
import { isCompanyAdmin, COMPANY_ADMIN, TTBIL_COMPANY_IDS, GREENPLY_COMPANY_IDS, CARGILL_COMPANY_IDS, LR_FOR_PANTALOON_COMPANY_ID, BEAM_COMPANY_IDS, getEmail, AWL_COMPANY_IDS, isAWL,
   isAdmin 
  } from "../../../utils/common.js";
import ContractManagement from "../contracts_management/contracts_management";
import { getCookie } from "../../../utils/cookies";
import LocationNetwork from "../location_network/location_network";
import Reconciliation from "../reconciliation/reconciliation";
import ShowConfigures from "./configure/navigation-configure";
import IndentManagement from "../indent-management/indent-reasons";
import LrManagement from "../lr-management/lr-mngnt";
import ModuleConfig from "../module_config/module_config";
import CompanyCategory from "../company_category/company_category";
import RouteCode from "../route_code/route_code";
import Button from "../button-classic/button-classic";
import AddCompany from "../add-company/add-company";
import MaterialConversion from "../material_conversion/material_conversion";
import Scheduler from "../scheduler/scheduler";
import WarehouseParticulars from "../warehouse_particulars/warehouse_particulars";
import VendorMappingList from '../vendor-mapping/VendorMappingList';
import MaterialDetails from '../material-details/material-details';
import RouteMaster from '../route-master/route-master';
import DisposalMaterial from "../disposal-material/disposal-material";
import ContractTenureConfig from "../contract-tenure-config/contract-tenure-config";
import MaterialMRP from '../material-mrp/material-mrp.js'
import Cx from 'classnames';
import MaterialDamage from "../material-details/material-damage/material-damage.js";
import BillManagement from '../bill_management/index.jsx';
import VendorLaneMapping from "../vendor-lane-mapping/vendor-lane-mapping.js";
import { isValueThere } from "../../../utils/index.js";
import CompanyUserInfo from "../companyUserInfo/CompanyUserInfo.jsx";
import Emudhra from "../add-emudhra/add-emudhra.js";

class HomeNavigationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigationOptions: isAWL()?[
        {
          heading: "Basic Details",
          id: "1"
        },
        {
          heading: "Roles/Departments",
          id: "2"
        },
        {
          heading: "Plants/Depots",
          id: "3"
        },
        {
          heading: "Contracts Mgmt",
          id: "4"
        },
        {
          heading: "Indent Mgmt",
          id: "5"
        },
        {
          heading: "Reconciliation",
          id: "8"
        },
        {
          heading: "Location Network",
          id: "9"
        },
        {
          heading: "Vendor",
          id: "10"
        },
        {
          heading: "Vendor Mapping",
          id: "11"
        },
        {
          heading: "Configuration",
          id: "12"
        },
        {
          heading: "Route Code",
          id: "14"
        },
        {
          heading: "Material Conversion",
          id: "15"
        },
        {
          heading: "Material Details",
          id: "18"
        },
        {
          heading: "Disposal Material",
          id: "20"
        },
      ]: [
        {
          heading: "Basic Details",
          id: "1"
        },
        {
          heading: "Roles/Departments",
          id: "2"
        },
        {
          heading: "Plants/Depots",
          id: "3"
        },
        {
          heading: "Contracts Mgmt",
          id: "4"
        },
        {
          heading: "Indent Mgmt",
          id: "5"
        },
        (LR_FOR_PANTALOON_COMPANY_ID.includes(this.props.id) ?
          {
            heading: "LR Mgmt",
            id: "6"
          }
          :
          {

          }
        ),
        {
          heading: "Tracking",
          id: "7"
        },
        {
          heading: "Reconciliation",
          id: "8"
        },
        {
          heading: "Location Network",
          id: "9"
        },
        {
          heading: "Vendor",
          id: "10"
        },
        {
          heading: "Vendor Mapping",
          id: "11"
        },
        // {
        //   heading : "Operational reach",
        //   id : "10"
        // },
        // {
        //   heading : "Service range",
        //   id : "11"
        // },
        {
          heading: "Configuration",
          id: "12"
        },
        {
          heading: "Route Code",
          id: "14"
        },
        {
          heading: "Material Conversion",
          id: "15"
        },
        {
          heading: "Scheduler",
          id: "16"
        },
        {
          heading: "Warehouse",
          id: "17"
        },
        {
          heading: "Material Details",
          id: "18"
        },
        {
          heading: "Route Master",
          id: "19"
        }, {
          heading: "Disposal Material",
          id: "20"
        },
        {
          heading: 'Contract Tenure Config',
          id: "21"
        },
        {
          heading: 'Vendor Lane Mapping',
          id: "22"
        }
      ],
      navigationProviderOptions: [
        {
          heading: "Basic Details",
          id: "1"
        },
        {
          heading: "Roles/Departments",
          id: "2"
        },
        {
          heading: "Branches",
          id: "3"
        },
     
        {
          heading: "LR Mgmt",
          id: "6"
        },
        {
          heading: "Bill Mgmt",
          id: "7"
        },
        {
          heading: "Emudhra config",
          id: "23"
        }
  
        // {
        //   heading: "Contracts Mgmt",
        //   id: "4"
        // },
        // {
        //   heading: "Indent Mgmt",
        //   id: "5"
        // },
        // {
        //   heading: "Tracking",
        //   id: "7"
        // },
        // {
        //   heading: "Reconciliation",
        //   id: "8"
        // },
        // {
        //   heading: "Location Network",
        //   id: "9"
        // },
        // {
        //   heading : "Vendor",
        //   id : "10"
        // },
        // // {
        // //   heading : "Operational reach",
        // //   id : "10"
        // // },
        // // {
        // //   heading : "Service range",
        // //   id : "11"
        // // },
        // {
        //   heading : "Configuration",
        //   id : "12"
        // },
        // {
        //   heading : "Route Code",
        //   id : "14"
        // },

      ],
      isupdated: true,
      selectedOption: this.props.tab,
      redirectToOption: this.props.tab,
      isStateEditedInForms: false,
      allDropdownTypes: [],
      intra_typecodeOptionsList:[],
      inter_typecodeOptionsList:[],
      enableIntraDropdown:false,
      enableInterDropdown:false,
      intra_po_taxcode:null,
      inter_po_taxcode:null,
    };
  }

  componentWillMount() {
    if (!this.props.isCmpyAdmin) {
      const { navigationOptions, navigationProviderOptions } = this.state;
      navigationOptions.push({
        heading: 'Module Config',
        id: "13"
      });
      navigationProviderOptions.push({
        heading: 'Module Config',
        id: "13"
      })
      this.setState({ navigationOptions, navigationProviderOptions });
    }
  }

  setOption = (option) => {
    if (!this.state.isStateEditedInForms) {
      history.replace("/home/" + this.props.id + "/" + this.props.type + "?tab=" + option);
      this.setState({
        selectedOption: option
      });
    }
    this.setState({
      redirectToOption: option
    });
    // this.props.selectedTab(option);
    if(option !== 12 && sessionStorage.getItem('selectedConfigurationTab')){
      sessionStorage.removeItem('selectedConfigurationTab');
    }
  };

  getBasicDetails = () => {
    const { dispatch } = this.props;
    let data = "";
    if (!this.props.id && isCompanyAdmin()) {
      const userInfo = JSON.parse(getCookie('user'));
      const cmpId = (this.props.id) ? this.props.id : userInfo.id;
      data = "?companyId=" + cmpId + "&companyType=" + this.props.type;
    } else {
      data = "?companyId=" + this.props.id + "&companyType=" + this.props.type;
    }
    dispatch({ type: ACTIONS.HOME.GET_BASIC_DETAILS, data,
      onSuccess: ((resp) => {
        if(isValueThere(resp.intra_po_taxcode)){

           this.setState((prevState) => {
            const updatedState = { ...prevState }
            updatedState.intra_po_taxcode=resp.intra_po_taxcode;
            return updatedState
          });
        }
        if(isValueThere(resp.inter_po_taxcode)){

           this.setState((prevState) => {
            const updatedState = { ...prevState }
            updatedState.inter_po_taxcode=resp.inter_po_taxcode;
            return updatedState
          });
        }
      })
     });
  };


fetchTaxCodeOptions=()=>{
  const{dispatch}=this.props;
  dispatch({
    type: ACTIONS.HOME.FETCH_TAX_CODES,
    onSuccess: (data) => {
      if(!data.data.inter_type || !data.data.intra_type){
        return
      }
      const tempData_inter_type = data.data.inter_type;
      const tempData_intra_type=data.data.intra_type;

				if (data && tempData_intra_type.length > 0) {
					let intra_typeCodeOptions = tempData_intra_type.map(obj => {
						return {
							...obj,
							label: obj.taxcode_name,
							value: obj.id
						};
					});
        this.setState((prevState) => {
					const updatedState = { ...prevState }
					updatedState.intra_typecodeOptionsList=intra_typeCodeOptions;
					return updatedState
				});
				}

        if (data && tempData_inter_type.length > 0) {
					let inter_typeCodeOptions = tempData_inter_type.map(obj => {
						return {
							...obj,
							label: obj.taxcode_name,
							value: obj.id
						};
					});
        this.setState((prevState) => {
					const updatedState = { ...prevState }
					updatedState.inter_typecodeOptionsList=inter_typeCodeOptions;
					return updatedState
				});
				}
    }
  })
}
handlePOtaxCodeChange=(data , name)=>{
  if(name=='is_intra'){
    this.setState({
      intra_po_taxcode:data
    })
  }
  if(name=='is_inter'){
    this.setState({
      inter_po_taxcode:data
    })
  }
}

  initializeNavigationOptions = () => {
    
    const { navigationOptions } = this.state;
    const option = {
      heading: "Users Info",
      id: "3.1"
    }
    const updatedNavigationOptions = []
    navigationOptions.forEach((ele)=>{
      if(isAdmin() && ele.id === "3"){
        updatedNavigationOptions.push(ele,option)
      }else{
        updatedNavigationOptions.push(ele)
      }
    })

    this.setState((prevState)=>{
      const updatedpState = {...prevState};
      updatedpState.navigationOptions = updatedNavigationOptions;
      return updatedpState
    })
}


  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_ROLES, data: this.props.id });
    dispatch({ type: ACTIONS.HOME.GET_DEPARTMENTS, data: this.props.id });
    dispatch({ type: ACTIONS.HOME.GET_MODE_OF_TRANSPORT });
    this.getActionTypes();
    this.initializeNavigationOptions()
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      selectedOption: newProps.tab,
      redirectToOption: newProps.tab,
    });
  }
  componentUnmount() {
    this.setState({ isStateEditedInForms: false });
  }
  goToHome = () => {
    history.push("/home");
  };
  checkBoxChange = (e, id, type) => {
    e.preventDefault();
    e.stopPropagation()
    const data = {
      company_type: type,
      company_id: id,
      is_active: e.target.checked
    }
    const { dispatch } = this.props;
    let details = Object.assign({}, this.props.home.basicDetails);
    details.is_active = e.target.checked
    dispatch({
      type: ACTIONS.HOME.STORE_BASIC_DETAILS,
      data: details
    });
    dispatch({ type: ACTIONS.HOME.UPDATE_COMPANY_STATUS, data })
  }
  getEstCategory = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_EST_CATEGORY })
  }
  getBusinessSeg = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_BUSINESS_SEG })
  }
  companyImgUpload = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.UPLOAD_COMPANY_IMG, data })
  }

  uploadMultipleFiles = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.UPLOAD_MULTIPLE_FILE,
      data,
      onSuccess: ((data) => {
        this.getBasicDetails();
      })
    })
  }

  deleteFile = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.DELETE_FILE,
      data,
      onSuccess: ((data) => {
        this.getBasicDetails();
      })
    })

  }

  updateBasicDetails = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.STORE_BASIC_DETAILS,
      data: data
    });
    dispatch({ type: ACTIONS.HOME.UPDATE_BASIC_DETAILS, data })
  }
  isFormEdited = (data) => {
    if (this.state.isStateEditedInForms !== data) {
      const { dispatch } = this.props;
      dispatch({ type: ACTIONS.MSG_DISPLAY.IS_FORM_EDITED, data: data });
      this.setState({ isStateEditedInForms: data });
    }
  }

  //providers and users
  getProviderUsers = () => {
    const { dispatch } = this.props;
    const data = {
      id: this.props.id,
      company_type: this.props.type
    }
    dispatch({ type: ACTIONS.HOME.GET_PROVIDER_USERS, data })
  }
  fetchAllSeekers = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.FETCH_ALL_SEEKERS });
  }
  fetchBranches = (id) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.CONTRACTS.GET_BRANCH_LIST, data: id });
  }
  addSeekerUser = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.ADD_PROVIDER_USERS, data });
  }
  editSeeker = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.UPDATE_PROVIDER_USERS, data });
  }

  // department and roles funtions *************
  addDepartment = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.ADD_DEPARTMENT, data });
  }

  deleteDepartment = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.DELETE_DEPARTMENT, data })
  }
  editDepartment = (data) => {
    const { dispatch } = this.props;
    let param = {
      mainData: data,
      comp_id: this.props.id
    }
    dispatch({ type: ACTIONS.HOME.UPDATE_DEPARTMENT, data: param });
  }

  //roles
  addRole = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.ADD_ROLE, data })
  }

  deleteRole = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.DELETE_ROLE, data })
  }

  closeMsgPopup = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.MSG_DISPLAY.HIDE_MSG });
  }
  closeMsgModal = () => {
    const { tab } = this.props;
    this.setState({ redirectToOption: tab });
  }
  handleRedirection = () => {
    history.replace(
      "/home/" + this.props.id + "/" + this.props.type + "?tab=" + this.state.redirectToOption
    );
    this.setState({ selectedOption: this.state.redirectToOption, isStateEditedInForms: false });
  }
  getPlantsDepotsList = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_PLANTS_DEPOTS, data })
  }
  deletePlantDepot = (data, params) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.DELETE_PLANTS_DEPOTS, data, onSuccess: () => this.getPlantsDepotsList(params) })
  }
  getZones = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_ZONES })
  }
  getBranchTypes = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_BRANCH_TYPES })
  }
  setCurrentDepot = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.SET_CURRENT_DEPOT, data })
    dispatch({ type: ACTIONS.PLANTS.SET_TRACK_DETAILS, data })
    if (data.branch_id)
      history.push("/home/" + this.props.id + "/" + this.props.type + "/" + data.branch_id)
    else
      history.push("/home/" + this.props.id + "/" + this.props.type + "/" + "newdepot")
  }
  getMoves = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_INDENTING_MOVES, data });
  }
  getSubMoves = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_INDENTING_SUBMOVES, data });
  }
  getIndentActions = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_INDENTING_ACTIONS, data });
  }
  getIndentOperationTime = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_INDENTING_OPERATION_TIME, data });
  }
  getChecklists = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_INDENTING_CHECKLISTS, data });
  };
  getFields = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_INDENTING_FIELDS, data })
  }
  getActionTypes = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_ACTION_TYPES });
  }
  getMoveName = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_MOVE_NAME });
  }
  // getIndentingReasons = (data) => {
  //   const { dispatch } = this.props;
  //   dispatch({ type: ACTIONS.PLANTS.GET_INDENTING_REASONS, data });
  // }
  getIndentingReasons = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.GET_INDENTING_REASONS, data });
  }
  addAction = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.ADD_ACTION, data: data, param: { companyId: this.props.id } });
  }
  addMove = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.ADD_MOVE, data: data, param: { companyId: this.props.id } });
  }
  addChecklist = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.ADD_CHECKLIST, data: data, param: { companyId: this.props.id } });
  }

  addFields = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.ADD_INDENTING_FIELDS, data: data, param: { companyId: this.props.id } });
  }
  addOperationTime = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.ADD_INDENTING_OPERATION_TIME, data: data, param: { company_id: this.props.id } });
  }
  editOperationTime = (data) => {
    const { dispatch } = this.props;
    data.company_id = this.props.id;
    dispatch({ type: ACTIONS.PLANTS.EDIT_INDENTING_OPERATION_TIME, data: data, param: { company_id: this.props.id } });
  }
  deleteOperationTime = (data) => {
    const { dispatch } = this.props;
    data.company_id = this.props.id;
    dispatch({ type: ACTIONS.PLANTS.DELETE_INDENTING_OPERATION_TIME, data: data, param: { company_id: this.props.id } });
  }
  // addIndentingReasons = (data) => {
  //   const { dispatch } = this.props;
  //   dispatch({ type: ACTIONS.PLANTS.ADD_INDENTING_REASONS, data: data, param: { companyId: this.props.id } });
  // }
  // editIndentingReasons = (data) => {
  //   const { dispatch } = this.props;
  //   dispatch({ type: ACTIONS.PLANTS.EDIT_INDENTING_REASONS, data: data, param: { companyId: this.props.id }});
  // }
  // deleteIndentingReasons = (data) => {
  //   const { dispatch } = this.props;
  //  dispatch({ type: ACTIONS.PLANTS.DELETE_INDENTING_REASONS, data: data, param: { companyId: this.props.id }});
  // }
  addIndentingReasons = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.ADD_INDENTING_REASONS, data: data, param: { companyId: this.props.id } });
  }
  editIndentingReasons = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.PLANTS.EDIT_INDENTING_REASONS, data: data, param: { companyId: this.props.id }});
  }
  deleteIndentingReasons = (data) => {
    const { dispatch } = this.props;
   dispatch({ type: ACTIONS.PLANTS.DELETE_INDENTING_REASONS, data: data, param: { companyId: this.props.id }});
  }

  toggleAdd = () => {
    this.setState({
      showAddComponent: !this.state.showAddComponent, isupdated: true
    })
  }
  toggleAdd2 = () => {
    this.setState({
      showAddComponent2: !this.state.showAddComponent2, isupdated: true
    })
  }
  toggleAdd3 = () => {
    this.setState({
      showAddComponent3: !this.state.showAddComponent3, isupdated: true
    })
  }

  fetchAllStates = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_ALL_STATE });
  }

  getIndentReasons = (type) => {
    const { dispatch } = this.props;
    const param = `${this.props.id}&type=${type}`;
    dispatch({ type: ACTIONS.HOME.GET_INDENT_REASONS, param });
  }

  addIndentReasons = (type, data) => {
    const { dispatch } = this.props;
    const param = `${this.props.id}&type=${type}`;
    dispatch({ type: ACTIONS.HOME.ADD_INDENT_REASONS, param, data });
  }

  updateIndentReasons = (type, data, reasonId) => {
    const { dispatch } = this.props;
    const param = `${this.props.id}&type=${type}`;
    dispatch({ type: ACTIONS.HOME.UPDATE_INDENT_REASONS, param, data, reasonId });
  }

  deleteIndentReasons = (type, reasonId) => {
    const { dispatch } = this.props;
    const param = `${this.props.id}&type=${type}`;
    dispatch({ type: ACTIONS.HOME.DELETE_INDENT_REASONS, param, reasonId });
  }

  getModuleList = () => {
    const { dispatch, id, type } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_MODULES_LIST, company_id: id, company_type: type });
  }

  saveModuleList = (data) => {
    const { dispatch, id, type } = this.props;
    dispatch({ type: ACTIONS.HOME.SAVE_MODULES_LIST, data, company_id: id, company_type: type});
  }

  fetchAllCompanyCategory = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_COMPANY_CATEGORY });
  }

  fetchAllTypes = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_ALL_TYPES });
  }

  fetchAllModes = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_ALL_MODES });
  }

  getCompanyTransporters = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS, companyId: this.props.id, branchId: this.props.id });
  }

  saveCompanyCategoryDetails = (data) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.SAVE_COMPANY_CATEGORY_DETAILS, data,
      onSuccess: ((data) => {
        this.fetchCompanyCategoryDetails();
      })
    })
  }

  fetchCompanyCategoryDetails = () => {
    const { dispatch } = this.props;
    const data = this.props.id;
    dispatch({ type: ACTIONS.HOME.FETCH_COMPANY_CATEGORY, data });
  }

  downloadVendorDetails = () => {
    const { dispatch } = this.props;
    const id = this.props.id;
    if (CARGILL_COMPANY_IDS.includes(id)) {
      return dispatch({
        type: ACTIONS.HOME.FETCH_CARGILL_VENDOR_DETAILS, data: id,
        onSuccess: ((data) => {
          const element = document.createElement('a');
          element.setAttribute('href', data[0].Link);
          element.setAttribute('download', true);
          element.click();
        })
      })
    }
  }

  downloadAllVendorDetails = () => {
    const { dispatch } = this.props;
    const id = this.props.id;
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_VENDOR_DETAILS, data: id,
      onSuccess: ((data) => {
        if(data.link) {
          const element = document.createElement('a');
          element.setAttribute('href', data.link);
          element.setAttribute('download', true);
          element.click();
        }
      })
    })
  }

  DownloadContract = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.VENDORS.DOWNLOAD_CONTRACT_FORM,
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data.link
        });
      })
    })
  }

  fetchVendorMDGDetails = (value) => {
    const { dispatch } = this.props;
    const userInfo = JSON.parse(getCookie('user'));
    const data = `vendor_id=${value.company_id}&company_id=${userInfo.company_id}`;
    dispatch({ type: ACTIONS.HOME.GET_VENDOR_MDG_DETAILS, data })
  }


  getAllDropdownData = () => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.DATA_GOVERNANCE.GET_OTHERCHARGES_DROPDOWNLIST,
      params: '', //`?DropdownType=all`,
      onSuccess: ((data) => {
        if (Object.keys(data).length > 0) {
          this.setState({
            allDropdownTypes: data
          })
        }
      })
    })
  }

  toggelAddCompany = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.TOGGLE_ADD_COMPANY_MODAL })
  }

  submitAddCompany = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.ADD_COMPANY_API, data })
  }

  getRFQTypes = () => {
    const { dispatch } = this.props;
    dispatch({ type: ACTIONS.HOME.GET_RFQ_TYPES });
  }

  getDepartments = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.GET_DEPARTMENTS,
      data: id
    });

  }

  getAllBranch = (id, type) => {
    const { dispatch, } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.GET_ALL_BRANCH,
      data: {
        companyId: id,
        companyType: type
      }
    });

  }

  downloadUserDeatils = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_USER,
      data: id,
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data.link
        });
        this.downloadBtn.click();
      })
    });

  }

  uploadProviderUserFile = (e, id) => {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.UPLOAD_USER,
      params: `type=${1}&companyId=${id}`,
      data: formData,
      onSuccess: ((data) => {
        if (data)
          this.getProviderUsers()
      })
    })
  }

  downloadBranchDeatils = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_BRANCH,
      data: {
        "id": id,
        "type": Number(this.props.type)
      },
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data[0].link
        });
        this.downloadBtn.click();
      })
    });

  }

  downloadSeekerBranchDeatils = (id, withData) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.PLANTS.DOWNLOAD_PLANT_DEPOT,
      params: `companyId=${id}&companyType=${Number(this.props.type)}`,
      withData,
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data[0].Link
        });
        this.downloadBtn.click();
      })
    });

  }

  uploadSeekerBranchFile = (e, id) => {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    const { dispatch } = this.props;
    let type = this.props.type;
    dispatch({
      type: ACTIONS.PLANTS.UPLOAD_PLANT_DETAILS,
      params: `?companyId=${id}&companyType=${Number(type)}&userEmail=${getEmail()}`,
      data: formData,
      onSuccess: ((data) => {
        if (data) {
          this.getAllBranch(id, Number(type))
        }
      })
    })
  }

  uploadProviderBranchFile = (e, id) => {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    const { dispatch } = this.props;
    let type = this.props.type;
    dispatch({
      type: ACTIONS.HOME.UPLOAD_BRANCH,
      params: `companyId=${id}&companyType=${Number(type)}`,
      data: formData,
      onSuccess: ((data) => {
        if (data) {
          this.getAllBranch(id, Number(type))
        }
      })
    })
  }

  downloadProviderDepartmentDeatils = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_DEPARTMENT,
      data: {
        "id": id,
        "type": Number(this.props.type)
      },
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data[0].link
        });
        this.downloadBtn.click();
      })
    });

  }

  uploadProviderDepartmentFile = (e, id) => {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    const { dispatch } = this.props;
    let type = this.props.type;
    dispatch({
      type: ACTIONS.HOME.UPLOAD_DEPARTMENT,
      params: `companyId=${id}&companyType=${Number(type)}`,
      data: formData,
      onSuccess: ((data) => {
        if (data) {
          this.getDepartments(id)
        }
      })
    })
  }

  downloadProviderRolesDeatils = (id) => {
    const { dispatch } = this.props;
    dispatch({
      type: ACTIONS.HOME.DOWNLOAD_ROLES,
      data: {
        "id": id,
        "type": Number(this.props.type)
      },
      onSuccess: ((data) => {
        this.setState({
          downloadLink: data[0].link
        });
        this.downloadBtn.click();
      })
    });

  }

  uploadProviderRolesFile = (e, id) => {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    const { dispatch } = this.props;
    let type = this.props.type;
    dispatch({
      type: ACTIONS.HOME.UPLOAD_ROLES,
      params: `companyId=${id}&companyType=${Number(type)}`,
      data: formData,
      onSuccess: ((data) => {
        if (data) {

        }
      })
    })
  }

  syncChainWithVIMS = (branchId, id) => {
    const { dispatch } = this.props;
    const data = {
      company_id: id,
      branch_ids: branchId
    }
    dispatch({
      type: ACTIONS.HOME.SYNC_CHAIN_WITH_VIMS,
      data
    })
  }

  render() {
    const addCompanyModal = this.props.home.addCompanyModal;
    let company_name = "",
      pan_no = "",
      company_website = "",
      contact = "",
      service_type = [],
      company_type = "",
      is_active = false,
      id = "",
      estCategory = [],
      businessSeg = [],
      isTransporter = false

    if (this.props.home.basicDetails) {
      company_name = this.props.home.basicDetails.company_name;
      id = this.props.id;
      pan_no = this.props.home.basicDetails.pan_no;
      company_website = this.props.home.basicDetails.company_website;
      contact = this.props.home.basicDetails.contact;
      service_type = this.props.home.basicDetails.service_type;
      company_type = this.props.home.basicDetails.company_type == 1 ? "Seeker" : this.props.home.basicDetails.company_type == 2 ? "Provider" : "3PL";
      is_active = this.props.home.basicDetails.is_active;
      isTransporter = this.props.home.basicDetails.is_transporter;
      if (!isTransporter) {
        isTransporter = this.props.home.basicDetails.is_multiService || false;
      }
    }

    if (this.props.home.estCategory) {
      estCategory = this.props.home.estCategory;
    }
    if (this.props.home.businessSeg) {
      businessSeg = this.props.home.businessSeg;
    }
    const isCmpyAdmin = isCompanyAdmin();
    const cardClass = isCmpyAdmin ? 'card-company-admin' : '';
    const enabledList = ['4', '5', '6', '7'];
    const userInfo = JSON.parse(getCookie('user'));

    return (
      <div className={Cx("home-navigation-panel-root", {'pointer-event': userInfo.disable_homepage})}>
        {
          this.props.loader.isLoading && <Loader />
        } 
        {
          this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
        }
        <a className="download-btn" style={{ display: 'none' }} ref={input => this.downloadBtn = input} href={this.state.downloadLink} download />
        {this.props.home.basicDetails && (
          <React.Fragment>
            <div className={'title'}>
              {
                !isCmpyAdmin &&
                <div className="page-title">
                  {" "}
                  <span className="home" onClick={this.goToHome}>
                    Home
                  </span>{" "}
                  {">"} <span className="current-screen">{company_name}</span>
                </div>
              }
              <div className="company-name"> {company_name}</div>
              <div className="add-company-section">

                {
                  addCompanyModal && <AddCompany close={this.toggelAddCompany} submit={this.submitAddCompany} enableList={enabledList} />
                }
                {
                  this.props.loader.isLoading && <Loader />
                }
                {
                  this.props.msgpopup.active && <MSGPopUp close={this.closeMsgPopup} type={this.props.msgpopup.type} msg={this.props.msgpopup.msg} />
                }
                {enabledList && enabledList.length > 0 && enabledList.includes(this.props.type) && <Button value="+ Add Company" click={this.toggelAddCompany} />}
              </div>
            </div>

            <div className={`card-view ${cardClass}`}>
              <div className="company-details">
                <div className="options">
                  <div className="pan-heading">PAN:</div>
                  <div className="pan-no">{pan_no}</div>
                </div>
                <div className="options">
                  <div className="img" style={{ backgroundImage: `url(${phoneImg})` }} ></div>
                  <div className="pan-no">{contact}</div>
                </div>
                {company_website != "" && <div className="options">
                  <div className="img" style={{ backgroundImage: `url(${WebImg})` }} ></div>
                  <div className="pan-no comp-website">{company_website}</div>
                </div>}
              </div>
              <div className="other-details">
                <MoreDetails imp={true} text={company_type} />
                {
                  service_type && (service_type.length > 0) &&
                  service_type.map((type, index) => {
                    return (
                      <MoreDetails key={index} imp={false} text={type.name} />
                    )
                  })
                }
                <CheckBox type={company_type} status={is_active} id={id} handleChange={this.checkBoxChange} />
              </div>
            </div>
          </React.Fragment>
        )}
        {this.props.home.basicDetails && this.props.home.basicDetails.company_type == 1 && <div className="home-navigation-holder">
          {this.state.navigationOptions.map((option, index) => {
            if (option.id > 22 || (TTBIL_COMPANY_IDS.includes(id) && option.id == 15) || (['737'].includes(id) && ['5', '7', '8', '15'].includes(option.id)) || (GREENPLY_COMPANY_IDS.includes(id) && ['16', '17'].includes(option.id)) || ((userInfo || {}).access_depo_disposal && !['1', '3', '20'].includes(option.id))) return;
            return (
              <div
                key={index}
                className={
                  this.state.selectedOption === option.id
                    ? "selected-option options"
                    : "options"
                }
                onClick={() => this.setOption(option.id)}
              >
                {option.heading}
              </div>
            );
          })}
        </div>}

        {this.props.home.basicDetails && this.props.home.basicDetails.company_type > 1 && <div className="home-navigation-holder">
          {this.state.navigationProviderOptions.map((option, index) => {
            // if(option.id > 1 && option.id <= 10) return ;
            // if(!LR_FOR_PANTALOON_COMPANY_ID.includes(this.props.id) && option.id == '6' ){
            //   return null
            // } 
            // else
            // {
              return (
                <div
                  key={index}
                  className={
                    this.state.selectedOption === option.id
                      ? "selected-option options"
                      : "options"
                  }
                  onClick={() => this.setOption(option.id)}
                >
                  {option.heading}
                </div>
              );
            // }
          })}
        </div>}
        {
          this.state.redirectToOption !== this.props.tab && this.state.isStateEditedInForms &&
          <MSGPopUp
            type={1}
            isEditMode={true}
            close={this.closeMsgModal}
            isEditing={this.state.isStateEditedInForms}
            handleRedirection={this.handleRedirection}
            msg={'Are You Sure You Want Leave Without Saving?'}
          />
        }

        {this.state.selectedOption == 1 && (
          <BasicDetails
            getBasicDetails={this.getBasicDetails}
            getBusinessSeg={this.getBusinessSeg}
            getEstCategory={this.getEstCategory}
            updateBasicDetails={this.updateBasicDetails}
            companyImgUpload={this.companyImgUpload}
            details={this.props.home.basicDetails}
            businessSeg={businessSeg}
            estCategory={estCategory}
            dispatch={this.props.dispatch}
            company_type={this.props.home.basicDetails && this.props.home.basicDetails.company_type}
            getProviderUsers={this.getProviderUsers}
            providersUsersList={this.props.home.providersUsers}
            fetchAllSeekers={this.fetchAllSeekers}
            fetchBranches={this.fetchBranches}
            branchList={this.props.contracts.branchList}
            seekersList={this.props.home.seekers}
            addSeekerUser={this.addSeekerUser}
            editSeeker={this.editSeeker}
            isFormEdited={this.isFormEdited}
            fetchAllStates={this.fetchAllStates}
            stateList={this.props.home.states}
            uploadMultipleFiles={this.uploadMultipleFiles}
            deleteFile={this.deleteFile}
            fetchAllCompanyCategory={this.fetchAllCompanyCategory}
            companyCategory={this.props.home.companyCategory}
            getDepartments={this.getDepartments}
            departments={this.props.home.departments}
            getAllBranch={this.getAllBranch}
            allPlantBranches={this.props.plantsDepots.allPlantBranches}
            downloadUserDeatils={this.downloadUserDeatils}
            uploadProviderUserFile={this.uploadProviderUserFile}
            isTransporter={isTransporter}
            modeOfTransportList={this.props.modeOfTransportList}
            companyId={this.props.id}
            groupNames={this.props.groupNames}
            fetchTaxCodeOptions={this.fetchTaxCodeOptions}
            inter_typecodeOptionsList={this.state.inter_typecodeOptionsList}
            intra_typecodeOptionsList={this.state.intra_typecodeOptionsList}
            inter_po_taxcode={this.state.inter_po_taxcode}
            intra_po_taxcode={this.state.intra_po_taxcode}
            handlePOtaxCodeChange={this.handlePOtaxCodeChange}
          />
        )}
        {this.state.selectedOption == 2 &&
          <DepartmentRoles
            editDepartment={this.editDepartment}
            addRole={this.addRole}
            deleteRole={this.deleteRole}
            deleteDepartment={this.deleteDepartment}
            addDepartment={this.addDepartment}
            roles={this.props.home.roles}
            departments={this.props.home.departments}
            companyDetails={this.props.home.basicDetails}
            companyType={this.props.type}
            downloadProviderDepartmentDeatils={this.downloadProviderDepartmentDeatils}
            companyId={this.props.id}
            uploadProviderDepartmentFile={this.uploadProviderDepartmentFile}
            downloadProviderRolesDeatils={this.downloadProviderRolesDeatils}
            uploadProviderRolesFile={this.uploadProviderRolesFile}
          />}
        {this.state.selectedOption == 3 &&
          <PlantDepots
            setCurrentDepot={this.setCurrentDepot}
            plantsDepots={this.props.plantsDepots}
            companyType={this.props.type}
            companyId={this.props.id}
            getZones={this.getZones}
            getBranchTypes={this.getBranchTypes}
            getPlantsDepotsList={this.getPlantsDepotsList}
            downloadBranchDeatils={this.downloadBranchDeatils}
            uploadProviderBranchFile={this.uploadProviderBranchFile}
            downloadSeekerBranchDeatils={this.downloadSeekerBranchDeatils}
            uploadSeekerBranchFile={this.uploadSeekerBranchFile}
            deletePlantDepot={this.deletePlantDepot}
            syncChainWithVIMS={this.syncChainWithVIMS}
          />}
        {this.state.selectedOption === "3.1" &&
          <CompanyUserInfo />
        }
        {this.state.selectedOption == 4 &&
          <ContractManagement
            company_id={this.props.id}
            branch_id={this.props.id}
            isFormEdited={this.isFormEdited}
          />}
        {this.state.selectedOption == 9 &&
          <LocationNetwork
            company_id={this.props.id}
            syncChainWithVIMS={this.syncChainWithVIMS}
          />
        }
        {this.state.selectedOption == 8 &&
          <Reconciliation company_id={this.props.id} />
        }
        {this.state.selectedOption == 12 && (
          <ShowConfigures
            getMoves={this.getMoves}
            getSubMoves={this.getSubMoves}
            addMove={this.addMove}
            indentingMoves={this.props.indentingMoves}
            indentingSubMoves={this.props.indentingSubMoves}
            getActions={this.getIndentActions}
            addAction={this.addAction}
            indentingActions={this.props.indentingActions}
            getChecklists={this.getChecklists}
            addChecklist={this.addChecklist}
            getFields={this.getFields}
            indentingChecklists={this.props.indentingChecklists}
            company_id={this.props.id}
            actiontypeslist={this.props.actiontypeslist}
            indentingFields={this.props.indentingFields}
            addFields={this.addFields}
            getIndentOperationTime={this.getIndentOperationTime}
            indentingOperationTime={this.props.indentingOperationTime}
            addOperationTime={this.addOperationTime}
            editOperationTime={this.editOperationTime}
            deleteOperationTime={this.deleteOperationTime}
            moveNamesList={this.props.moveNamesList}
            getMoveName={this.getMoveName}
            getIndentingReasons={this.getIndentingReasons}
            addIndentingReasons={this.addIndentingReasons}
            editIndentingReasons={this.editIndentingReasons}
            deleteIndentingReasons={this.deleteIndentingReasons}
            indentingReasons={this.props.indentingReasons}
          />
        )}
        {this.state.selectedOption == 5 &&
          <IndentManagement
            addIndentReasons={this.addIndentReasons}
            getIndentReasons={this.getIndentReasons}
            updateIndentReasons={this.updateIndentReasons}
            deleteIndentReasons={this.deleteIndentReasons}
            indentReasons={this.props.indentReasons}
            companyId={this.props.id}
          />}
        {
          this.state.selectedOption == 6 &&
          <LrManagement
            dispatch={this.props.dispatch}
            companyId={this.props.home && this.props.home.basicDetails && this.props.home.basicDetails.company_id}
            basicDetails={this.props.home.basicDetails}
            companyType = {this.props.type}
            getBasicDetails={this.getBasicDetails}
          />
        }
        {this.state.selectedOption == 13 &&
          <ModuleConfig
            getModuleList={this.getModuleList}
            saveModuleList={this.saveModuleList}
            moduleList={this.props.moduleList}
            getRFQTypes={this.getRFQTypes}
            rfqTypes={this.props.rfqTypes}
          />
        }
        {this.state.selectedOption == 10 &&
          <CompanyCategory
            fetchAllCompanyCategory={this.fetchAllCompanyCategory}
            companyCategory={this.props.home.companyCategory}
            companyTransporters={this.props.master.companyTransporters}
            getCompanyTransporters={this.getCompanyTransporters}
            saveCompanyCategoryDetails={this.saveCompanyCategoryDetails}
            basicDetails={this.props.home.basicDetails}
            fetchCompanyCategoryDetails={this.fetchCompanyCategoryDetails}
            downloadVendorDetails={this.downloadVendorDetails}
            companyCategoryDetails={this.props.home.companyCategoryDetails}
            fetchAllTypes={this.fetchAllTypes}
            typesArray={this.props.home.types}
            fetchAllModes={this.fetchAllModes}
            modesArray={this.props.home.modes}
            fetchVendorMDGDetails={this.fetchVendorMDGDetails}
            vendorMDGDetails={this.props.vendorMDGDetails}
            getAllDropdownData={this.getAllDropdownData}
            allDropdownTypes={this.state.allDropdownTypes}
            DownloadContract={this.DownloadContract}
            company_id={this.props.id}
            downloadAllVendorDetails={this.downloadAllVendorDetails}
          />
        }
        {
          this.state.selectedOption == 14 &&
          <RouteCode
            company_id={this.props.id}
          />
        }
        {
          !TTBIL_COMPANY_IDS.includes(id) && this.state.selectedOption == 15 &&
          <MaterialConversion
            company_id={this.props.id}
          />
        }
        {
          TTBIL_COMPANY_IDS.includes(id) && this.state.selectedOption == 16 &&
          <Scheduler
            company_id={this.props.id}
          />
        }
        {this.state.selectedOption == 17 &&
          <WarehouseParticulars
            companyId={this.props.id}
          />
        }
        {this.state.selectedOption == 11 &&
          <VendorMappingList
            companyId={this.props.id}
          />
        }
        {
          [...GREENPLY_COMPANY_IDS, ...BEAM_COMPANY_IDS].includes(id) && this.state.selectedOption == 18 &&
          <MaterialDetails
            company_id={this.props.id}
          />
        }
        {[...AWL_COMPANY_IDS].includes(id) && this.state.selectedOption == 18 &&
          // <MaterialMRP 
          //   company_id={this.props.company_id}
          // />
          <MaterialDamage
          company_id={this.props.id}
          />
        }
        {this.state.selectedOption == 19 &&
          <RouteMaster
            company_id={this.props.id}
          />
        }
        {this.state.selectedOption == 20 &&
          <DisposalMaterial
            company_id={this.props.id}
            branch_id={this.props.id}
          />
        }
        {
          this.state.selectedOption == 21 &&
          <ContractTenureConfig
            company_id={this.props.id}
          />
        }
        {
          this.state.selectedOption == 7 &&
          <BillManagement
            dispatch={this.props.dispatch}
            companyId={this.props.home && this.props.home.basicDetails && this.props.home.basicDetails.company_id}
            basicDetails={this.props.home.basicDetails}
            companyType = {this.props.type}
            getBasicDetails={this.getBasicDetails}
            company_id={this.props.id}
            company_name = {this.props.home && this.props.home.basicDetails && this.props.home.basicDetails.company_name}
          />
        }
        { 
           this.state.selectedOption == 22 &&
          <VendorLaneMapping
          companyId={this.props.id}
          />
        }
        {
          this.state.selectedOption == 23 &&
          <Emudhra
          companyId={this.props.id}
          />
        } 
        {/* {this.state.selectedOption == 123 && (
              <div className = "moves">
                  <div className = "search-holder">
                      <input class="button-classic" type="button" value="+ New move" onClick = {this.toggleAdd} />
                  </div>
                  <div className = "">
                      <h1>Moves</h1>
                  </div>
                  <div className = "heading">
                      <div className ="depot-users-list depot-users-list-firs" >
                          <div className="halflist">
                              <div className = "head usn first">Id</div>
                              <div className = "head usn second">Name</div>
                          </div> 
                      </div>
                      <div className ="depot-users-list depot-users-list-secon" >
                          <div className="halflist">
                              <div className = "head usn first">Id</div>
                              <div className = "head usn second">Name</div>
                          </div> 
                      </div>
                  </div>
                  { this.props.indentingMoves && this.props.indentingMoves.length == 0 &&
                      <div className = "depot-users-list no-records"> That is all, nothing to display!
                      </div>
                  }
                  
                  {
                      this.props.indentingMoves && this.props.indentingMoves.map((user,index) => {
                          if(index%2==0){
                              return (
                                  <div className ="depot-users-list depot-users-list-firs" >
                                      <div className="halflist">
                                          <div className = "head usn first">{user.move_id}</div>
                                          <div className = "head usn second">{user.name}</div>
                                      </div> 
                                  </div>                            
                              )
                          }else{
                              return (
                                  <div className ="depot-users-list depot-users-list-secon" >
                                      <div className="halflist">
                                          <div className = "head usn first">{user.move_id}</div>
                                          <div className = "head usn second">{user.name}</div>
                                      </div> 
                                  </div>                            
                              )
                          }
                      })
                  }
                  {
                      this.state.showAddComponent && 
                      <AddMove  
                          toggleAdd = {this.toggleAdd}
                          company_id = {this.props.id}
                          addMove = {this.addMove}
                      />
                  }
              </div>
          )}
          {this.state.selectedOption == 13 && (
              <div className = "actions">
                  <div className = "search-holder">
                      <input class="button-classic" type="button" value="+ New action" onClick = {this.toggleAdd2} />
                  </div>
                  <div className = "">
                      <h1>Actions</h1>
                  </div>
                  <div className = "heading">
                      <div className ="depot-users-list depot-users-list-firs" >
                          <div className="halflist">
                              <div className = "head usn first">Id</div>
                              <div className = "head usn second">Name</div>
                          </div> 
                      </div>
                      <div className ="depot-users-list depot-users-list-secon" >
                          <div className="halflist">
                              <div className = "head usn first">Id</div>
                              <div className = "head usn second">Name</div>
                          </div> 
                      </div>
                  </div>
                  { this.props.indentingActions && this.props.indentingActions.length == 0 &&
                      <div className = "depot-users-list no-records"> That is all, nothing to display!
                      </div>
                  }
                  {
                  this.props.indentingActions && this.props.indentingActions.map((user,index) => {
                          if(index%2==0){
                              return (
                                  <div className ="depot-users-list depot-users-list-firs" >
                                      <div className="halflist">
                                          <div className = "head usn first">{user.action_id}</div>
                                          <div className = "head usn second">{user.name}</div>
                                      </div> 
                                  </div>                            
                              )
                          }else{
                              return (
                                  <div className ="depot-users-list depot-users-list-secon" >
                                      <div className="halflist">
                                          <div className = "head usn first">{user.action_id}</div>
                                          <div className = "head usn second">{user.name}</div>
                                      </div> 
                                  </div>                            
                              )
                          }
                      })
                  }
                  {
                      this.state.showAddComponent2 && 
                      <AddAction  
                          toggleAdd2 = {this.toggleAdd2}
                          company_id = {this.props.id}
                          addAction = {this.addAction}
                      />
                  }
              </div>
          )}
          {this.state.selectedOption == 14 && (
              <div className = "checklists">
                  <div className = "search-holder">
                      <input class="button-classic" type="button" value="+ New checklist" onClick = {this.toggleAdd3} />
                  </div>
                  <div className = "">
                      <h1>Checklists</h1>
                  </div>
                  <div className = "heading">
                      <div className ="depot-users-list depot-users-list-firs" >
                          <div className="halflist">
                              <div className = "head usn first">Id</div>
                              <div className = "head usn second">Name</div>
                          </div> 
                      </div>
                      <div className ="depot-users-list depot-users-list-secon" >
                          <div className="halflist">
                              <div className = "head usn first">Id</div>
                              <div className = "head usn second">Name</div>
                          </div> 
                      </div>
                  </div>
                  { this.props.indentingChecklists && this.props.indentingChecklists.length == 0 &&
                      <div className = "depot-users-list no-records"> That is all, nothing to display!
                      </div>
                  }
                  {
                  this.props.indentingChecklists && this.props.indentingChecklists.map((user,index) => {
                      if(index%2==0){
                          return (
                              <div className ="depot-users-list depot-users-list-firs" >
                                  <div className="halflist">
                                      <div className = "head usn first">{user.checklist_id}</div>
                                      <div className = "head usn second">{user.name}</div>
                                  </div> 
                              </div>                            
                          )
                      }else{
                          return (
                              <div className ="depot-users-list depot-users-list-secon" >
                                  <div className="halflist">
                                      <div className = "head usn first">{user.checklist_id}</div>
                                      <div className = "head usn second">{user.name}</div>
                                  </div> 
                              </div>                            
                          )
                      }
                      })
                  }
                  {
                      this.state.showAddComponent3 && 
                      <AddChecklist  
                          toggleAdd3 = {this.toggleAdd3}
                          company_id = {this.props.id}
                          addChecklist = {this.addChecklist}
                      />
                  }
              </div>
          )} */}
      </div>
    );
  }
}


const MoreDetails = (props) => {
  return (
    <div className={props.imp ? "more-details-wrap imp" : "more-details-wrap"}>
      {props.text}
    </div>
  );
};


const mapStateToProps = state => {
  return {
    home: state.home,
    loader: state.loader,
    msgpopup: state.msgpopup,
    plantsDepots: state.plantsDepots,
    contracts: state.contracts,
    indentingChecklists: state.plantsDepots.indentingChecklists,
    indentingMoves: state.plantsDepots.indentingMoves,
    // volumetricMaster: state.plantsDepots.volumetricMaster,
    indentingSubMoves: state.plantsDepots.indentingSubMoves,
    indentingActions: state.plantsDepots.indentingActions,
    indentingFields: state.plantsDepots.indentingFields,
    indentingOperationTime: state.plantsDepots.indentingOperationTime,
    actiontypeslist: state.plantsDepots.actiontypeslist,
    indentReasons: state.home.indentReasons,
    moduleList: state.home.moduleList,
    master: state.master,
    vendorMDGDetails: state.home.vendorMDGDetails,
    rfqTypes: state.home.rfqTypes || [],
    modeOfTransportList: state.home.modeOfTransport || [],
    moveNamesList: state.plantsDepots.moveNamesList,
    groupNames: state.home.groupNames || [],
    indentingReasons: state.plantsDepots.indentingReasons || []
  };
};

export default connect(mapStateToProps)(HomeNavigationPanel);
