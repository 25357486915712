import React, { Component, Fragment } from "react";
import "./LoginFormCard.scss";
import Logo from "../Logo/index";
// import Tabs from "../Tabs/index";
import { ReactComponent as PasswordVisible } from "./icons/passwordvisible.svg";
import { ReactComponent as PasswordNotVisible } from "./icons/passwordNotVisible.svg";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
import { ToastContainer, toast } from "react-toastify";

class LoginFormCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailFocused: false,
      isPasswordFocused: false
    };
  }

  render() {
    const {
      isPasswordVisible,
      togglePasswordVisibility,
      handlePasswordChange,
      password,
      toggleLoginWithSso,
      email,
      handleEmailChange,
      onSubmit,
      signin
    } = this.props;
    return (
      <div>
        {/* <form onSubmit={onSubmit}> */}
          {/* <Rectangle className={"rectangleTopL"} /> */}
          {/* <Rectangle className={"rectangleTopS"} /> */}
          <div className="loginFormCardMain">
            <ToastContainer />
            <div className="loginFormCard">
              <div className="loginFormLogo">
                <Logo className="loginFormLogoImage" />
              </div>
              <div className="signInLableBox">
                <p className="signInLable">Sign In With Your Credentials Or To Register Contact info@caliperbusiness.com</p>
              </div>
              <div className="loginFormEmailMain">
                <p className="loginFormEmailLable">Email</p>
                <div className={`loginFormPasswordBox ${this.state.isEmailFocused ? 'box-focused' : ''}`}>
                  <input
                    type="text"
                    name="email"
                    className="loginFormEmailInput"
                    value={email}
                    onChange={handleEmailChange}
                    onFocus={() => this.setState({ isEmailFocused: true })}
                    onBlur={() => this.setState({ isEmailFocused: false })}
                  >
                  </input>
                </div>
              </div>
              <div className="loginFormPasswordMain">
                <p className="loginFormPasswordLable">Password</p>
                <div className={`loginFormPasswordBox ${this.state.isPasswordFocused ? 'box-focused' : ''}`}>
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    value={password}
                    onChange={handlePasswordChange}
                    className="loginFormPasswordInput"
                    name="password"
                    maxLength={16}
                    onFocus={() => this.setState({ isPasswordFocused: true })}
                    onBlur={() => this.setState({ isPasswordFocused: false })}
                  ></input>
                  <div
                    className="loginFormPasswordIcon"
                    // onClick={togglePasswordVisibility}
                    onClick={() => {
                    togglePasswordVisibility();
                    this.setState({ isPasswordFocused: true })
                    }}
                  >
                    {isPasswordVisible ? (
                      <PasswordVisible />
                    ) : (
                      <PasswordNotVisible />
                    )}
                  </div>
                </div>
              </div>
              <div className="loginButtonBox">
                <Button 
                Name={"Login"} 
                className={"loginButton"} 
                onClick={signin}
                />
              </div>
              <div className="orLableBox">
                <p className="orLable">Or</p>
              </div>
              <div className="loginWithSsoLableBox">
                <p className="loginWithSso" onClick={toggleLoginWithSso}>
                  Login with SSO
                </p>
              </div>
            </div>
          </div>
          {/* <Rectangle className={"rectangleBottomL"} /> */}
          {/* <Rectangle className={"rectangleBottomS"} /> */}
        {/* </form> */}
      </div>
    );
  }
}
export default LoginFormCard;
