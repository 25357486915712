import React, { Component, Fragment } from "react";
import "./LoginWithSso.scss";
import Logo from "../Logo/index";
// import Tabs from "../Tabs/index";
import { ReactComponent as PasswordVisible } from "./icons/passwordvisible.svg";
import { ReactComponent as PasswordNotVisible } from "./icons/passwordNotVisible.svg";
import Button from "./Button";
import Rectangle from "./Rectangle";
import "./Rectangle.scss";
import { ToastContainer, toast } from "react-toastify";
class LoginWithSso extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmailFocused: false,
    };
  }
  render() {
    const {
      toggleLoginWithSso,
      email,
      handleEmailChange,
      validateSSOUser,
      onSubmit
    } = this.props;
    return (
      <Fragment>
        {/* <form onSubmit={(e) => onSubmit(e)}> */}
          {/* <Rectangle className={"otpRectangleTopL"} /> */}
          {/* <Rectangle className={"otpRectangleTopS"} /> */}
          <div className="loginWithSsoFormCardMain">
            <ToastContainer />
            <div className="loginWithSsoFormCard">
              <div className="loginWithSsoFormLogo">
                <Logo className="loginWithSsoLogoImage" />
              </div>
              <div className="signInLableBox">
                <p className="signInLable">Sign In With Your Credentials Or To Register Contact info@caliperbusiness.com</p>
              </div>
              <div className="loginWithSsoFormEmailMain">
                <p className="loginWithSsoFormEmailLable">Email</p>
                <div className={`loginWithSsoFormEmailBox ${this.state.isEmailFocused ? 'box-focused' : ''}`}>
                  <input
                    type="text"
                    name="email"
                    className="loginWithSsoFormEmailInput"
                    value={email}
                    onChange={handleEmailChange}
                    onFocus={() => this.setState({ isEmailFocused: true })}
                    onBlur={() => this.setState({ isEmailFocused: false })}
                  ></input>
                </div>
              </div>

              <div className="loginWithSsoButtonBox">
                <Button
                  Name={"Login with SSO"}
                  className={"loginWithSsoButton"}
                  onClick={validateSSOUser}
                  // type="submit"
                />
              </div>
              <div className="loginWithSsoBackToSignInLableBox">
                <p
                  className="loginWithSsoBackToSignInLable"
                  onClick={toggleLoginWithSso}
                >
                  Back To SignIn
                </p>
              </div>
            </div>
          </div>
          {/* <Rectangle className={"otpRectangleBottomL"} /> */}
          {/* <Rectangle className={"otpRectangleBottomS"} /> */}
        {/* </form> */}
      </Fragment>
    );
  }
}
export default LoginWithSso;
