import { takeEvery, takeLatest, take } from "redux-saga/effects";
import { login, validateSSOLogin, ssoLogin, ssoConfig } from "./SignIn/login.saga";
import {
  getHome,
  addCompany,
  updateCompnayStatus,
  getCompanyBasicDetils,
  getEstCategory,
  getBusinessSeg,
  uploadCompanyimg,
  updateBasicDetails,
  getRoles,
  lspOptions,
  selectedOptions,
  updateThreshold,
  uploadFile,
  downloads,
  getDepartments,
  addRole,
  deleteRole,
  addDepartment,
  updateDepartment,
  deleteDepartment,
  getProviderUsers,
  addProviderUsers,
  updateProviderUsers,
  fetchAllSeekers,
  fetchAllStates,
  getIndentReasons,
  addIndentReasons,
  updateIndentReasons,
  deleteIndentReasons,
  getModuleList,
  saveModuleList,
  uploadMultipleFiles,
  deleteFile,
  fetchAllCompanyCategory,
  saveCompanyCategoryDetails,
  fetchCompanyCategoryDetails,
  fetchVendorDetails,
  fetchCargillVendorDetails,
  fetchAllModesData,
  fetchAllTypesData,
  getRouteCode,
  updateRouteCode,
  addRouteCode,
  getDistance,
  getDistrict,
  getToDistrict,
  getVendorMDGDetils,
  getRFQTypes,
  downloadProviderUserDeatils,
  uploadProviderUserDetails,
  downloadProviderBranchDeatils,
  uploadProviderBranchDetails,
  downloadProviderDepartmentDetails,
  uploadProviderDepartmentDetails,
  downloadProviderRolesDeatils,
  uploadProviderRolesDetails,
  addMaterialConversion,
  getMaterialConversion,
  downloadSapRouteCode,
  uploadSapRouteCodeDetails,
  deleteSapRouteCodeDetails,
  downloadSapRouteCodeDetails,
  getSchedulerCode,
  addSchedulerCode,
  updateSchedulerCode,
  deleteSchedulerDetails,
  downloadSchedulerDetails,
  uploadSchedulerDetails,
  getWarehouseParticulars,
  postWarehouseParticulars,
  getWarehouseTemplateList,
  postWarehouseTemplate,
  deleteMaterialConversionDetails,
  editMaterialConversionDetails,
  downloadMaterialConversionTemplate,
  uploadMaterialConversionDetails,
  getVendorMaterialDetails,
  uploadVendorMaterialDetails,
  downloadVendorMaterialDetails,
  addVendorMaterialDetails,
  updateVendorMaterialDetails,
  deleteVendorMaterialDetails,
  getVendorWithSapCode,
  downloadParticularsConversionTemplate,
  uploadParticularsDetails,
  getMaterialItemDetails,
  getModeOfTransport,
  syncChainWithVIMS,
  getRouteMaster,
  downloadRouteMasterTemplate,
  downloadRouteMasterDetails,
  uploadMasterRouteDetails,
  updateRouteMaster,
	addRouteMaster,
  deleteRouteMaster,
  getDisposalMaterial,
  downloadDisposalMaterialTemplate,
  uploadDisposalMaterial,
  downloadDispsalMaterialDetails,
  getDisposalMaterialDropdowns,
  addDisposalMaterial,
  deleteDisposalMaterial,
  updateDisposalMaterial,
  getContractTenureTypes,
  getContractTenureList,
  saveContractTenureList,
  fetchLRUploadSummary,
  deleteLRUploadDetails,
  downloadLRUploadDetail,
  SendLRtoMoglix,
  uploadLRDetail,
  downloadLSPName,
  setLrRange,
  fetchBranchForLr,
  fetchAllSeekerForLr,
  setLrRangeForProvider,
  getRouteCodeDropdowns,
  downloadIndividualLRDetails,
  getProviderLRDetails,
  getParentChildLocationListing,
  getParentChildViewListing,
  downloadTemplateInUploadLocation,
  uploadFileInUploadLocation,
  deleteFileInUploadLocation,
  getStatusForUploadLocation,
  getAllClusterMappingData,
  getAllLOCOptions,
  addClusterMappingData,
  deleteClusterMappingData,
  EditClusterMappingData,
  deleteLRForProvider,
  editLRDataForProvider,
  deleteParentChildLocationData,
  EditParentChildLocaitonData,
  downloadVendorDetails,
  deleteAllChildData,
  getGroupNameDropdowns,
  addGroupName,
  fetchLRRange,
  deleteLRRange,
  saveLrRange,
  editLRRange,
  fetchLspLRange,
  SendLRRangetoMoglix,
  SendLRRangetoSimbus,
  getEmamiPaymentTerms,
  getMaterialMRPDetails,
  downloadMRPTemplate,
  downloadMRPData,
  uploadMRPData,
  fetchAwbLRRange,
  deleteAwbLRRange,
  saveAwbLrRange,
  editAwbLRRange,
  fetchAwbLspLRange,
  SendAwbLRRangetoSimbus,
  awbSelectedOptions,
  downloadIndividualAwbDetails,
  downloadAwbLSPName,
  awbLspOptions,
  awbUpdateThreshold,
  getDamageRateDetails,
  downloadDamageRateTemplate,
  downloadDamageRateData,
  uploadDamageRateData,
  getCompanyBasicDetilsForVendorScreen,
  getDebitNoteConfig,
  saveDebitNoteConfig,
  fetchTaxCodes,
  getCustomerLaneMapping,
  postCustomerLaneMapping,
  updateCustomerLaneMapping,
  deleteCustomerLaneMapping,
  getExtraDeliveryChargesDetails,
  deleteExtraDeliveryChargesDetails,
  getAdditionalCostChargesDetails,
  deleteAdditionalCostChargesDetails,
  saveEmudhraDetails,
  // getState,
} from "./home/home.saga";
import {
  master,
  generateRouteExcel,
  exportRouteExcel,
  addNetworkChain,
  getNetworkChain,
  updateNetworkChain,
  deleteNetworkChain,
  getInventoryItem,
  addInventoryItem,
  updateInventoryItem,
  deleteInventoryItem,
  downloadInventoryItem,
  uploadInventoryItem,
  getCommonConstants,
  getAccountEvents,
  addAccountEvents,
  deleteAccountEvents,
  uploadNetworkChain,
  downloadNetworkChain,
  getDepotConsignee,
  addDepotConsignee,
  updateDepotConsignee,
  deleteDepotConsignee,
  downloadDepotConsigneeTemp,
  downloadDepotConsignee,
  uploadDepotConsignee,
  getPackagingDetails,
  getCompanyTransporters,
  addCompanyTransporters,
  deleteCompanyTransporters,

  getTruckDetails,
  addTruckDetails,
  updateTruckDetails,
  deleteTruckDetails,
  uploadTruckDetails,
  downloadTruckDetails,
  downloadTruckTemp,
  getVehicleSize,
  getTransporterDetails,
  getTruckDriverDetails,

  getSkuDetails,
  addSkuDetails,
  updateSkuDetails,
  deleteSkuDetails,
  downloadSkuDetails,
  uploadSkuDetails,
  downloadSkuTemp,

  // Driver Master
getDriverDetails,
addDriverDetails,
updateDriverDetails,
downloadDriverDetails,
uploadDriverDetails,
downloadDriverTemp,
deleteDriverDetails,
// LOCATION_NETWORK
getState,
getDPLbranchListDetails,
getCountry,
downloadNetworkTemp,
getGpsVendorData,
getDropDownNamesForMaster,
getInsideTabDropDown,
updateMasterDropDownData,
addMasterDropDownData,
deleteMasterDropDownData,
uploadRateChart,
downloadRateChart,
getRateChartDetails,
addRateChartDetails,
deleteRateChartDetails,
updateRateChartDetails
} from "./master/master.saga";
import {
  getPlantsDepots,
  getZones,
  getBranchTypes,
  updatePlant,
  createNew,
  getDepotUsers,
  getBranches,
  verifyUser,
  getActions,
  updateUser,
  createUser,
  toggleUserStatus,
  deleteUser,
  getIndentingFlows,
  getIndentingItems,
  getIndentingUsers,
  getIndentingChecklists,
  addAction,
  addChecklist,
  addWarehouse,
  addVendor,
  addMove,
  getIndentingActions,
  getIndentingMoves,
  getIndentingSubMoves,
  getIndentingWarehouses,
  getIndentingVendors,
  submitMoves,
  deleteMove,
  getIndentingFlowDetails,
  getSubPlants,
  addSubPlants,
  getActionTypes,
  getAllBranch,
  getIndentingFields,
  addIndentingFields,
  addLoadingBay,
  getBayDetails,
  getGangDetails,
  addLoadingGang,
  deleteLoadingBay,
  deleteLoadingGang,
  updateLoadingGang,
  updateLoadingBay,
  getIndentingEditFields,
  saveMoveStandardTime,
  getMoveStandardTime,
  updateMoveStandardTime,
  getApproverList,
  updateApproverList,
  postApproverList,
  applyApproverToOtherBranches,
  saveWeighBridge,
  getDepartmentsEmailList,
  getAllApproverList,
  getWBConversion,
  addWBConversion,
  deleteWBConversion,
  updateWBConversion,
  getWarehouseList,
  getWeighbridgeDetails,
  uploadTermConditions,
  downloadSubplantTemplate,
  uploadSubplantDetails,
  deleteSubplantDetails,
  editSubplantDetails,
  downloadLoadingBayTemplate,
  uploadLoadingBayDetails,
  downloadPlantDetails,
  downloadLoadingVendorTemplate,
  uploadLoadingVendorDetails,
  uploadPlantDetails,
  deletePlantsDepots,
  getWarehouseDetails,
  editWarehouseDetails,
  downloadWarehouseTemplate,
  uploadWarehouseDetails,
  getIndentingOperationTime,
  addOperationTime,
  editOperationTime,
  deleteOperationTime,
  branchTransfer,
  deactivateBranchUser,
  getDepartmentAndUserDetails,
  downloadUserDetailsTemplate,
  uploadUserDetails,
  applyIndentFlowSettingsToBranches,
  getAutomoveDetails,
  addAutomoveDetails,
  getLOBListData,
  getMoveName,
  getConsigneesCityList,
  getIndentConfigDetails,
  saveIndentConfigDetails,
  addIndentConfigDetails,
  getWeighbridgeMasterListing,
  addWBMaster,
  updateWBMaster,
  deleteWBMaster,
  getIndentingDashboardList,
  getParkingLotListing,
  addParkingLot,
  updateParkingLot,
  deleteParkingLot,
  getAllYards,
  getVolumeticMaster,
  addVolumetricMaster,
  updateVolumetricMaster,
  deleteVolumetricMaster,
  getPackUOMDropdown,
  downloadVolumetricMaster,
  uploadVolumetricMasterDetails,
  getReportsDashboardList,
  getMFSMasterDetails,
  updateMfsMasterDetails,
  createMfsMasterDetails,
  deleteMfsMasterDetails,
  getServiceModes,
  getCompanyWiseZones,
  getMfsMasterUomDetails,
  getRegionMasterDropdowns,
  getSlabRange,
  deleteSlabRange,
  createSlabRangeDetails,
  getPlantsCategory,
  fetchDepotName,
  getReasons,
  addReasons,
  deleteReasons,
  updateReasons,
  getInventoryMaster,
  deleteInventoryMaster,
  updateInventoryMaster,
  addInventoryMaster,
  getTransporterArray,
  uploadMFSMasterDetails,
  downloadMfsMaster,
  getSalesDistrictMappingMaster,
  addSalesDistrictMappingMaster,
  updateSalesDistrictMappingMaster,
  deleteSalesDistrictMappingMaster,
  getAllMoves,
  lorealdownloadUserDetailsTemplate,
  uploadLorealUserDetails
} from "./home/plants-depots/plants-depots-saga";
import {
  getBasicContractSetting,
  getOtherContractSetting,
  createNewContract,
  updateContract,
  getCollapsibleSection,
  addCollapsibleSection,
  getBranchesList,
  applySettingsToBranches,
  deleteCollapsibleSection,
  getRFQList,
  getLOIApproverEmail,
  getApproverTypes,
  addCurrency,
  getCurrencyDetails,
  addProductCategory,
  addPackagingType,
  addPricingBasis,
  addVehicleType,
  addVehicleBodyType,
  addUom,
  getMasterContractConfig,
  getPlantWiseContractData,
  addLoadability,
  getBasicMSPContractSetting,
  updateMSPContract,
	createNewMSPContract,
  applyMSPSettingsToBranches,
  getAutoDecrementData,
  addAutoDecrementData,
  getChargesForApproval
} from "./home/contracts_management/contracts_management_saga";
import {
  addCreditMaster,
  getCreditMaster,
  updateCreditMaster,
  addDebitMaster,
  getDebitMaster,
  updateDebitMaster,
  deleteDebitMaster,
  getVehicleType,
  getCompanyAccounts,
  addCompanyAccounts,
  updateCompanyAccounts,
  deleteCompanyAccounts,
  updateBillNumber,
  deleteCreditMaster,
  getBillNumberDetails,
  postBillNumber,
  deleteBillNumber,
  getMaterialDetails,
  getDetentionDetails,
  addDetentionDetails,
  updateDetentionDetails,
  deleteDetentionDetails,
  getMutltiPickupDropDetails,
  addMutltiPickupDropDetails,
  updateMutltiPickupDropDetails,
  getHolidayList,
  uploadHolidayList,
  downloadHolidayList,
  getTransporterList,
  getPayables,
  updatePayables,
  addPayables,
  deletePayables,
  getStateList,
  getZIPCodeList,
  getShortagePilferageToleranceList,
  addShortagePilferageTolerance,
  deleteShortagePilferageTolerance
} from "./home/reconciliation/reconcillation_saga";

import {
  getAllEmailsList,
  postAllEmailsList,
  copyAllEmailsList
} from "./broadcast/broadcast.saga";

import {
  getDriverInfo,
  deleteDriverInfo,
  addDriverInfo,
  updateDriverInfo,

  getTruckInfo,
  deleteTruckInfo,
  addTruckInfo,
  updateTruckInfo,
  getVehicleTypes,
  getFuleTypes,
  uploadfiels,
  getDelayPenaltyData,
  postDelayPenaltyData,
  updateDelayPenaltyData,
  deleteDelayPenaltyData,
  uploadDelayPenaltyData,
  downloadDelayPenaltyData,
  getModeTypes,
  getChannelTypes,
  getDistributionTypes,
  getVendorsTypes,
  getLiabilityData,
  downloadLiabilityData,

  getClaimTypes,
  getShortageTypes,
  postLiabilityData,
  updateLiabilityData,
  deleteLiabilityData,
  getLaneMasterData,

  postVolumetricWeight,
  getVolumetricWeightData,
  deleteVolumetricWeightData,

  getCityList,
  getCityPincodes,
  postLane,
  updateLane,
  deleteLane,
  getHubList,

  getPodVendorsTypes,
  getPodClauseData,
  getPodStatus,
  getPodCycles,
  updatePodData,
  postPodData,
  deletePodData,

  updateVolumetricWeightData,
  downLoadVolumetricLink,
  uploadVolumetricFile,

  getOdaMasters,
  postOdaMasters,
  deleteOdaMasters,
  putOdaMasters,

  getGLCodeMasters,
  postGLCodeMasters,
  deleteGLCodeMasters,
  putGLCodeMasters,

  getTypesOfOda,
  uploadSlabFile,
  uploadOdaFile,
  downloadSlabTemplate,
  downloadOdaTemplate,

  uploadLaneMaster,
  downloadLaneMaster,

  postCityMaster,
  updateCityMaster,
  deleteCityMaster,
  getCityMaster,
  uploadCityMaster,
  downloadCityMaster,

  getRMVendorsTypes,
  getRMModesTypes,
  getRMRegions,
  getRMData,
  postRMData,
  updateRMData,
  deleteRMData,
  downloadRMTemplate,
  getRMLanes,
  uploadRateMastersData,
  getHubs,
  readVolumetricWeightType,
  postVolumetricWeightType,
  deleteVolumetricWeightType,
  getRapidDieselRates,
  postApproveMonthlyRates,
  addNewDemurrage,
  getDemurrages,
  updateDemurrages,

  getMDGOtherCharges,
  addNewOtherCharges,
  updateOtherCharges,
  deleteOtherCharges,
  getOtherChargesDropdownList,

  getHubsMasterDropdown,
  deleteHubsMasterData,
  updateHubsMaster,
  addNewHubMaster,
  downloadHubsMasterData,
  uploadHubsMasterData,
  downloadDPHVendorLanes,

  getDPHDiscountStructure,
  deleteDPHDiscountStructure,
  updateDPHDiscountStructure,
  addDPHDiscountStructure,
  getHSDLaneDetails,
  getHSDContractDetails,
  editHSDContractDetails,
  hsdSaveApprovalDetails,
  getHSDZoneDetails,
  getHSDConsolidatedDetails,
  getConsolidatedViewRFQDetails,
  getTATFormVendorDetails,
  updateTATFormDetails,
  getDailyDieselPriceHike,
  postNetworkMaster,
  getNetworkMasterData,
  updateNetworkMasterdata,
  downloadnetworkmasterData,
  networkmasterupload,
  downloadNetworkMaster,
  getorigin_zoneNM,
  getmodeNM,
  getRoutenameNM,
  getsourceNM,


  downloadDriverTemplate,
  driverUpload,
  downloadVehicleTemplate,
  vehicleUploadTemplate,
  postHSDdetails,
  downloadHSDTemplate,
  uploadHSDDetails,
  postDphAmendmentMonthlyRates,
  getDphAmendmentMonthlyRates
} from "./dataGovernance/dataGovernance.saga";

import {
  getAllCharges,
  getAllVendorList,
  getVendorModeDropdown,
  fetchVendorOnBoard,
  getAllVendorModes,
  postPrimaryVendorDetails,
  updateVendorApproval,
  activateVendor,
	downloadContract,
  getAllVendorApproverList,
  getVendorApprovalCounts,
  editVendorEmail,
  retriggerVendorEmail,
  getBankDetailsFromIFSC,
  postVerificationCodeWithData,
  postPrimaryVendorDetailsFirstTime,
  getVendorDetailsForSap,
  getPaymentTermsList,
  getCurrencyList,
  postSaveSapDeatails,
  postSapSendDetailsForCode,
  getVendorSourceDropdown,
  postExWorksDeatails,
  getIncotermsOptions,
  getBillSequenceTestingList,
  getBillSequenceListing,
  getBillSequenceDropdown,
  getBillSequence,
  postBillSequence,
  updateBillSequence,
  deleteBillSequence
} from "./vendors/vendors.saga";

import {
  getAllScope,
  getVendorDetails,
  getFormVendorModes,
  postPrimaryVendorForm,
  postSecondaryVendorForm,
  updateSecondaryVendorForm,
  uploadVendorOperationalAreas,
  downloadOperationalAreas,
  uploadVendorLogo,
  getVendorOnboardAreas,
  uploadVendorDocs,
  sendChallengePrice,
  getFormStates,
  putPrimaryVendorForm,
  getOperationalAreas,
  getServicesProvided,
  getAllServicesOffered,
  getVendorRatingQuestions,
  vendorGSTDetails,
  vendorGSTDownload,
  getStateListDetails,
  getCityAndStateFromPin,
  deleteUploadedDocumentInVendor,
  uploadVendorDeclarationFiles,
  sendOTPInVendorForm,
  verifyWithOTPInVendorForm,
  uploadExternalApproverDocInVendor,
  uploadApproverDocInVendor,
  downloadVehicleDetailsTemplate,
  getUploadedVehicleDetails,
  getPaymentTerms,
  getAdminNames,
  getCurrencyOptions,
  getCountryList,
  saveSAPVendorForm,
  uploadBankDocs,
  saveBankApprovalData,
  SubmitPrimaryVendorForm,
} from "./vendorForm/vendorForm.saga";

import ACTIONS from "./common/action_constant";
import { data } from "jquery";
import { downloadAuditLogs, getAllBranchList, getAuditLogs, getIndentBranches, getAllDlNO, getAllVehicelRegNo, getAllSapMaterialCode, 
  getNetworkChainV1 
} from "./pages/AuditScreen/auditScreenSaga";
import { 
  createBannerMessage, 
  deleteBannerMessage, 
  getBannerList, 
  getBroadcastMsg, 
  updateBannerMessage } from "./pages/Banner Settings/bannerSaga";
import { companyUserInfoScreenSaga } from "./home/companyUserInfo/companyUserInfoScreenSaga";
// all saga functions in this file

export default () => [
  takeEvery(ACTIONS.HOME.GET_HOME, getHome),
  takeEvery(ACTIONS.HOME.ADD_COMPANY_API, addCompany),
  takeEvery(ACTIONS.HOME.UPDATE_COMPANY_STATUS, updateCompnayStatus),
  takeEvery(ACTIONS.HOME.GET_BASIC_DETAILS, getCompanyBasicDetils),
  takeEvery(ACTIONS.HOME.GET_BASIC_DETAILS_FOR_VENDOR_SCREEN, getCompanyBasicDetilsForVendorScreen),
  takeEvery(ACTIONS.HOME.GET_EST_CATEGORY, getEstCategory),
  takeEvery(ACTIONS.HOME.GET_BUSINESS_SEG, getBusinessSeg),
  takeEvery(ACTIONS.HOME.UPLOAD_COMPANY_IMG, uploadCompanyimg),
  takeEvery(ACTIONS.HOME.UPDATE_BASIC_DETAILS, updateBasicDetails),
  takeEvery(ACTIONS.HOME.UPLOAD_MULTIPLE_FILE, uploadMultipleFiles),
  takeEvery(ACTIONS.HOME.DELETE_FILE, deleteFile),
  takeEvery(ACTIONS.HOME.GET_COMPANY_CATEGORY, fetchAllCompanyCategory),
  takeEvery(ACTIONS.HOME.SAVE_COMPANY_CATEGORY_DETAILS, saveCompanyCategoryDetails),
  takeEvery(ACTIONS.HOME.FETCH_LR_UPLOAD_SUMMARY, fetchLRUploadSummary),
  takeEvery(ACTIONS.HOME.DELETE_LR_UPLOAD_DETAILS, deleteLRUploadDetails),
  takeEvery(ACTIONS.HOME.UPLOAD_LR_DETAIL,uploadLRDetail),
  takeEvery(ACTIONS.HOME.DOWNLOAD_LSP_DATA,downloadLSPName),
  takeEvery(ACTIONS.HOME.SET_LR_RANGE,setLrRange),
  takeEvery(ACTIONS.HOME.SET_LR_RANGE_FOR_PROVIDER,setLrRangeForProvider),
  takeEvery(ACTIONS.HOME.GET_ROUTE_CODE_DROPDOWN, getRouteCodeDropdowns),
  takeEvery(ACTIONS.HOME.DOWNLOAD_INDIVIDUAL_LR_DETAILS,downloadIndividualLRDetails),
  takeEvery(ACTIONS.HOME.GET_PROVIDER_LR_DETAILS, getProviderLRDetails),
  takeEvery(ACTIONS.HOME.DELETE_LR_FOR_PROVIDER, deleteLRForProvider),
  takeEvery(ACTIONS.HOME.EDIT_LR_DATA_FOR_PROVIDER, editLRDataForProvider),
  takeEvery(ACTIONS.HOME.GET_GROUP_NAME_DROPDOWN, getGroupNameDropdowns),
  takeEvery(ACTIONS.HOME.ADD_GROUP_NAME, addGroupName),
  
  takeEvery(ACTIONS.PLANTS.GET_PLANTS_DEPOTS, getPlantsDepots),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_FLOWS, getIndentingFlows),
  takeEvery(ACTIONS.PLANTS.GET_FLOW_DETAILS, getIndentingFlowDetails),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_ITEMS, getIndentingItems),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_USERS, getIndentingUsers),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_CHECKLISTS, getIndentingChecklists),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_ACTIONS, getIndentingActions),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_MOVES, getIndentingMoves),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_SUBMOVES, getIndentingSubMoves),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_WAREHOUSES, getIndentingWarehouses),
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_VENDORS, getIndentingVendors),
  takeEvery(ACTIONS.PLANTS.GET_SUBPLANTS, getSubPlants),
  takeEvery(ACTIONS.PLANTS.ADD_SUBPLANTS, addSubPlants),
  takeEvery(ACTIONS.PLANTS.GET_ZONES, getZones),
  takeEvery(ACTIONS.PLANTS.GET_BRANCH_TYPES, getBranchTypes),
  takeEvery(ACTIONS.PLANTS.UPDATE_CURRENT_DEPOT, updatePlant),
  takeEvery(ACTIONS.PLANTS.ADD_NEW_CURRENT_DEPOT, createNew),
  takeEvery(ACTIONS.LOGIN.USERLOGIN, login),
  takeEvery(ACTIONS.PLANTS.GET_DEPOT_USERS, getDepotUsers),
  takeEvery(ACTIONS.PLANTS.GET_BRANCHES, getBranches),
  takeEvery(ACTIONS.PLANTS.VERIFY_USER, verifyUser),
  takeEvery(ACTIONS.PLANTS.GET_ACTIONS, getActions),
  takeEvery(ACTIONS.PLANTS.ADD_USER, createUser),
  takeEvery(ACTIONS.PLANTS.ADD_ACTION, addAction),
  takeEvery(ACTIONS.PLANTS.ADD_CHECKLIST, addChecklist),
  takeEvery(ACTIONS.PLANTS.ADD_WAREHOUSE, addWarehouse),
  takeEvery(ACTIONS.PLANTS.ADD_MOVE, addMove),
  takeEvery(ACTIONS.PLANTS.ADD_VENDOR, addVendor),
  takeEvery(ACTIONS.PLANTS.SUBMIT_MOVES, submitMoves),
  takeEvery(ACTIONS.PLANTS.DELETE_MOVE, deleteMove),
  takeEvery(ACTIONS.PLANTS.UPDATE_USER, updateUser),
  takeEvery(ACTIONS.PLANTS.TOGGLE_USER_STATUS, toggleUserStatus),
  takeEvery(ACTIONS.PLANTS.DELETE_USER, deleteUser),
  takeEvery(ACTIONS.PLANTS.GET_ACTION_TYPES, getActionTypes),
  takeEvery(ACTIONS.PLANTS.GET_ALL_BRANCH, getAllBranch),

  takeEvery(ACTIONS.PLANTS.GET_MOVE_NAME, getMoveName),
  takeEvery(ACTIONS.HOME.GET_PROVIDER_USERS, getProviderUsers),
  takeEvery(ACTIONS.HOME.ADD_PROVIDER_USERS, addProviderUsers),
  takeEvery(ACTIONS.HOME.UPDATE_PROVIDER_USERS, updateProviderUsers),
  takeEvery(ACTIONS.HOME.FETCH_ALL_SEEKERS, fetchAllSeekers),
  takeEvery(ACTIONS.HOME.FETCH_COMPANY_CATEGORY, fetchCompanyCategoryDetails),
  takeEvery(ACTIONS.HOME.FETCH_VENDOR_DETAILS, fetchVendorDetails),
  takeEvery(ACTIONS.HOME.FETCH_CARGILL_VENDOR_DETAILS, fetchCargillVendorDetails),
  takeEvery(ACTIONS.HOME.GET_ALL_MODES, fetchAllModesData),
  takeEvery(ACTIONS.HOME.GET_ALL_TYPES, fetchAllTypesData),
  takeEvery(ACTIONS.HOME.FETCH_LR_RANGE, fetchLRRange),
  takeEvery(ACTIONS.HOME.DELETE_LR_RANGE, deleteLRRange),
  takeEvery(ACTIONS.HOME.ADD_LR_RANGE, saveLrRange),
  takeEvery(ACTIONS.HOME.EDIT_LR_RANGE, editLRRange),
  takeEvery(ACTIONS.HOME.FETCH_LR_LSP_RANGE, fetchLspLRange),
  takeEvery(ACTIONS.HOME.SEND_LR_RANGE_TO_MOGLIX, SendLRRangetoMoglix),
  takeEvery(ACTIONS.HOME.SEND_LR_RANGE_TO_SIMBUS, SendLRRangetoSimbus),

  takeEvery(ACTIONS.HOME.FETCH_AWB_LR_RANGE, fetchAwbLRRange),
  takeEvery(ACTIONS.HOME.DELETE_AWB_LR_RANGE, deleteAwbLRRange),
  takeEvery(ACTIONS.HOME.ADD_AWB_LR_RANGE, saveAwbLrRange),
  takeEvery(ACTIONS.HOME.EDIT_AWB_LR_RANGE, editAwbLRRange),
  takeEvery(ACTIONS.HOME.FETCH_AWB_LR_LSP_RANGE, fetchAwbLspLRange),
  takeEvery(ACTIONS.HOME.SEND_AWB_LR_RANGE_TO_SIMBUS, SendAwbLRRangetoSimbus),
  takeEvery(ACTIONS.HOME.AWB_SELECTED_OPTIONS, awbSelectedOptions),
  takeEvery(ACTIONS.HOME.DOWNLOAD_INDIVIDUAL_AWB_DETAILS, downloadIndividualAwbDetails),
  takeEvery(ACTIONS.HOME.DOWNLOAD_AWB_LSP_DATA, downloadAwbLSPName),
  takeEvery(ACTIONS.HOME.AWB_LSP_OPTIONS, awbLspOptions),
  takeEvery(ACTIONS.HOME.AWB_THRESHOLD_UPDATES, awbUpdateThreshold),
  
  //contract management saga
  takeEvery(
    ACTIONS.CONTRACTS.GET_BASIC_CONTRACT_SETTING,
    getBasicContractSetting
  ),
  takeEvery(
    ACTIONS.CONTRACTS.GET_OTHER_CONTRACT_SETTING,
    getOtherContractSetting
  ),
  takeEvery(ACTIONS.CONTRACTS.ADD_CONTRACT, createNewContract),
  takeEvery(ACTIONS.CONTRACTS.EDIT_CONTRACT, updateContract),
  takeEvery(ACTIONS.CONTRACTS.GET_COLLAPSIBLE_SECTION, getCollapsibleSection),
  takeEvery(ACTIONS.CONTRACTS.ADD_COLLAPSIBLE_SECTION, addCollapsibleSection),
  takeEvery(
    ACTIONS.CONTRACTS.DELETE_COLLAPSIBLE_SECTION,
    deleteCollapsibleSection
  ),
  takeEvery(ACTIONS.CONTRACTS.GET_BRANCH_LIST, getBranchesList),
  takeEvery(
    ACTIONS.CONTRACTS.APPLY_SETTINGS_TO_BRANCHES,
    applySettingsToBranches
  ),
  takeEvery(ACTIONS.CONTRACTS.GET_RFQS, getRFQList),
  takeEvery(ACTIONS.CONTRACTS.GET_EMAIL_LIST, getLOIApproverEmail),
  takeEvery(ACTIONS.CONTRACTS.GET_AUTO_DECREMENT_DATA, getAutoDecrementData),
  takeEvery(ACTIONS.CONTRACTS.ADD_AUTO_DECREMENT_DATA, addAutoDecrementData),

  //import the respective sagas from the corresponding file to excute the below apis
  takeEvery(ACTIONS.HOME.GET_ROLES, getRoles),
  takeEvery(ACTIONS.HOME.LSP_OPTIONS, lspOptions),
 takeEvery(ACTIONS.HOME.SELECTED_OPTIONS, selectedOptions),
 takeEvery(ACTIONS.HOME.THRESHOLD_UPDATES, updateThreshold),
 takeEvery(ACTIONS.HOME.FILE_UPLOAD, uploadFile),
 takeEvery(ACTIONS.HOME.DOWNLOADS_OPTIONS, downloads),
  takeEvery(ACTIONS.HOME.ADD_ROLE, addRole),
  takeEvery(ACTIONS.HOME.DELETE_ROLE, deleteRole),
  takeEvery(ACTIONS.HOME.GET_DEPARTMENTS, getDepartments),
  takeEvery(ACTIONS.HOME.ADD_DEPARTMENT, addDepartment),
  takeEvery(ACTIONS.HOME.UPDATE_DEPARTMENT, updateDepartment),
  takeEvery(ACTIONS.HOME.DELETE_DEPARTMENT, deleteDepartment),
  takeEvery(ACTIONS.HOME.DOWNLOAD_LR_UPLOAD_DETAIL, downloadLRUploadDetail),
  takeEvery(ACTIONS.HOME.SEND_LR_TO_MOGLIX, SendLRtoMoglix),
  takeEvery(ACTIONS.HOME.GET_BRANCH_FOR_LR, fetchBranchForLr),
  takeEvery(ACTIONS.HOME.GET_ALL_SEEKERS_FOR_LR, fetchAllSeekerForLr),

  takeEvery(ACTIONS.MASTER.SAP_MODULE.GENERATE_ROUTE_EXCEL, generateRouteExcel),
  takeEvery(ACTIONS.MASTER.SAP_MODULE.EXPORT_ROUTE_EXCEL, exportRouteExcel),

  // Location Network Chain
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.ADD_NETWORK_CHAIN, addNetworkChain),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN, getNetworkChain),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.GET_NETWORK_CHAIN_V1, getNetworkChainV1),
  takeEvery(
    ACTIONS.MASTER.LOCATION_NETWORK.UPDATE_NETWORK_CHAIN,
    updateNetworkChain
  ),
  takeEvery(
    ACTIONS.MASTER.LOCATION_NETWORK.DELETE_NETWORK_CHAIN,
    deleteNetworkChain
  ),
  takeEvery(
    ACTIONS.MASTER.LOCATION_NETWORK.UPLOAD_NETWORK_CHAIN,
    uploadNetworkChain
  ),
  takeEvery(
    ACTIONS.MASTER.LOCATION_NETWORK.DOWNLOAD_NETWORK_CHAIN,
    downloadNetworkChain
  ),

  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.GET_STATE,getState),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.GET_DPL_BRANCH_LIST_DETAILS,getDPLbranchListDetails),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.GET_COUNTRY,getCountry),

  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.DOWNLOAD_NETWORK_CHAIN_TEMP,downloadNetworkTemp),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.ADD_DEPOT_CONSIGNEE, addDepotConsignee),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.GET_DEPOT_CONSIGNEE, getDepotConsignee),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.UPDATE_DEPOT_CONSIGNEE, updateDepotConsignee),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.DELETE_DEPOT_CONSIGNEE, deleteDepotConsignee),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.DOWNLOAD_DEPOT_CONSIGNEE_TEMP,downloadDepotConsigneeTemp),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.DOWNLOAD_DEPOT_CONSIGNEE,downloadDepotConsignee),
  takeEvery(ACTIONS.MASTER.LOCATION_NETWORK.UPLOAD_DEPOT_CONSIGNEE,uploadDepotConsignee),
  //Inventory Sagas
  takeEvery(
    ACTIONS.MASTER.INVENTORY_ITEM.GET_COMMON_CONSTANTS,
    getCommonConstants
  ),
  takeEvery(ACTIONS.MASTER.INVENTORY_ITEM.GET_INVENTORY_ITEM, getInventoryItem),
  takeEvery(ACTIONS.MASTER.INVENTORY_ITEM.ADD_INVENTORY_ITEM, addInventoryItem),
  takeEvery(
    ACTIONS.MASTER.INVENTORY_ITEM.UPDATE_INVENTORY_ITEM,
    updateInventoryItem
  ),
  takeEvery(
    ACTIONS.MASTER.INVENTORY_ITEM.DELETE_INVENTORY_ITEM,
    deleteInventoryItem
  ),
  takeEvery(
    ACTIONS.MASTER.INVENTORY_ITEM.UPLOAD_INVENTORY_ITEM,
    uploadInventoryItem
  ),
  takeEvery(
    ACTIONS.MASTER.INVENTORY_ITEM.DOWNLOAD_INVENTORY_ITEM,
    downloadInventoryItem
  ),
  takeEvery(
    ACTIONS.MASTER.INVENTORY_ITEM.GET_PACKAGING_DETAILS,
    getPackagingDetails
  ),

  //Account Master
  takeEvery(ACTIONS.MASTER.ACCOUNTS.GET_ACCOUNT_DETAILS, getAccountEvents),
  takeEvery(ACTIONS.MASTER.ACCOUNTS.ADD_ACCOUNT_DETAILS, addAccountEvents),
  takeEvery(
    ACTIONS.MASTER.ACCOUNTS.DELETE_ACCOUNT_DETAILS,
    deleteAccountEvents
  ),

  // Company Account  master
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER, getCreditMaster),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.POST_CREDIT_MASTER, addCreditMaster),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.UPADTE_CREDIT_MASTER, updateCreditMaster),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_DEBIT_MASTER, getDebitMaster),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.POST_DEBIT_MASTER, addDebitMaster),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.UPDATE_DEBIT_MASTER, updateDebitMaster),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.DELETE_DEBIT_MASTER, deleteDebitMaster),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_VEHICLE_DETAILS, getVehicleType),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_ACCOUNTS_DETAILS, getCompanyAccounts),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.POST_ACCOUNTS_DETAILS, addCompanyAccounts),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.UPDATE_ACCOUNTS_DETAILS, updateCompanyAccounts),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.DELETE_ACCOUNTS_DETAILS, deleteCompanyAccounts
  ),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.DELETE_CREDIT_MASTER, deleteCreditMaster),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_BILL_NUMBER_DETAILS, getBillNumberDetails),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.POST_BILL_NUMBER_DETAILS, postBillNumber),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.DELETE_BILL_NUMBER, deleteBillNumber),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.UPDATE_BILL_NUMBER_DETAILS, updateBillNumber),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_MATERIAL_DETAILS, getMaterialDetails),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_DETENTION_DETAILS, getDetentionDetails),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.UPDATE_DETENTION_DETAILS, updateDetentionDetails),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.ADD_DETENTION_DETAILS, addDetentionDetails),
  takeEvery(
    ACTIONS.COMPANY_ACCOUNTS.DELETE_DETENTION_DETAILS,
    deleteDetentionDetails
  ),
  takeEvery(
    ACTIONS.COMPANY_ACCOUNTS.GET_MULTI_PICKUP_DETAILS,
    getMutltiPickupDropDetails
  ),
  takeEvery(
    ACTIONS.COMPANY_ACCOUNTS.ADD_MULTI_PICKUP_DETAILS,
    addMutltiPickupDropDetails
  ),
  takeEvery(
    ACTIONS.COMPANY_ACCOUNTS.UPDATE_MULTI_PICKUP_DETAILS,
    updateMutltiPickupDropDetails
  ),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_HOLIDAY_LIST, getHolidayList),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.UPLOAD_HOLIDAY_LIST, uploadHolidayList),
  takeEvery(
    ACTIONS.COMPANY_ACCOUNTS.DOWNLOAD_HOLIDAY_LIST,
    downloadHolidayList
  ),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_CREDIT_MASTER_PAYABLES, getPayables),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.ADD_CREDIT_MASTER_PAYABLES, addPayables),
  takeEvery(
    ACTIONS.COMPANY_ACCOUNTS.UPDATE_CREDIT_MASTER_PAYABLES,
    updatePayables
  ),
  takeEvery(
    ACTIONS.COMPANY_ACCOUNTS.DELETE_CREDIT_MASTER_PAYABLES,
    deletePayables
  ),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_STATE_LIST, getStateList),

  takeEvery(ACTIONS.HOME.GET_ALL_STATE, fetchAllStates),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_TRANSPORTER_LIST, getTransporterList),

  takeEvery(ACTIONS.HOME.GET_INDENT_REASONS, getIndentReasons),
  takeEvery(ACTIONS.HOME.ADD_INDENT_REASONS, addIndentReasons),
  takeEvery(ACTIONS.HOME.UPDATE_INDENT_REASONS, updateIndentReasons),
  takeEvery(ACTIONS.HOME.DELETE_INDENT_REASONS, deleteIndentReasons),

  takeEvery(ACTIONS.BROADCAST.GET_ALL_EMAILS, getAllEmailsList),
  takeEvery(ACTIONS.BROADCAST.POST_ALL_EMAILS, postAllEmailsList),

  takeEvery(ACTIONS.BROADCAST.COPY_ALL_EMAILS , copyAllEmailsList),

  takeEvery(ACTIONS.MASTER.TRANSPORTERS.GET_COMPANY_TRANSPORTERS, getCompanyTransporters),
  takeEvery(ACTIONS.MASTER.TRANSPORTERS.ADD_COMPANY_TRANSPORTERS, addCompanyTransporters),
  takeEvery(ACTIONS.MASTER.TRANSPORTERS.DELETE_COMPANY_TRANSPORTERS, deleteCompanyTransporters),

  takeEvery(ACTIONS.HOME.GET_MODULES_LIST, getModuleList),
  takeEvery(ACTIONS.HOME.SAVE_MODULES_LIST, saveModuleList),

  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_ZIPCODE_LIST, getZIPCodeList),
// Vehicle driver master
// Driver info
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_DRIVER_INFO, getDriverInfo),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_DRIVER_INFO, deleteDriverInfo),
  takeEvery(ACTIONS.DATA_GOVERNANCE.ADD_DRIVER_INFO, addDriverInfo),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_DRIVER_INFO, updateDriverInfo),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_DRIVER_TEMPLATE, downloadDriverTemplate),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_DRIVER_TEMPLATE, driverUpload),

  // Truck info
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_TRUCK_INFO, getTruckInfo),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_TRUCK_INFO, deleteTruckInfo),
  takeEvery(ACTIONS.DATA_GOVERNANCE.ADD_TRUCK_INFO, addTruckInfo),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_TRUCK_INFO, updateTruckInfo),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_TRUCK_TYPE, getVehicleTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_VEHICLE_FIELS, uploadfiels),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_FULE_TYPE, getFuleTypes),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_DELAY_PENALTY, getDelayPenaltyData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_DELAY_PENALTY, postDelayPenaltyData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_DELAY_PENALTY, updateDelayPenaltyData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_DELAY_PENALTY, deleteDelayPenaltyData),

  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_VEHICLE_TEMPLATE, downloadVehicleTemplate),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_VEHICLE_TEMPLATE, vehicleUploadTemplate),

  
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_DELAY_PENALTY, downloadDelayPenaltyData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_DELAY_PENALTY, uploadDelayPenaltyData),
  
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_MODE_TYPES, getModeTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_CHANNEL_TYPES, getChannelTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_DISTRIBUTION_TYPES, getDistributionTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_VENDORS_TYPES, getVendorsTypes),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_POD_VENDORS_TYPES, getPodVendorsTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_POD_STATUS, getPodStatus),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_POD_CYCLES, getPodCycles),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_POD_CLAUSE_DATA, getPodClauseData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_POD_DATA, postPodData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_POD_DATA, updatePodData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_POD_DATA, deletePodData),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_LIABILITY_DATA, getLiabilityData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_LIABILITY, downloadLiabilityData),

  takeEvery(ACTIONS.PLANTS.GET_INDENTING_FIELDS, getIndentingFields),
  takeEvery(ACTIONS.PLANTS.ADD_INDENTING_FIELDS, addIndentingFields),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_CLAIM_TYPES, getClaimTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_SHORTAGES_TYPES, getShortageTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_LIABILITY_DATA, postLiabilityData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_LIABILITY_DATA, updateLiabilityData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_LIABILITY, deleteLiabilityData),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_LANE_MASTERS, getLaneMasterData),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_CITY_LIST, getCityList),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_HUB_LIST, getHubList),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_CITY_PINCODES, getCityPincodes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_LANE, postLane),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_LANE, deleteLane),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_LANE, updateLane),

  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_VOLUMETRIC_WEIGHT, postVolumetricWeight),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_VOLUMETRIC_WEIGHT, getVolumetricWeightData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_VOLUMETRIC_WEIGHT, deleteVolumetricWeightData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_VOLUMETRIC_WEIGHT, updateVolumetricWeightData),

  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_VOLUMETRIC_LINK, downLoadVolumetricLink),

  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_VOLUMETRIC_FILE, uploadVolumetricFile),
    
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_ODA_MASTERS, getOdaMasters),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_ODA_MASTERS, postOdaMasters),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_ODA_MASTERS, putOdaMasters),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_ODA_MASTERS, deleteOdaMasters),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_ALL_TYPES_OF_ODA, getTypesOfOda),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_GLCODE_MASTERS, getGLCodeMasters),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_GLCODE_MASTERS, postGLCodeMasters),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_GLCODE_MASTERS, putGLCodeMasters),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_GLCODE_MASTERS, deleteGLCodeMasters),

  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_SLAB, uploadSlabFile),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_ODA, uploadOdaFile),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_SLAB, downloadSlabTemplate),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_ODA, downloadOdaTemplate),

  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_LANE, uploadLaneMaster),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_LANE, downloadLaneMaster),

  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_CITY_MASTER, postCityMaster),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_CITY_MASTER, updateCityMaster),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_CITY_MASTER, deleteCityMaster),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_CITY_MASTER, getCityMaster),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_CITY_MASTER, uploadCityMaster),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_CITY_MASTER, downloadCityMaster),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_RM_VENDORS_TYPES, getRMVendorsTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_RM_MODES_TYPES, getRMModesTypes),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_RM_REGIONS, getRMRegions),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_RM_DATA, getRMData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_RM_DATA, postRMData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_RM_DATA, updateRMData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_RM_DATA, deleteRMData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_RM_TEMPLATE, downloadRMTemplate),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_RM_TEMPLATE, uploadRateMastersData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_RM_LANES, getRMLanes),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_HUBS, getHubs),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_VOLUMETRIC_WEIGHT_TYPE, postVolumetricWeightType),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_VOLUMETRIC_WEIGHT_TYPE, deleteVolumetricWeightType),
  takeLatest(ACTIONS.DATA_GOVERNANCE.GET_VOLUMETRIC_WEIGHT_TYPE, readVolumetricWeightType),

  takeEvery(ACTIONS.VENDORS.GET_ALL_VENDOR_LIST ,getAllVendorList),
  takeEvery(ACTIONS.VENDORS.GET_VENDOR_MODE_LIST, getVendorModeDropdown),
  takeEvery(ACTIONS.VENDORS.GET_VENDOR_DETAILS, getVendorDetails),
  takeEvery(ACTIONS.VENDORS.FETCH_VENDOR_ONBOARD, fetchVendorOnBoard),
  takeEvery(ACTIONS.VENDORS.POST_PRIMARY_VENDOR, postPrimaryVendorDetails),
  takeEvery(ACTIONS.VENDORS.POST_PRIMARY_VENDOR_FIRST_TIME, postPrimaryVendorDetailsFirstTime),
  takeEvery(ACTIONS.VENDORS.VERIFY_VENDOR_CONTACTS, postVerificationCodeWithData),

  takeEvery(ACTIONS.VENDORS.GET_ALL_VENDOR_MODES, getAllVendorModes),
  takeEvery(ACTIONS.VENDORS.GET_ALL_SCOPE, getAllScope),
  takeEvery(ACTIONS.VENDORS.GET_FORM_VENDOR_MODES, getFormVendorModes),
  takeEvery(ACTIONS.VENDORS.GET_CHARGES, getAllCharges),

  takeEvery(ACTIONS.VENDORS.POST_PRIMARY_VENDOR_FORM, postPrimaryVendorForm),
  takeEvery(ACTIONS.VENDORS.PUT_PRIMARY_VENDOR_FORM, putPrimaryVendorForm),
  takeEvery(ACTIONS.VENDORS.SUBMIT_PRIMARY_VENDOR_FORM, SubmitPrimaryVendorForm),
  takeEvery(ACTIONS.VENDORS.POST_SECONDARY_VENDOR_FORM, postSecondaryVendorForm),
  takeEvery(ACTIONS.VENDORS.UPDATE_SECONDARY_VENDOR_FORM, updateSecondaryVendorForm),
  takeEvery(ACTIONS.VENDORS.UPDATE_VENDOR_APPROVAL, updateVendorApproval),
  takeEvery(ACTIONS.VENDORS.ACTIVATE_VENDOR, activateVendor),
  takeEvery(ACTIONS.VENDORS.SEND_OTP_IN_VENDOR_FORM, sendOTPInVendorForm),
  takeEvery(ACTIONS.VENDORS.VERIFY_OTP_IN_VENDOR_FORM, verifyWithOTPInVendorForm),

  takeEvery(ACTIONS.VENDORS.UPLOAD_VENDOR_OP_AREAS, uploadVendorOperationalAreas),
  takeEvery(ACTIONS.VENDORS.DOWNLOAD_OPERATIONAL_AREAS, downloadOperationalAreas),
  takeEvery(ACTIONS.VENDORS.UPLOAD_VENDOR_LOGO, uploadVendorLogo),
  takeEvery(ACTIONS.VENDORS.GET_VENDOR_ONBOARD_AREAS, getVendorOnboardAreas),
  takeEvery(ACTIONS.VENDORS.UPLOAD_VENDOR_DOCS, uploadVendorDocs),
  takeEvery(ACTIONS.VENDORS.UPLOAD_VENDOR_DECLARATION_FILES, uploadVendorDeclarationFiles),
  takeEvery(ACTIONS.VENDORS.UPLOAD_EXTERNAL_APPROVER_DOC_IN_VENDOR, uploadExternalApproverDocInVendor),
  takeEvery(ACTIONS.VENDORS.UPLOAD_APPROVER_DOC_IN_VENDOR, uploadApproverDocInVendor),
  takeEvery(ACTIONS.VENDORS.SEND_CHALLENGE_PRICE, sendChallengePrice),
  takeEvery(ACTIONS.VENDORS.FORM_ZONES, getFormStates),
	takeEvery(ACTIONS.VENDORS.DOWNLOAD_CONTRACT_FORM, downloadContract),
  takeEvery(ACTIONS.VENDORS.GET_ALL_VENDOR_APPROVERS, getAllVendorApproverList),
  takeEvery(ACTIONS.VENDORS.GET_VENDOR_GST_DETAILS, vendorGSTDetails),
  takeEvery(ACTIONS.VENDORS.DOWNLOAD_VENDOR_GST_DETAILS, vendorGSTDownload),
  takeEvery(ACTIONS.VENDORS.GET_BANK_DETAILS_FROM_IFSC, getBankDetailsFromIFSC),
  takeEvery(ACTIONS.VENDORS.DOWNLOAD_VEHICLE_DETAILS, downloadVehicleDetailsTemplate),
  takeEvery(ACTIONS.VENDORS.GET_UPLOADED_VEHICLE_DETAILS, getUploadedVehicleDetails),
  takeEvery(ACTIONS.VENDORS.GET_PAYMENT_TERMS, getPaymentTerms),
  takeEvery(ACTIONS.VENDORS.GET_ADMIN_NAMES, getAdminNames),
  takeEvery(ACTIONS.VENDORS.GET_CURRENCY_OPTIONS, getCurrencyOptions),
  takeEvery(ACTIONS.VENDORS.GET_COUNTRY_LIST, getCountryList),
  takeEvery(ACTIONS.VENDORS.SAVE_SAP_VENDOR_FORM, saveSAPVendorForm),
  takeEvery(ACTIONS.VENDORS.BANK_APPROVER_DOCUMENT_UPLOAD, uploadBankDocs),
  takeEvery(ACTIONS.VENDORS.SAVE_BANK_APPROVER_DATA, saveBankApprovalData),

  takeEvery(ACTIONS.HOME.GET_ROUTE_CODE, getRouteCode),
  takeEvery(ACTIONS.HOME.ADD_ROUTE_CODE, addRouteCode),
  takeEvery(ACTIONS.HOME.UPDATE_ROUTE_CODE, updateRouteCode),
  takeEvery(ACTIONS.HOME.GET_ROUTE_CODE_DISTANCE, getDistance),
  takeEvery(ACTIONS.HOME.GET_DISTRICT, getDistrict),
  takeEvery(ACTIONS.HOME.GET_TO_DISTRICT, getToDistrict),
  takeEvery(ACTIONS.HOME.GET_VENDOR_MDG_DETAILS, getVendorMDGDetils),
  takeEvery(ACTIONS.PLANTS.ADD_LOADING_BAY, addLoadingBay),
  takeEvery(ACTIONS.PLANTS.GET_LOADING_BAY, getBayDetails),
  takeEvery(ACTIONS.PLANTS.GET_LOADING_GANG, getGangDetails),
  takeEvery(ACTIONS.PLANTS.ADD_LOADING_GANG, addLoadingGang),
  takeEvery(ACTIONS.PLANTS.DELETE_LOADING_BAY, deleteLoadingBay),
  takeEvery(ACTIONS.PLANTS.DELETE_LODING_GANG, deleteLoadingGang),
  takeEvery(ACTIONS.PLANTS.UPDATE_LOADING_GANG, updateLoadingGang),
  takeEvery(ACTIONS.PLANTS.UPDATE_LOADING_BAY, updateLoadingBay),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_RAPID_RATES, getRapidDieselRates),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_MONTHLY_RATES, postApproveMonthlyRates),
  
  takeEvery(ACTIONS.PLANTS.GET_INDENTING_EDIT_FIELDS, getIndentingEditFields),
  takeEvery(ACTIONS.PLANTS.ADD_MOVE_STANDARD_TIME, saveMoveStandardTime),
  takeEvery(ACTIONS.PLANTS.GET_MOVE_STANDARD_TIME, getMoveStandardTime),
  takeEvery(ACTIONS.PLANTS.UPDATE_MOVE_STANDARD_TIME, updateMoveStandardTime),

  takeEvery(ACTIONS.CONTRACTS.GET_APPROVER_TYPES, getApproverTypes),
  takeEvery(ACTIONS.CONTRACTS.GET_CHARGES_FOR_APPROVAL, getChargesForApproval),
  takeEvery(ACTIONS.DATA_GOVERNANCE.ADD_DEMURRAGE, addNewDemurrage),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_DEMURRAGE, getDemurrages),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_DEMURRAGE, updateDemurrages),

  takeEvery(ACTIONS.PLANTS.GET_APPROVER_LIST, getApproverList),
  takeEvery(ACTIONS.PLANTS.POST_APPROVER_LIST, postApproverList),
  takeEvery(ACTIONS.PLANTS.UPDATE_APPROVER_LIST, updateApproverList),
  takeEvery(ACTIONS.PLANTS.APPLY_APPROVER_SETTING_TO_OTHER_BRANCH, applyApproverToOtherBranches),

  takeEvery(ACTIONS.PLANTS.SAVE_WEIGH_BRIDGE_CONFIG, saveWeighBridge),
  takeEvery(ACTIONS.PLANTS.DEPARTMENT_EMAIL_LIST, getDepartmentsEmailList),
  takeEvery(ACTIONS.PLANTS.GET_ALL_APPROVERS, getAllApproverList),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_MDG_OTHERCHARGES, getMDGOtherCharges),
  takeEvery(ACTIONS.DATA_GOVERNANCE.ADD_NEW_OTHERCHARGES, addNewOtherCharges),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_OTHERCHARGES, updateOtherCharges),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_OTHERCHARGES, deleteOtherCharges),
  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_OTHERCHARGES_DROPDOWNLIST, getOtherChargesDropdownList),

  takeEvery(ACTIONS.PLANTS.GET_WB_CONVERSION, getWBConversion),
  takeEvery(ACTIONS.PLANTS.ADD_WB_CONVERSION, addWBConversion),
  takeEvery(ACTIONS.PLANTS.UPDATE_WB_CONVERSION, updateWBConversion),
  takeEvery(ACTIONS.PLANTS.DELETE_WB_CONVERSION, deleteWBConversion),

  takeEvery(ACTIONS.HOME.GET_RFQ_TYPES, getRFQTypes),
  takeEvery(ACTIONS.CONTRACTS.ADD_CURRENCY, addCurrency),
  takeEvery(ACTIONS.CONTRACTS.GET_CURRENCY_DETAILS,getCurrencyDetails),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_HUBS_MASTER_DROPDOWN, getHubsMasterDropdown),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_HUBS_MASTER_DATA, deleteHubsMasterData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_HUBS_MASTER, updateHubsMaster),
  takeEvery(ACTIONS.DATA_GOVERNANCE.ADD_NEW_HUB_MASTER, addNewHubMaster),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_HUBS_MASTER, downloadHubsMasterData),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_HUBS_MASTER_DATA, uploadHubsMasterData),

  takeEvery(ACTIONS.VENDORS.GET_VENDORA_PPROVAL_COUNTS, getVendorApprovalCounts),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_VENDOR_LANES, downloadDPHVendorLanes),

  takeEvery(ACTIONS.DATA_GOVERNANCE.GET_DPH_DISCOUNT_STRUCTURE, getDPHDiscountStructure),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DELETE_DPH_DISCOUNT_STRUCTURE, deleteDPHDiscountStructure),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_DPH_DISCOUNT_STRUCTURE, updateDPHDiscountStructure),
  takeEvery(ACTIONS.DATA_GOVERNANCE.ADD_DPH_DISCOUNT_STRUCTURE, addDPHDiscountStructure),

  takeEvery(ACTIONS.PLANTS.GET_WAREHOUSE_LIST, getWarehouseList),

  takeEvery(ACTIONS.PLANTS.GET_WEIGH_BRIDGE_DETAILS, getWeighbridgeDetails),
  takeEvery(ACTIONS.PLANTS.UPLOAD_TERM_CONDITION, uploadTermConditions),

  //Trip Eg 
  takeEvery(ACTIONS.HOME.DOWNLOAD_USER, downloadProviderUserDeatils),
  takeEvery(ACTIONS.HOME.UPLOAD_USER, uploadProviderUserDetails),

  takeEvery(ACTIONS.HOME.DOWNLOAD_BRANCH, downloadProviderBranchDeatils),
  takeEvery(ACTIONS.HOME.UPLOAD_BRANCH, uploadProviderBranchDetails),
  takeEvery(ACTIONS.HOME.DOWNLOAD_DEPARTMENT, downloadProviderDepartmentDetails),
  takeEvery(ACTIONS.HOME.UPLOAD_DEPARTMENT, uploadProviderDepartmentDetails),

  takeEvery(ACTIONS.HOME.DOWNLOAD_ROLES, downloadProviderRolesDeatils),

  takeEvery(ACTIONS.HOME.UPLOAD_ROLES, uploadProviderRolesDetails),

  //Material Conversion
  takeEvery(ACTIONS.HOME.GET_MATERIAL_CONVERSION, getMaterialConversion),
  takeEvery(ACTIONS.HOME.ADD_MATERIAL_CONVERSION, addMaterialConversion),


  takeEvery(ACTIONS.HOME.DOWNLOAD_ROUTE_CODE, downloadSapRouteCode),
  takeEvery(ACTIONS.HOME.UPLOADLOAD_ROUTE_CODE, uploadSapRouteCodeDetails),
  takeEvery(ACTIONS.HOME.DELETE_ROUTE_CODE, deleteSapRouteCodeDetails),


  takeEvery(ACTIONS.CONTRACTS.ADD_PRODUCT_CATEGORY, addProductCategory),
  takeEvery(ACTIONS.CONTRACTS.ADD_PACKING_TYPE, addPackagingType),
  takeEvery(ACTIONS.CONTRACTS.ADD_PRICING_BASIS, addPricingBasis),
  takeEvery(ACTIONS.CONTRACTS.ADD_VEHICLE_TYPE, addVehicleType),
  takeEvery(ACTIONS.CONTRACTS.ADD_VEHICLE_BODY_TYPE, addVehicleBodyType),
  takeEvery(ACTIONS.CONTRACTS.ADD_UOM, addUom),
  takeEvery(ACTIONS.HOME.DOWNLOAD_ROUTE_CODE_DETAILS, downloadSapRouteCodeDetails),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.GET_SHORTAGE_PILFERAGE_TOLERANCE_LIST, getShortagePilferageToleranceList),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.SAVE_SHORTAGE_PILFERAGE_TOLERANCE, addShortagePilferageTolerance),
  takeEvery(ACTIONS.COMPANY_ACCOUNTS.DELETE_SHORTAGE_PILFERAGE_TOLERANCE, deleteShortagePilferageTolerance),

  takeEvery(ACTIONS.HOME.GET_SCHEDULER_CODE, getSchedulerCode),
  takeEvery(ACTIONS.HOME.ADD_SCHEDULER_CODE, addSchedulerCode),
  takeEvery(ACTIONS.HOME.UPDATE_SCHEDULER_CODE, updateSchedulerCode),
  takeEvery(ACTIONS.HOME.DELETE_SCHEDULER_CODE, deleteSchedulerDetails),
  takeEvery(ACTIONS.HOME.DOWNLOAD_SCHEDULER_DETAILS, downloadSchedulerDetails),
  takeEvery(ACTIONS.HOME.UPLOAD_SCHEDULER_DETAILS, uploadSchedulerDetails),

  takeEvery(ACTIONS.VENDORS.GET_OPERATIONAL_AREA, getOperationalAreas),
  takeEvery(ACTIONS.VENDORS.GET_STATE_LIST_DETAILS, getStateListDetails),
  takeEvery(ACTIONS.VENDORS.GET_SERVICE_PROVIDED, getServicesProvided),
  takeEvery(ACTIONS.VENDORS.GET_ALL_SERVICE_OFFERED, getAllServicesOffered),
  takeEvery(ACTIONS.HOME.GET_WAREHOUSE_PARTICULARS_LIST, getWarehouseParticulars),
  takeEvery(ACTIONS.HOME.SAVE_WAREHOUSE_PARTICULARS_LIST, postWarehouseParticulars),
  takeEvery(ACTIONS.HOME.GET_WAREHOUSE_TEMPLATE_LIST, getWarehouseTemplateList),
  takeEvery(ACTIONS.HOME.SAVE_WAREHOUSE_TEMPLATE_LIST, postWarehouseTemplate),
  takeEvery(ACTIONS.VENDORS.GET_STATE_AND_CITY_FROM_PIN_CODE, getCityAndStateFromPin),
  takeEvery(ACTIONS.VENDORS.DELETE_UPLOADED_DOCUMENT_IN_VENDOR, deleteUploadedDocumentInVendor),

  takeEvery(ACTIONS.VENDORS.GET_ALL_QUESTIONS, getVendorRatingQuestions),
  takeEvery(ACTIONS.PLANTS.DOWNLOAD_SUBPLANT, downloadSubplantTemplate),
  takeEvery(ACTIONS.PLANTS.UPLOAD_SUBPLANT, uploadSubplantDetails),
  takeEvery(ACTIONS.PLANTS.DELETE_SUBPLANT, deleteSubplantDetails),
  takeEvery(ACTIONS.PLANTS.EDIT_SUBPLANT, editSubplantDetails),

  // takeEvery(ACTIONS.PLANTS.DOWNLOAD_LOADINGBAY_DETAILS, downloadLoadingBayDetails),
  takeEvery(ACTIONS.PLANTS.UPLOAD_LOADINGBAY_DETAILS, uploadLoadingBayDetails),
  takeEvery(ACTIONS.PLANTS.DOWNLOAD_LOADINGBAY_TEMP, downloadLoadingBayTemplate),
  takeEvery(ACTIONS.PLANTS.DOWNLOAD_PLANT_DEPOT, downloadPlantDetails),

  takeEvery(ACTIONS.DATA_GOVERNANCE.HSD_CONTRACT_DETAILS, getHSDContractDetails),
  takeEvery(ACTIONS.DATA_GOVERNANCE.HSD_CONTRACT_LANE_DETAILS, getHSDLaneDetails),
  takeEvery(ACTIONS.DATA_GOVERNANCE.HSD_EDIT_CONTRACT_DETAILS, editHSDContractDetails),
  takeEvery(ACTIONS.DATA_GOVERNANCE.HSD_LANE_APPROVAL, hsdSaveApprovalDetails),
  takeEvery(ACTIONS.PLANTS.UPLOAD_PLANT_DETAILS, uploadPlantDetails),
  takeEvery(ACTIONS.DATA_GOVERNANCE.HSD_ZONE_DETAILS, getHSDZoneDetails),
  takeEvery(ACTIONS.DATA_GOVERNANCE.HSD_CONSOLIDATED_DETAILS,getHSDConsolidatedDetails),
  takeEvery(ACTIONS.DATA_GOVERNANCE.POST_HSD_DETAILS,postHSDdetails),
  takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_HSD_DETAILS,downloadHSDTemplate),
  takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_HSD_DETAILS ,uploadHSDDetails),

  takeEvery(ACTIONS.HOME.UPLOAD_MATERIAL_CONVERSION ,uploadMaterialConversionDetails),
  takeEvery(ACTIONS.HOME.DOWNLOAD_MATERIAL_CONVERSION, downloadMaterialConversionTemplate),
  takeEvery(ACTIONS.HOME.UPDATE_MATERIAL_CONVERSION, editMaterialConversionDetails),
  takeEvery(ACTIONS.HOME.DELETE_MATERIAL_CONVERSION, deleteMaterialConversionDetails),

  takeEvery(ACTIONS.PLANTS.UPLOAD_LOADINGVENDOR_DETAILS, uploadLoadingVendorDetails),
  takeEvery(ACTIONS.PLANTS.DOWNLOAD_LOADINGVENDOR_TEMP, downloadLoadingVendorTemplate),
  takeEvery(ACTIONS.PLANTS.DELETE_PLANTS_DEPOTS, deletePlantsDepots),
  takeEvery(ACTIONS.CONTRACTS.GET_MASTER_CONTRACT_DATA,getMasterContractConfig),
  takeEvery(ACTIONS.CONTRACTS.GET_PLANT_WISE_CONTRACT_DATA,getPlantWiseContractData),

  takeEvery(ACTIONS.PLANTS.WAREHOUSE_STORAGE_DETAILS, getWarehouseDetails),
  takeEvery(ACTIONS.PLANTS.EDIT_WAREHOUSE_DETAILS, editWarehouseDetails),
  takeEvery(ACTIONS.PLANTS.DOWNLOAD_WAREHOUSE_TEMP_DETAILS, downloadWarehouseTemplate),
  takeEvery(ACTIONS.PLANTS.UPLOAD_WAREHOUSE_DETAILS, uploadWarehouseDetails),
  takeEvery(ACTIONS.HOME.GET_VENDOR_MATERIAL_DETAILS, getVendorMaterialDetails),
  takeEvery(ACTIONS.HOME.UPLOAD_VENDOR_MATERIAL_DETAILS, uploadVendorMaterialDetails),
  takeEvery(ACTIONS.HOME.DOWNLOAD_VENDOR_MATERIAL_DETAILS, downloadVendorMaterialDetails),
  takeEvery(ACTIONS.HOME.ADD_VENDOR_MATERIAL_DETAILS, addVendorMaterialDetails),
  takeEvery(ACTIONS.HOME.UPDATE_VENDOR_MATERIAL_DETAILS, updateVendorMaterialDetails),
  takeEvery(ACTIONS.HOME.DELETE_VENDOR_MATERIAL_DETAILS, deleteVendorMaterialDetails),
  takeEvery(ACTIONS.HOME.GET_VENDOR_WITH_SAP_CODE, getVendorWithSapCode),

  takeEvery(ACTIONS.PLANTS.GET_INDENTING_OPERATION_TIME, getIndentingOperationTime),
  takeEvery(ACTIONS.PLANTS.ADD_INDENTING_OPERATION_TIME, addOperationTime),
  takeEvery(ACTIONS.PLANTS.EDIT_INDENTING_OPERATION_TIME, editOperationTime),
  takeEvery(ACTIONS.PLANTS.DELETE_INDENTING_OPERATION_TIME, deleteOperationTime),

  takeEvery(ACTIONS.VENDORS.EDIT_VENDOR_EMAIL, editVendorEmail),
  takeEvery(ACTIONS.VENDORS.RETRIGGER_VENDOR_FORM, retriggerVendorEmail),

  takeEvery(ACTIONS.PLANTS.USER_BRANCH_TRANSFER, branchTransfer),
  takeEvery(ACTIONS.PLANTS.DEACTIVATE_BRANCH_USER, deactivateBranchUser),

  takeEvery(ACTIONS.PLANTS.GET_DEPOT_AND_USER_DETAILS, getDepartmentAndUserDetails),
  takeEvery(ACTIONS.PLANTS.DOWNLOAD_USER_DETAILS_TEMPLATE, downloadUserDetailsTemplate),
  takeEvery(ACTIONS.PLANTS.UPLOAD_USER_DETAILS, uploadUserDetails),
  
  takeEvery(ACTIONS.HOME.DOWNLOAD_PARTICULARS_CONVERSION, downloadParticularsConversionTemplate),
  takeEvery(ACTIONS.HOME.UPLOAD_PARTICULARS_CONVERSION, uploadParticularsDetails),

  takeEvery(ACTIONS.PLANTS.APPLY_INDENT_FLOW_SETTINGS_TO_BRANCHES, applyIndentFlowSettingsToBranches),

  takeEvery(ACTIONS.PLANTS.LOREAL_DOWNLOAD_USER_DETAILS_TEMPLATE, lorealdownloadUserDetailsTemplate),
  takeEvery(ACTIONS.PLANTS.LOREAL_UPLOAD_USER_DETAILS, uploadLorealUserDetails),

// Vehicle Planning saga
// Truck Master
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.GET_TRUCK_DETAILS, getTruckDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.ADD_TRUCK_DETAILS, addTruckDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.UPDATE_TRUCK_DETAILS, updateTruckDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DELETE_TRUCK_DETAILS, deleteTruckDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.UPLOAD_TRUCK_DETAILS, uploadTruckDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DOWNLOAD_TRUCK_DETAILS, downloadTruckDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DOWNLOAD_TRUCK_TEMP,downloadTruckTemp),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.GET_VEHICLE_SIZE_DETAILS,getVehicleSize),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.GET_TRANSPORTER_DETAILS, getTransporterDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.GET_TRUCK_DRIVER_DETAILS, getTruckDriverDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.GET_GPS_VENDOR_DATA, getGpsVendorData),

// Sku Master
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.GET_SKU_DETAILS, getSkuDetails ),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.ADD_SKU_DETAILS, addSkuDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.UPDATE_SKU_DETAILS, updateSkuDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DOWNLOAD_SKU_DETAILS, downloadSkuDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.UPLOAD_SKU_DETAILS, uploadSkuDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DOWNLOAD_SKU_TEMP,downloadSkuTemp),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DELETE_SKU_DETAILS, deleteSkuDetails),
// Driver master
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.GET_DRIVER_DETAILS, getDriverDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.ADD_DRIVER_DETAILS, addDriverDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.UPDATE_DRIVER_DETAILS, updateDriverDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DOWNLOAD_DRIVER_DETAILS, downloadDriverDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.UPLOAD_DRIVER_DETAILS, uploadDriverDetails),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DOWNLOAD_DRIVER_TEMP,downloadDriverTemp),
takeEvery(ACTIONS.MASTER.VEHICLE_PLANNING.DELETE_DRIVER_DETAILS, deleteDriverDetails),

takeEvery(ACTIONS.PLANTS.GET_AUTOMOVE, getAutomoveDetails),
takeEvery(ACTIONS.PLANTS.ADD_AUTOMOVE, addAutomoveDetails),

takeEvery(ACTIONS.HOME.GET_MATERIAL_ITEM_DETAILS, getMaterialItemDetails),
takeEvery(ACTIONS.HOME.GET_MODE_OF_TRANSPORT, getModeOfTransport),

takeEvery(ACTIONS.CONTRACTS.ADD_LOADABILITY, addLoadability),
takeEvery(ACTIONS.DATA_GOVERNANCE.HSD_CONSOLIDATED_RFQ_DETAILS, getConsolidatedViewRFQDetails),

takeEvery(ACTIONS.HOME.SYNC_CHAIN_WITH_VIMS, syncChainWithVIMS),

takeEvery(ACTIONS.HOME.GET_ROUTE_MASTER, getRouteMaster),
takeEvery(ACTIONS.HOME.DOWNLOAD_ROUTE_MASTER_DETAILS, downloadRouteMasterDetails),
takeEvery(ACTIONS.HOME.DOWNLOAD_ROUTE_MASTER_TEMPLATE, downloadRouteMasterTemplate),
takeEvery(ACTIONS.HOME.UPLOAD_ROUTE_MASTER, uploadMasterRouteDetails),
takeEvery(ACTIONS.HOME.ADD_ROUTE_MASTER, addRouteMaster),
takeEvery(ACTIONS.HOME.UPDATE_ROUTE_MASTER, updateRouteMaster),
takeEvery(ACTIONS.HOME.DELETE_ROUTE_MASTER, deleteRouteMaster),

takeEvery(ACTIONS.PLANTS.GET_LOB_LIST, getLOBListData),
takeEvery(ACTIONS.HOME.GET_DISPOSAL_MATERIAL, getDisposalMaterial),
takeEvery(ACTIONS.HOME.DOWNLOAD_DISPOSAL_MATERIAL_TEMPLATE, downloadDisposalMaterialTemplate),
takeEvery(ACTIONS.HOME.UPLOAD_DISPOSAL_MATERIAL, uploadDisposalMaterial),
takeEvery(ACTIONS.HOME.DOWNLOAD_DISPOSAL_MATERIAL_DETAILS, downloadDispsalMaterialDetails),
takeEvery(ACTIONS.HOME.GET_DISPOSAL_MATERIAL_DROPDOWNS, getDisposalMaterialDropdowns),
takeEvery(ACTIONS.HOME.ADD_DISPOSAL_MATERIAL, addDisposalMaterial),
takeEvery(ACTIONS.HOME.DELETE_DISPOSAL_MATERIAL, deleteDisposalMaterial),
takeEvery(ACTIONS.HOME.UPDATE_DISPOSAL_MATERIAL, updateDisposalMaterial),
takeEvery(ACTIONS.CONTRACTS.GET_BASIC_MSP_CONTRACT_SETTING, getBasicMSPContractSetting),
takeEvery(ACTIONS.CONTRACTS.EDIT_MSP_CONTRACT, updateMSPContract),
takeEvery(ACTIONS.CONTRACTS.ADD_MSP_CONTRACT, createNewMSPContract),
takeEvery(ACTIONS.CONTRACTS.APPLY_MSP_SETTINGS_TO_BRANCHES, applyMSPSettingsToBranches),
takeEvery(ACTIONS.DATA_GOVERNANCE.GET_TAT_FORM_VENDOR_DETAILS, getTATFormVendorDetails),
takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_TAT_FORM, updateTATFormDetails),
takeEvery(ACTIONS.PLANTS.GET_CITIES_LIST, getConsigneesCityList),

takeEvery(ACTIONS.LOGIN.VALIDATE_SSO_USER, validateSSOLogin),
takeEvery(ACTIONS.LOGIN.LOGIN_WITH_SSO, ssoLogin),
takeEvery(ACTIONS.LOGIN.GET_SSO_CONFIG ,ssoConfig),
takeEvery(ACTIONS.HOME.GET_CONTRACT_TENURE_TYPES, getContractTenureTypes),
takeEvery(ACTIONS.HOME.GET_CONTRACT_TENURE_LIST, getContractTenureList),
takeEvery(ACTIONS.HOME.SAVE_CONTRACT_TENURE_LIST, saveContractTenureList),

takeEvery(ACTIONS.MASTER.MASTER_DROP_DOWN.GET_DROP_DOWN_NAME, getDropDownNamesForMaster),
takeEvery(ACTIONS.MASTER.MASTER_DROP_DOWN.GET_INSIDE_TAB_DROPDOWN, getInsideTabDropDown),
takeEvery(ACTIONS.MASTER.MASTER_DROP_DOWN.EDIT_MASTER_DROP_DOWN_DATA, updateMasterDropDownData),
takeEvery(ACTIONS.MASTER.MASTER_DROP_DOWN.ADD_MASTER_DROP_DOWN_DATA, addMasterDropDownData),
takeEvery(ACTIONS.MASTER.MASTER_DROP_DOWN.DELETE_MASTER_DROP_DOWN_DATA, deleteMasterDropDownData),

takeEvery(ACTIONS.PARENTCHILD.GET_LOCATION_MANAGEMENT_LISTING, getParentChildLocationListing),
takeEvery(ACTIONS.PARENTCHILD.GET_PARENT_CHILD_VIEW_LISTING, getParentChildViewListing),
takeEvery(ACTIONS.PARENTCHILD.DOWNLOAD_TEMPLATE_IN_UPLOAD_LOCATION, downloadTemplateInUploadLocation),
takeEvery(ACTIONS.PARENTCHILD.UPLOAD_FILE_IN_UPLOAD_LOCATION, uploadFileInUploadLocation),
takeEvery(ACTIONS.PARENTCHILD.DELETE_FILE_IN_UPLOAD_LOCATION, deleteFileInUploadLocation),
takeEvery(ACTIONS.PARENTCHILD.GET_ALL_CLUSTER_MAPPING_DATA, getAllClusterMappingData),
takeEvery(ACTIONS.PARENTCHILD.GET_LOC_CHILD_OPTIONS, getAllLOCOptions),
takeEvery(ACTIONS.PARENTCHILD.ADD_CLUSTER_MAPPING_DATA, addClusterMappingData),
takeEvery(ACTIONS.PARENTCHILD.DELETE_CLUSTER_MAPPING_DATA, deleteClusterMappingData),
takeEvery(ACTIONS.PARENTCHILD.EDIT_CLUSTER_MAPPING_DATA, EditClusterMappingData),
takeEvery(ACTIONS.PARENTCHILD.DELETE_PARENT_CHILD_LOCATION_DATA, deleteParentChildLocationData),
takeEvery(ACTIONS.PARENTCHILD.EDIT_PARENT_CHILD_LOCATION_DATA, EditParentChildLocaitonData),
takeEvery(ACTIONS.PARENTCHILD.DELETE_ALL_CHILD_DATA, deleteAllChildData),
takeEvery(ACTIONS.INDENTING.GET_INDENT_CONFIG_DETAILS, getIndentConfigDetails),
takeEvery(ACTIONS.INDENTING.ADD_INDENT_CONFIG_DETAILS, addIndentConfigDetails),
takeLatest(ACTIONS.INDENTING.SAVE_INDENT_CONFIG_DETAILS, saveIndentConfigDetails),
takeEvery(ACTIONS.HOME.DOWNLOAD_VENDOR_DETAILS, downloadVendorDetails),

takeEvery(ACTIONS.DATA_GOVERNANCE.GET_DAILY_DIESEL_PRICE_HIKE, getDailyDieselPriceHike),

takeEvery(ACTIONS.PLANTS.GET_WEIGH_BRIDGE_MASTER_LISTING, getWeighbridgeMasterListing),
takeEvery(ACTIONS.PLANTS.CREATE_WEIGH_BRIDGE_MASTER, addWBMaster),
takeEvery(ACTIONS.PLANTS.UPDATE_WEIGH_BRIDGE_MASTER, updateWBMaster),
takeEvery(ACTIONS.PLANTS.DELETE_WEIGH_BRIDGE_MASTER, deleteWBMaster),
takeEvery(ACTIONS.PLANTS.GET_REGION_DROPDOWN, getRegionMasterDropdowns),
takeEvery(ACTIONS.PLANTS.INDENTING_DASHBOARD_LIST, getIndentingDashboardList),
takeEvery(ACTIONS.PLANTS.GET_PARKING_LOT_LISTING, getParkingLotListing),
takeEvery(ACTIONS.PLANTS.CREATE_PARKING_LOT, addParkingLot),
takeEvery(ACTIONS.PLANTS.UPDATE_PARKING_LOT, updateParkingLot),
takeEvery(ACTIONS.PLANTS.DELETE_PARKING_LOT, deleteParkingLot),
takeEvery(ACTIONS.PLANTS.GET_ALL_YARDS, getAllYards),
//volumetic master
takeEvery(ACTIONS.PLANTS.GET_VOLUMETRIC_MASTER, getVolumeticMaster),
takeEvery(ACTIONS.PLANTS.ADD_VOLUMETRIC_MASTER, addVolumetricMaster),
takeEvery(ACTIONS.PLANTS.UPDATE_VOLUMETRIC_MASTER, updateVolumetricMaster),
takeEvery(ACTIONS.PLANTS.DELETE_VOLUMETRIC_MASTER, deleteVolumetricMaster),
takeEvery(ACTIONS.PLANTS.GET_PACK_UOM_DROPDOWN, getPackUOMDropdown),
takeEvery(ACTIONS.PLANTS.DOWNLOAD_VOLUMETRIC_MASTER, downloadVolumetricMaster),
takeEvery(ACTIONS.PLANTS.UPLOAD_VOLUMETRIC_MASTER_DATA ,uploadVolumetricMasterDetails),
takeEvery(ACTIONS.PLANTS.GET_DEPO_NAME,fetchDepotName),

//MFS MASTER
takeEvery(ACTIONS.PLANTS.GET_MFS_MASTER_DETAILS, getMFSMasterDetails),
takeEvery(ACTIONS.PLANTS.UPDATE_MFS_MASTER_DETAILS, updateMfsMasterDetails),
takeEvery(ACTIONS.PLANTS.CREATE_MFS_MASTER_DETAILS, createMfsMasterDetails),
takeEvery(ACTIONS.PLANTS.DELETE_MFS_MASTER_DETAILS, deleteMfsMasterDetails),
takeEvery(ACTIONS.PLANTS.GET_MFS_MASTER_UOM_DETAILS, getMfsMasterUomDetails),

takeEvery(ACTIONS.PLANTS.DOWNLOAD_MFS, downloadMfsMaster),
takeEvery(ACTIONS.PLANTS.UPLOAD_MFS, uploadMFSMasterDetails),

//network master
takeEvery(ACTIONS.DATA_GOVERNANCE.POST_NETWORK_MASTER_DATA, postNetworkMaster),
takeEvery(ACTIONS.DATA_GOVERNANCE.GET_NETWORK_MASTER_DATA,getNetworkMasterData),
takeEvery(ACTIONS.DATA_GOVERNANCE.UPDATE_NETWORK_MASTER_DATA,updateNetworkMasterdata),
takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_NM_TEMPLATE,downloadnetworkmasterData),
takeEvery(ACTIONS.DATA_GOVERNANCE.UPLOAD_NETWORK_MASTER,networkmasterupload),
takeEvery(ACTIONS.DATA_GOVERNANCE.DOWNLOAD_NM,downloadNetworkMaster),
takeEvery(ACTIONS.DATA_GOVERNANCE.ORIGIN_NETWORK_MASTER_DROPDOWN_DATA,getorigin_zoneNM),
takeEvery(ACTIONS.DATA_GOVERNANCE.MODE_NETWORK_MASTER_DROPDOWN_DATA,getmodeNM),
takeEvery(ACTIONS.DATA_GOVERNANCE.ROUTE_NAME_NETWORK_MASTER_DROPDOWN_DATA,getRoutenameNM),
takeEvery(ACTIONS.DATA_GOVERNANCE.SOURCE_NETWORK_MASTER_DROPDOWN_DATA,getsourceNM),

takeEvery(ACTIONS.PLANTS.GET_REPORTS_DASHBOARDS_LIST, getReportsDashboardList),
takeEvery(ACTIONS.PLANTS.GET_SERVICE_MODES, getServiceModes),
takeEvery(ACTIONS.PLANTS.GET_COMPANY_WISE_ZONES, getCompanyWiseZones),
//vendorDetailsGET_VENDOR_DETAILS_FOR_SAP
takeEvery(ACTIONS.VENDORS.GET_VENDOR_DETAILS_FOR_SAP, getVendorDetailsForSap),
takeEvery(ACTIONS.VENDORS.GET_PAYMENT_TERMS_LIST, getPaymentTermsList),
takeEvery(ACTIONS.VENDORS.GET_CURRENCY_LIST, getCurrencyList),
takeEvery(ACTIONS.VENDORS.POST_SAVE_SAP_DEATAILS, postSaveSapDeatails),
takeEvery(ACTIONS.VENDORS.POST_SEND_SAP_DEATAILS, postSapSendDetailsForCode),
takeEvery(ACTIONS.VENDORS.GET_VENDOR_SOURCE_DROPDOWN, getVendorSourceDropdown),

// Exworks details
  takeEvery(ACTIONS.VENDORS.POST_EXWORKS_DEATAILS, postExWorksDeatails),
  takeEvery(ACTIONS.VENDORS.GET_INCO_TERMS_OPTIONS,   getIncotermsOptions,), 

takeEvery(ACTIONS.MASTER.INVENTORY_ITEM.UPLOAD_RATE_CHART, uploadRateChart),
takeEvery(ACTIONS.MASTER.INVENTORY_ITEM.DOWNLOAD_RATE_CHART, downloadRateChart),
takeEvery(ACTIONS.MASTER.INVENTORY_ITEM.GET_PRODUCT_RATE_CHART_DETAILS, getRateChartDetails),
takeEvery(ACTIONS.MASTER.INVENTORY_ITEM.ADD_PRODUCT_RATE_CHART_DETAILS, addRateChartDetails),
takeEvery(ACTIONS.MASTER.INVENTORY_ITEM.DELETE_PRODUCT_RATE_CHART_DETAILS, deleteRateChartDetails),
takeEvery(ACTIONS.MASTER.INVENTORY_ITEM.UPDATE_PRODUCT_RATE_CHART_DETAILS, updateRateChartDetails),
takeEvery(ACTIONS.PLANTS.GET_SLAB_RANGE, getSlabRange),
takeEvery(ACTIONS.PLANTS.ADD_SLAB_RANGE, createSlabRangeDetails),
takeEvery(ACTIONS.PLANTS.DELETE_SLAB_RANGE, deleteSlabRange),

takeEvery(ACTIONS.PLANTS.GET_ADD_PLANT_CATEGORY, getPlantsCategory),

takeEvery(ACTIONS.AUDIT_SCREEN.GET_AUDIT_LOGS, getAuditLogs),
takeEvery(ACTIONS.AUDIT_SCREEN.GET_ALL_BRANCH_LIST, getAllBranchList),
takeEvery(ACTIONS.AUDIT_SCREEN.DOWNLOAD_AUDIT_LOGS, downloadAuditLogs),
takeEvery(ACTIONS.AUDIT_SCREEN.GET_ALL_DL_NO, getAllDlNO),
takeEvery(ACTIONS.AUDIT_SCREEN.GET_ALL_VEH_REG_NO, getAllVehicelRegNo),
takeEvery(ACTIONS.AUDIT_SCREEN.GET_ALL_SAP_MATERIAL_CODE, getAllSapMaterialCode),

takeEvery(ACTIONS.PLANTS.GET_INDENTING_REASONS, getReasons),
takeEvery(ACTIONS.PLANTS.ADD_INDENTING_REASONS, addReasons),
takeEvery(ACTIONS.PLANTS.EDIT_INDENTING_REASONS, updateReasons),
takeEvery(ACTIONS.PLANTS.DELETE_INDENTING_REASONS, deleteReasons),
//Inventory Master
takeEvery(ACTIONS.PLANTS.GET_INVENTORY_MASTER, getInventoryMaster),
takeEvery(ACTIONS.PLANTS.DELETE_INVENTORY_MASTER, deleteInventoryMaster),
takeEvery(ACTIONS.PLANTS.UPDATE_INVENTORY_MASTER, updateInventoryMaster),
takeEvery(ACTIONS.PLANTS.ADD_INVENTORY_MASTER, addInventoryMaster),
takeEvery(ACTIONS.HOME.GET_TRANSPOTER_LIST, getTransporterArray),
takeEvery(ACTIONS.HOME.GET_EMAMI_PAYMENT_TERMS, getEmamiPaymentTerms),
takeEvery(ACTIONS.HOME.GET_MATERIALA_DETAILS, getMaterialMRPDetails),
takeEvery(ACTIONS.HOME.DOWNLOAD_MRP_TEMPLATE, downloadMRPTemplate),
takeEvery(ACTIONS.HOME.DOWNLOAD_MRP_DATA, downloadMRPData),
takeEvery(ACTIONS.HOME.UPLOAD_MRP_DATA, uploadMRPData),

// SalesDistrictMapping Master
takeEvery(ACTIONS.PLANTS.GET_DISTRICT_MAPPING_MASTER, getSalesDistrictMappingMaster),
takeEvery(ACTIONS.PLANTS.ADD_DISTRICT_MAPPING_MASTER, addSalesDistrictMappingMaster),
takeEvery(ACTIONS.PLANTS. UPDATE_DISTRICT_MAPPING_MASTER , updateSalesDistrictMappingMaster),
takeEvery(ACTIONS.PLANTS.DELETE_DISTRICT_MAPPING_MASTER, deleteSalesDistrictMappingMaster),
//Banner Settings start
takeEvery(ACTIONS.BANNER_SETTING.GET_BANNER_LIST, getBannerList),
takeEvery(ACTIONS.BANNER_SETTING.DELETE_BANNER_MESSAGE, deleteBannerMessage),
takeEvery(ACTIONS.BANNER_SETTING.ADD_BANNER_MESSAGE, createBannerMessage),
takeEvery(ACTIONS.BANNER_SETTING.UPDATE_BANNER_MESSAGE, updateBannerMessage),
takeLatest(ACTIONS.BANNER_SETTING.GET_BROADCAST_MSG, getBroadcastMsg),
//Banner Settings End

//Bill Sequence
takeEvery(ACTIONS.VENDORS.GET_BILL_SEQUENCE_TEST_LIST, getBillSequenceTestingList),
takeEvery(ACTIONS.VENDORS.GET_BILL_SEQUENCE_LISTING, getBillSequenceListing),
takeEvery(ACTIONS.VENDORS.GET_BILL_SEQUENCE_DROPDOWNS, getBillSequenceDropdown),
takeEvery(ACTIONS.VENDORS.GET_BILL_SEQUENCE, getBillSequence),
takeEvery(ACTIONS.VENDORS.POST_BILL_SEQUENCE, postBillSequence),
takeEvery(ACTIONS.VENDORS.UPDATE_BILL_SEQUENCE, updateBillSequence),
takeEvery(ACTIONS.VENDORS.DELETE_BILL_SEQUENCE, deleteBillSequence),
//DAMAGE RATE starts here
takeEvery(ACTIONS.HOME.GET_DAMAGE_RATE_DETAILS, getDamageRateDetails),
takeEvery(ACTIONS.HOME.DOWNLOAD_DAMAGE_RATE_TEMPLATE, downloadDamageRateTemplate),
takeEvery(ACTIONS.HOME.DOWNLOAD_DAMAGE_RATE_DATA, downloadDamageRateData),
takeEvery(ACTIONS.HOME.UPLOAD_DAMAGE_RATE_DATA, uploadDamageRateData),

//Debit Note Config
takeEvery(ACTIONS.HOME.GET_DEBIT_NOTE_CONFIG, getDebitNoteConfig),
takeEvery(ACTIONS.HOME.SAVE_DEBIT_NOTE_CONFIG, saveDebitNoteConfig),
takeEvery(ACTIONS.PLANTS.FETCH_INDENTING_MOVES, getAllMoves),
takeEvery(ACTIONS.HOME.FETCH_TAX_CODES, fetchTaxCodes),
//Dph Amendment
takeEvery(ACTIONS.DATA_GOVERNANCE.DPH_AMENDMENT_MONTHLY_RATES, postDphAmendmentMonthlyRates),
takeEvery(ACTIONS.DATA_GOVERNANCE.GET_DPH_AMENDMENT_MONTHLY_RATES, getDphAmendmentMonthlyRates),

//Customer Lane Mapping
takeLatest(ACTIONS.HOME.GET_CUSTOMER_LANE_MAPPING, getCustomerLaneMapping),
takeLatest(ACTIONS.HOME.POST_CUSTOMER_LANE_MAPPING, postCustomerLaneMapping),
takeLatest(ACTIONS.HOME.UPDATE_CUSTOMER_LANE_MAPPING, updateCustomerLaneMapping),
takeLatest(ACTIONS.HOME.DELETE_CUSTOMER_LANE_MAPPING, deleteCustomerLaneMapping),
//Delivery-Charges: extra delivery charges
takeEvery(ACTIONS.HOME.GET_EXTRA_DELIVERY_CHARGE_DETAILS, getExtraDeliveryChargesDetails),
takeEvery(ACTIONS.HOME.DELETE_EXTRA_DELIVERY_CHARGE_DETAILS, deleteExtraDeliveryChargesDetails),


//Delivery-Charges: Additonal Cost charges
takeEvery(ACTIONS.HOME.GET_ADDITIONAL_COST_CHARGE_DETAILS, getAdditionalCostChargesDetails),
takeEvery(ACTIONS.HOME.DELETE_ADDITIONAL_COST_CHARGE_DETAILS, deleteAdditionalCostChargesDetails),

takeEvery(ACTIONS.HOME.SAVE_EMUDHRA_DETAILS, saveEmudhraDetails),

...(companyUserInfoScreenSaga()||[])
];
